import React, { Component } from 'react'
import CustomMedia from '~components/atom/FieldFactory/CustomMedia/CustomMedia'
import ReactQuill, { Quill } from 'react-quill'
import withStyles from '@material-ui/core/styles/withStyles'
import './styles.css'

import DocBlot from './DocBlot'
import ImageBlot from './ImageBlot'
import PriceBlot from './PriceBlot'
import ReferenceBlot from './ReferenceBlot'
import VideoBlot from './VideoBlot'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'

import Popup from 'shared-ui/components/organisms/Popup'
import PopupBox from 'shared-ui/components/popup/PopupBox'
import PopupHeader from 'shared-ui/components/popup/PopupHeader'
import PopupFooter from 'shared-ui/components/popup/PopupFooter'
import PopupContent from 'shared-ui/components/popup/PopupContent'

import 'react-quill/dist/quill.snow.css'
import Button from '@material-ui/core/Button'
import CustomReferenceFilter from '~components/atom/FieldFactory/CustomReferenceFilter'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinkTypes from 'shared-ui/constants/LinkTypes'

const icons = Quill.import('ui/icons')

icons['doc'] = '<i class="fa fa-file" aria-hidden="true"></i>'
icons['link'] = '<i class="fa fa-forward" aria-hidden="true"></i>'
icons['reference'] = '<i class="fa fa-link" aria-hidden="true"></i>'
icons['price'] = '<i class="material-icons">format_shapes</i>'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  rootGroup: {
    flexDirection: 'row',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  refPopUpHeader: {
    '& > div': {
      padding: 0,
    },
  },
  refPopUpHeaderText: {
    padding: unit * 2,
  },
  selected: {
    backgroundColor: `${palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${palette.primary.main} !important`,
    },
  },
  button: {
    color: palette.paper,
    backgroundColor: palette.primary.main,
    border: `solid 1px ${palette.paper}`,
    '&:disabled': {
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      backgroundColor: palette.paper,
    },
    '&:hover': {
      backgroundColor: palette.accent200.main,
    },
  },
})

const openModalHandler = async function (type) {
  const { disabled, api } = this.props

  if (!disabled) {
    try {
      this.setCursorPositionAndText()

      const { request } = api.getReferenceSuggestions('~sitemap')
      const response = await request
      const sitemaps = response.result

      this.setState({
        openModal: true,
        type: type,
        sitemaps,
      })
    } catch (e) {
      console.error('Error opening modal', e)
    }
  }
}

const openModalReference = async function () {
  const { disabled, api } = this.props

  if (!disabled) {
    try {
      const { request } = api.getReferenceSuggestions('~sitemap')

      const response = await request
      const sitemaps = response.result

      const [_, text] = this.setCursorPositionAndText()
      if (isEmptyOrSpaces(text)) return // early exit if selectedText is empty

      this.setState({
        openModalReference: true,
        sitemaps: sitemaps,
      })
    } catch (e) {
      console.log('Error while opening modal reference', e)
    }
  }
}

function isEmptyOrSpaces(str) {
  return str === null || str.match(/^\s*$/) !== null
}

Quill.register(ImageBlot)
Quill.register(VideoBlot)
Quill.register(DocBlot)
Quill.register(ReferenceBlot)
Quill.register(PriceBlot)

class CustomRichText extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      openModalReference: false,
      type: null,
      checkBoxValue: 'a',
      checkBoxTargetValue: '_self',
      sitemapSearch: '',
      selectedText: '',
      selectedCursorPosition: null,
      selectedLink: {},
    }

    this.toolbarMenu = {}

    // Quill instance
    this.quillRef = null
    this.reactQuillRef = null

    const { settings: { toolbar } = {} } = this.props

    this.generateToolBar(toolbar)
    // this.handleSitemapSearchChange = debounce(this.handleSitemapSearchChange, 300)
  }

  componentDidMount() {
    this.attachQuillRefs()
    this.embedToolTips()
  }

  componentDidUpdate() {
    this.attachQuillRefs()
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return
    this.quillRef = this.reactQuillRef.getEditor()
  }

  embedToolTips = () => {
    const { settings: { toolbar } = {} } = this.props

    const toolbarItems = this.quillRef.container.parentElement.querySelectorAll(
      '.ql-formats > *:not(select)',
    )

    toolbarItems.forEach((element, idx) => {
      element.addEventListener('mouseenter', () => {
        const item = toolbar[idx]
        if (!item || !item.tooltip) return

        this.setState({
          tooltip: {
            title: `${item.tooltip}`,
            element,
            open: true,
          },
        })
      })
      element.addEventListener('mouseleave', () => {
        this.setState({
          tooltip: { ...this.state.tooltip, open: false },
        })
      })
    })
  }

  generateToolBar(toolbar) {
    const modulesItems = []
    const formatsItems = []
    let item
    let obj

    for (obj of toolbar) {
      switch (obj.type) {
        case 'select':
          item = {
            [obj.name]: obj.options,
          }
          break
        case 'button':
          if (obj.value) {
            item = {
              [obj.name]: obj.value,
            }
          } else {
            item = obj.name
          }
          break
        default:
          break
      }

      formatsItems.push(obj.name)
      modulesItems.push(item)
    }

    this.toolbarMenu = {
      modules: {
        clipboard: {
          matchVisual: false,
        },
        toolbar: {
          container: modulesItems,
          handlers: {
            image: openModalHandler.bind(this, 'image'),
            video: openModalHandler.bind(this, 'video'),
            doc: openModalHandler.bind(this, 'application'),
            reference: openModalReference.bind(this),
            price: this.insertBlock.bind(this, 'price'),
          },
        },
      },
      formats: formatsItems,
    }
  }

  insertBlock(type) {
    const cursorPosition = (this.quillRef.getSelection && this.quillRef.getSelection()) || 0
    const text = this.quillRef.getText(cursorPosition)

    this.quillRef.deleteText(cursorPosition)

    this.quillRef.insertEmbed(cursorPosition.index, type, { text: text })

    this.quillRef.setSelection(cursorPosition.index ? cursorPosition.index + 1 : 0)
  }

  handleChange = (content, delta, user, editor) => {
    const { disabled } = this.props
    if (disabled) return

    const { onUpdate } = this.props

    onUpdate && onUpdate(editor.getHTML())
  }

  handleBlur = (previousRange, source, editor) => {
    const { disabled } = this.props
    if (disabled) return

    const { onChange } = this.props

    onChange &&
      onChange({
        target: {
          name: this.props.name,
          value: editor.getHTML(),
        },
      })
  }

  useThisMedia = (file, event) => {
    // event.stopPropagation()
    let type
    let remove = false
    const { selectedText, selectedCursorPosition } = this.state
    if (!file) return false

    if (/image/.test(file.type)) {
      type = 'image'
    } else if (/video/.test(file.type)) {
      type = 'video'
    } else if (/application/.test(file.type)) {
      type = 'doc'
      remove = true
    } else if (/reference|externalUrl|internalLink|externalLink/.test(file.type)) {
      remove = true
      type = 'reference'
    }

    if (type === 'image' && !this.state.pendingFile) {
      this.setState({
        pendingFile: file,
        openModal: false,
        openModalReference: true,
      })

      return true
    }

    if (type === 'reference' && this.state.pendingFile) {
      this.quillRef.insertEmbed(
        selectedCursorPosition.index,
        type,
        Object.assign(file, {
          pendingFile: this.state.pendingFile,
          src: file.path,
          alt: file.name,
          data_object_id: file._id,
          text: selectedText,
        }),
      )

      this.setState({
        pendingFile: undefined,
        openModal: false,
        openModalReference: false,
        selectedLink: {},
      })

      return false
    }

    if (remove) {
      this.quillRef.deleteText(selectedCursorPosition)
    }

    this.quillRef.insertEmbed(
      selectedCursorPosition.index,
      type,
      Object.assign(file, {
        src: file.path,
        alt: file.name,
        autoplay: file.autoplay,
        data_object_id: file._id,
        text: selectedText,
      }),
    )

    this.quillRef.setSelection(selectedCursorPosition.index ? selectedCursorPosition.index + 1 : 0)
    this.clearCursorPositionAndText()
    this.setState({
      openModal: false,
      openModalReference: false,
      sitemapSearch: '',
      pendingFile: undefined,
      selectedLink: {},
    })

    return false
  }

  onCloseModal = () => {
    this.clearCursorPositionAndText()
    this.useThisMedia(this.state.pendingFile)
    this.setState({
      openModalReference: false,
      sitemapSearch: '',
      selectedLink: {},
    })
  }

  handleCloseParent = () => {
    this.clearCursorPositionAndText()
    this.setState({
      openModal: false,
      selectedLink: {},
      selectedType: '',
    })
  }

  handleListItemClick = (sitemap, event) => {
    event.stopPropagation()
    sitemap.type = sitemap.type || 'reference'
    sitemap.style = this.state.checkBoxValue
    sitemap.sitemapid = sitemap._id
    sitemap.target = this.state.checkBoxTargetValue

    this.setState({ selectedLink: sitemap })
  }

  _handleConfirm = event => {
    const { selectedLink } = this.state
    this.useThisMedia(selectedLink, event)
    this.setState({
      checkBoxValue: 'a',
      checkBoxTargetValue: '_self',
      selectedText: '',
      selectedCursorPosition: null,
      selectedType: '',
    })
  }

  handleStyleChange = event => {
    const style = event.target.value
    const sitemap = this.state.selectedLink
    sitemap.style = style
    this.setState({ checkBoxValue: style, selectedLink: sitemap })
  }

  handleTargetChange = event => {
    const target = event.target.value
    const sitemap = this.state.selectedLink
    sitemap.target = target
    this.setState({ checkBoxTargetValue: target, selectedLink: sitemap })
  }

  handleSitemapSearchChange = event => {
    const value = event.target.value
    this.setState(prev => {
      let isValidUrl = false
      if (value.startsWith('http://') || value.startsWith('https://')) {
        isValidUrl = true
      }
      return { ...prev, sitemapSearch: value, isValidUrl }
    })
  }

  setCursorPositionAndText = () => {
    const cursorPosition = (this.quillRef.getSelection && this.quillRef.getSelection()) || 0
    const text = this.quillRef.getText(cursorPosition)
    this.setState({
      selectedText: text,
      selectedCursorPosition: cursorPosition,
    })
    return [cursorPosition, text]
  }

  clearCursorPositionAndText = () => {
    this.setState({
      selectedText: '',
      selectedCursorPosition: null,
    })
  }

  filterSitemap = (sitemaps, selectedType, sitemapSearch) => {
    if (!sitemaps) return

    if (sitemapSearch === '' && !selectedType) {
      return sitemaps
    }

    let suggestions = sitemaps
    switch (selectedType) {
      case LinkTypes.EXTERNAL_LINK:
        suggestions = suggestions.filter(suggestion => suggestion.linkType?.startsWith('external'))
        break
      case LinkTypes.INTERNAL_LINK:
        suggestions = suggestions.filter(suggestion => suggestion.linkType?.startsWith('internal'))
        break
      case !selectedType:
      default:
    }

    return suggestions.filter(sitemap => {
      const { _name, title, _id } = sitemap
      return (_name || title || _id).toLowerCase().indexOf(sitemapSearch.toLowerCase()) >= 0
    })
  }

  _onSearchSubmit = async event => {
    const { api } = this.props

    if (this.state.sitemapSearch === '') return
    const collection = 'sys_external_urls'
    try {
      const { request } = api.createModelEntry(`/@/createSilent/${collection}?context=link`, {
        url: this.state.sitemapSearch,
        title: this.state.sitemapSearch.toUpperCase(),
      })
      const { result } = await request

      const newItem = {
        type: 'reference',
        style: this.state.checkBoxValue,
        sitemapid: result,
        target: this.state.checkBoxTargetValue,
        _id: result,
      }

      const newSitemap = {
        linkType: LinkTypes.EXTERNAL_URL,
        _id: result,
        _name: this.state.sitemapSearch,
      }

      this.setState(prev => {
        return {
          selectedLink: newItem,
          sitemaps: [...prev.sitemaps, newSitemap],
          selectedType: null,
        }
      })
    } catch (e) {
      console.error('Error on search submit', e)
    }
  }

  _onKeyDown = async event => {
    if (event.keyCode === 13) {
      await this._onSearchSubmit(event)
    }
  }

  _renderTargetsForSelection() {
    const { classes } = this.props

    const { sitemaps = [], selectedLink: { sitemapid } = {}, checkBoxTargetValue } = this.state
    let enablePopup = true
    let enableSelf = true
    const { linkType } = (sitemapid && sitemaps.find(x => x._id === sitemapid)) || {}

    const changeTargetValue = value => {
      return this.setState(props => {
        if (props.selectedLink) Object.assign(props.selectedLink, { target: value })
        return {
          ...props,
          checkBoxTargetValue: value,
        }
      })
    }

    const isExternal = [LinkTypes.EXTERNAL_LINK, LinkTypes.EXTERNAL_URL].some(x => x === linkType)
    if (isExternal) {
      enablePopup = false
      enableSelf = true
      checkBoxTargetValue === 'popup' && changeTargetValue('_self')
    } else if (linkType === LinkTypes.INTERNAL_LIGHTBOX) {
      enableSelf = false
      checkBoxTargetValue === '_self' && changeTargetValue('popup')
    }

    return (
      <RadioGroup
        aria-label="Select a target"
        name="target"
        value={checkBoxTargetValue}
        onChange={this.handleTargetChange}
        classes={{
          root: classes.rootGroup,
        }}
      >
        {enableSelf && <FormControlLabel value="_self" control={<Radio />} label="Self" />}
        {enablePopup && <FormControlLabel value="popup" control={<Radio />} label="Popup" />}
        <FormControlLabel value="newtab" control={<Radio />} label="New Tab" />
      </RadioGroup>
    )
  }

  render() {
    const { root, label, helperText, disabled, value, name, classes, ...rest } = this.props
    const { settings: { backgroundColor } = {} } = rest
    const {
      openModalReference,
      openModal,
      sitemaps,
      checkBoxValue,
      checkBoxTargetValue,
      sitemapSearch,
      tooltip = {},
      selectedType,
      isValidUrl,
      selectedLink,
    } = this.state

    const onLinkTypeSelection = (event, type) => {
      this.setState({ selectedType: type })
    }

    const filteredSitemaps = this.filterSitemap(sitemaps, selectedType, sitemapSearch)
    return (
      <React.Fragment>
        <FormLabel disabled={disabled} style={{ fontSize: '0.825rem' }}>
          {label}
        </FormLabel>
        <ReactQuill
          preserveWhitespace
          className={disabled && classes.disabled}
          style={{ backgroundColor }}
          modules={this.toolbarMenu.modules}
          formats={this.toolbarMenu.formats}
          value={value ? value.normalize('NFC') : ''}
          name={name}
          readOnly={disabled}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={el => {
            if (el) {
              this.reactQuillRef = el
              return this.reactQuillRef
            }
          }}
        />

        <Popper open={tooltip.open} anchorEl={tooltip.element} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ padding: '0.5rem 1rem' }}>
                <Typography className={classes.typography}>{tooltip.title}</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>

        {openModalReference && (
          <Popup open={openModalReference} onClose={this.onCloseModal}>
            <PopupBox>
              <PopupHeader onClose={this.onCloseModal} className={classes.refPopUpHeader}>
                <Typography variant="h4" className={classes.refPopUpHeaderText}>
                  Sitemap
                </Typography>
                <TextField
                  autoFocus
                  fullWidth
                  value={sitemapSearch}
                  onChange={this.handleSitemapSearchChange}
                  placeholder={'Type to filter ...'}
                  InputProps={{
                    style: { padding: `7px 15px`, boxSizing: 'border-box' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        <IconButton
                          color="secondary"
                          className={classes.searchButton}
                          variant={'outlined'}
                          type={'submit'}
                          disabled={!isValidUrl}
                          onClick={this._onSearchSubmit}
                        >
                          <Icon fontSize={'small'}>keyboard_return</Icon>
                        </IconButton>
                        <Tooltip
                          title={'Create new URL starting with http:// or https://'}
                          style={{ zIndex: 9999 }}
                        >
                          <Icon>info</Icon>
                        </Tooltip>
                      </>
                    ),
                  }}
                  onKeyDown={this._onKeyDown}
                />
              </PopupHeader>

              <PopupContent>
                <Paper square>
                  <List>
                    <CustomReferenceFilter
                      component={ListItem}
                      selectedType={selectedType || ''}
                      onSelection={onLinkTypeSelection}
                    />
                    {Array.isArray(filteredSitemaps) &&
                      filteredSitemaps.map(sitemap => {
                        const isSeleted = sitemap._id === this.state.selectedLink._id
                        return (
                          <ListItem
                            key={sitemap._id}
                            button
                            classes={{ selected: classes.selected }}
                            onClick={event => this.handleListItemClick(sitemap, event)}
                            selected={isSeleted}
                          >
                            <ListItemText primary={sitemap._name} />
                          </ListItem>
                        )
                      })}
                  </List>
                </Paper>
              </PopupContent>

              <PopupFooter>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Style</FormLabel>
                    <RadioGroup
                      aria-label="Select a style"
                      name="style"
                      value={checkBoxValue}
                      onChange={this.handleStyleChange}
                      classes={{
                        root: classes.rootGroup,
                      }}
                    >
                      <FormControlLabel value="a" control={<Radio />} label="Link" />
                      <FormControlLabel value="button" control={<Radio />} label="Button" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Target</FormLabel>
                    <RadioGroup
                      aria-label="Select a target"
                      name="target"
                      value={checkBoxTargetValue}
                      onChange={this.handleTargetChange}
                      classes={{
                        root: classes.rootGroup,
                      }}
                    >
                      {this._renderTargetsForSelection()}
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={!selectedLink._id}
                  onClick={e => this._handleConfirm(e)}
                >
                  {'Confirm'}
                </Button>
              </PopupFooter>
            </PopupBox>
          </Popup>
        )}

        {openModal && (
          <CustomMedia
            isRichText
            useThisMedia={this.useThisMedia.bind(this)}
            typeMedia={this.state.type}
            {...rest}
            handleCloseParent={this.handleCloseParent.bind(this)}
          />
        )}
        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CustomRichText)
