import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import LinkTypes from 'shared-ui/constants/LinkTypes'

const styles = theme => ({
  filterMenu: {
    columnGap: theme.spacing.unit,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toggleRoot: {
    display: 'flex',
    boxShadow: 'none',
    border: 0,
    backgroundColor: 'transparent',
    borderRadius: '20px',
    columnGap: theme.spacing.unit,
  },
  toggleButton: {
    borderRadius: '20px !important',
  },
  toggleSelected: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
})

const interlinkFilter = {
  type: LinkTypes.INTERNAL_LINK,
  label: 'Internal',
}
const externallinkFilter = {
  type: LinkTypes.EXTERNAL_LINK,
  label: 'External',
}

const deafaultlabel = 'Filter search: '

const CustomReferenceFilter = ({ component: Component, ...props }) => {
  const {
    classes,
    label = deafaultlabel,
    selectedType,
    onSelection,
    settings: { allowExternalLink = true, allowInternalLink = true } = {},
  } = props

  return (
    <Component className={classes.filterMenu}>
      <Typography variant={'h5'}>{label}</Typography>
      <ToggleButtonGroup
        classes={{ root: classes.toggleRoot }}
        value={selectedType}
        exclusive
        onChange={onSelection}
        aria-label="Platform"
      >
        {allowInternalLink && (
          <ToggleButton
            classes={{ root: classes.toggleButton, selected: classes.toggleSelected }}
            value={interlinkFilter.type}
          >
            {interlinkFilter.label}
          </ToggleButton>
        )}
        {allowExternalLink && (
          <ToggleButton
            classes={{ root: classes.toggleButton, selected: classes.toggleSelected }}
            value={externallinkFilter.type}
          >
            {externallinkFilter.label}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Component>
  )
}

export default withStyles(styles)(CustomReferenceFilter)
