import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

const LOCAL_STORAGE_ITEM_ID = 'jti-local-clipboard'
const defaultClipboardText = ''

export const LocalClipboardContext = createContext(defaultClipboardText)

// TODO: Add support for scopes (give clipbord an id)
const useLocalClipboard = () => {
  const localClipboard = useContext(LocalClipboardContext)

  return localClipboard
}

export default useLocalClipboard

export const ProvideLocalClipboard = ({ children }) => {
  const [localClipboardText, setLocalClipboardText] = useState(defaultClipboardText)

  useEffect(() => {
    const readClipboard = () => {
      setLocalClipboardText(window.localStorage.getItem(LOCAL_STORAGE_ITEM_ID))
    }
    window.addEventListener('focus', readClipboard)
    readClipboard()
    return () => {
      window.removeEventListener('focus', readClipboard)
    }
  }, [])

  const write = useCallback(writeText => {
    window.localStorage.setItem(LOCAL_STORAGE_ITEM_ID, writeText)
    setLocalClipboardText(writeText)
  }, [])

  return (
    <LocalClipboardContext.Provider value={{ text: localClipboardText, write }}>
      {children}
    </LocalClipboardContext.Provider>
  )
}
