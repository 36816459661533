import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import getSanitizedMediaPath from 'shared-ui/utils/getSanitizedMediaPath'
import sizeImagePath from 'shared-ui/utils/sizeImagePath'
import Tooltip from '@material-ui/core/Tooltip'

class ImageSelection extends React.Component {
  constructor() {
    super()
    this.videoRef = React.createRef()
  }

  handleDelete(key) {
    this.props.onHandleDeleteChip(key)
  }

  componentDidUpdate() {
    // the video tag has to reload if the src has changed
    const { customUserMedia, isPlaying } = this.props
    if (!customUserMedia && !isPlaying && this.videoRef.current) {
      this.videoRef.current.load()
    }
  }

  render() {
    const {
      classes,
      value,
      handleOpen,
      disabled,
      handleDeleteImageFromItem,
      clickedOnVideo,
      showControls = true,
      isPlaying,
      customUserMedia,
      customHeight,
    } = this.props

    let mediaPath = value && value.path
    if (mediaPath && !customUserMedia) mediaPath = sizeImagePath(getSanitizedMediaPath(value), 'sm')

    let style
    if (customHeight) style = { height: customHeight }

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} style={{ position: 'relative', zIndex: 0 }}>
            <Card
              className={classNames(classes.cardList, classes.card, classes.previewDetails)}
              style={style}
            >
              <CardMedia
                image={mediaPath || ''}
                className={classNames(
                  classes.media,
                  classes.preview,
                  customUserMedia && classes.displayFlex,
                )}
              >
                {!customUserMedia && value && /video/.test(value.type) && (
                  <video
                    height="100%"
                    width="100%"
                    controls
                    onPlaying={clickedOnVideo}
                    onPause={clickedOnVideo}
                  >
                    <source src={value.path} type={value.type} />
                    Your browser does not support HTML5 video.
                  </video>
                )}
              </CardMedia>
            </Card>
            {showControls && mediaPath && !isPlaying && (
              <React.Fragment>
                <Tooltip
                  title="Remove Image"
                  placement="right"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleDeleteImageFromItem}
                    className={classNames(classes.imageChange, classes.imageRemove)}
                    disabled={disabled}
                    classes={{ root: classes.buttonRoot }}
                  >
                    <Icon className={classes.iconSelect}>clear</Icon>
                  </Button>
                </Tooltip>
              </React.Fragment>
            )}
            {showControls && !isPlaying && (
              <React.Fragment>
                <Tooltip
                  title="Search Image"
                  placement="right"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                    className={classes.imageChange}
                    disabled={disabled}
                    classes={{ root: classes.buttonRoot }}
                  >
                    <Icon className={classes.iconSelect}>image_search</Icon>
                  </Button>
                </Tooltip>

                <div className={classes.labelInfo}>
                  <Typography variant="body2" style={{ padding: 12 }}>
                    {value && value._id && value.mimetype
                      ? `${value._id} / ${value.mimetype}`
                      : 'No image selected'}
                  </Typography>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default ImageSelection
