import React from 'react'
import Typography from '@material-ui/core/Typography'

export const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  )
}
