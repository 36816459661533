import withStyles from '@material-ui/core/styles/withStyles'
import React, { useCallback, useMemo, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import { getIconAndColorByStatus } from '~components/molecules/EnhancedTable/StateHelper'
import EnhancedTableHeadSelectItem from './EnhancedTableHeadSelectItem'

const styles = ({ spacing: { unit } }) => ({
  inputRoot: {
    maxHeight: '2rem',
  },
  select: {
    padding: 0,
    marginLeft: -unit,
  },
  button: {
    width: unit * 2.5,
    height: unit * 2.5,
    padding: 0,
  },
  icon: {
    fontSize: 'inherit',
  },
})

const EnhancedTableHeadSelect = ({
  filterActive,
  classes,
  colType,
  selected,
  options,
  multiple,
  variant,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState()
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(undefined), [])

  const handleItemClick = useCallback(
    value => {
      if (!multiple) return onChange(selected === value ? undefined : value)

      let updatedSelected = selected ? [...selected] : []

      const idx = updatedSelected.findIndex(item => item === value || item.value === value)

      if (idx !== -1) updatedSelected.splice(idx, 1)
      else updatedSelected.push({ value })

      onChange(updatedSelected)
    },
    [selected, multiple],
  )

  const handleFilterExclusion = useCallback(
    value => {
      let updatedSelected = selected ? [...selected] : []

      const idx = updatedSelected.findIndex(item => item === value || item.value === value)

      if (idx === -1) updatedSelected.push({ value, negated: true })
      else {
        const item = updatedSelected[idx]
        item.negated = !item.negated
      }

      onChange(updatedSelected)
    },
    [selected, multiple],
  )

  const renderedItems = useMemo(
    () =>
      options.map(option => {
        let { value, label, state } = option
        label = <span className={'enhanced-table-head-select-item-label'}> {label || value}</span>

        if (variant === 'status') {
          const { icon: itemIcon, className } = getIconAndColorByStatus(value)
          if (itemIcon) {
            label = (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon className={`me-2 ${className}`}>{itemIcon}</Icon>
                {label}
              </span>
            )
          }
        } else if (colType === 'boolean') {
          label = (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon style={{ marginRight: 6 }} color={value ? 'primary' : 'disabled'}>
                {value ? 'check' : 'close'}
              </Icon>
              {label}
            </span>
          )
        }

        const existingItem = multiple
          ? Array.isArray(selected) && selected.find(item => item === value || item.value === value)
          : selected === value

        return (
          <EnhancedTableHeadSelectItem
            key={value}
            selected={!!existingItem}
            negated={existingItem && existingItem.negated === true}
            label={label}
            state={state}
            onClick={() => handleItemClick(value)}
            onNegateClick={() => handleFilterExclusion(value)}
          />
        )
      }),
    [variant, colType, options, handleItemClick],
  )

  return (
    <React.Fragment>
      <IconButton
        className={classes.button}
        aria-owns={anchorEl ? 'enhanced-table-head-select' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon
          style={!filterActive ? { color: `rgba(0, 0, 0, 0.26)` } : undefined}
          className={classes.icon}
        >
          filter_list
        </Icon>
      </IconButton>
      <Menu
        id="enhanced-table-head-select"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderedItems}
      </Menu>
    </React.Fragment>
  )
}

export default withStyles(styles)(EnhancedTableHeadSelect)
