import { Quill } from 'react-quill'

let InlineEmbed = Quill.import('blots/inline')
class PriceBlot extends InlineEmbed {
  static create(value) {
    let node = super.create()

    if (!value.text) return node

    node.innerText = value.text || value.alt
    node.setAttribute('alt', value.text || value.alt)
    return node
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
    }
  }
}
PriceBlot.blotName = 'price'
PriceBlot.tagName = 'div'
PriceBlot.className = 'ql-priceContent'

// "\f02a"

export default PriceBlot
