import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import GenericMessageContent from 'shared-ui/components/molecules/GenericMessageContent'

class GenericMessagePopupTemplate extends React.Component {
  render() {
    const { classes, variant, name, title, message, action, actionOptions, points, api } =
      this.props
    const messageProps = { variant, name, title, message, action, actionOptions, points, api }

    return <GenericMessageContent {...messageProps} className={classes.root} />
  }
}

const styles = ({ spacing: { unit } }) => ({
  root: {
    maxWidth: 1280,
    margin: `${unit * 10}px auto ${unit * 5}px`,
  },
})

export default withStyles(styles)(GenericMessagePopupTemplate)
