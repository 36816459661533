import React, { useMemo, useRef, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import { Menu } from './components/molecules/Menu'
import Breadcrumbs from 'shared-ui/components/Breadcrumbs'
import Header from './components/molecules/Header'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageProgress from 'shared-ui/components/PageProgress'
import EnvBar from 'shared-ui/components/atoms/EnvBar'
import Colors from 'shared-ui/assets/styles/colors'
import { LanguageProvider } from './LanguageContext'
import classnames from 'classnames'
import { useMember, usePageData } from 'shared-ui/components/pages/PageWrapper'
import { useApi } from 'shared-ui/api/ApiContext'
import ReCaptcha from 'shared-ui/components/ReCaptcha'
import { AppFrameProvider } from 'shared-ui/components/AppFrameContext'

const styles = ({ palette, spacing: { unit } }) => ({
  '@media print': {
    content: {
      backgroundColor: palette.paper,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.paper,
  },
  contentWrapperHeadless: {
    paddingTop: '0 !important',
  },
  contentWrapper: {
    paddingTop: 64, // refers to Header height
    display: 'flex',
  },
  contentHeadless: {
    height: '100vh !important',
  },
  content: {
    height: 'calc(100vh - 64px)', // 64px refers to Header height
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 9999,
  },
  breadcrumbs: {
    minHeight: unit * 4,
  },
  page: {
    overflow: 'scroll',
    height: '100%',
    background: Colors.gradientBackground,
  },
  pagePadding: {
    padding: unit,
    paddingBottom: unit * 5,
  },
  fadeEnter: {
    opacity: 0,
    transform: `translate3d(0, ${-unit * 2}px, 0)`,
  },
  fadeEnterActive: {
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    transition: `opacity 350ms 100ms,
       transform 350ms ease-out 100ms
      `,
  },
})

export default function AppFrame(props) {
  const { appFrame } = usePageData()
  const { busy } = props

  if (!appFrame) return <PageProgress visible={busy} className="absolute z-[9999] w-full" />

  return <AppFrameComponent {...props} />
}

const AppFrameComponent = withStyles(styles)(props => {
  const { componentName, appFrame } = usePageData()
  const member = useMember()
  const api = useApi()

  const {
    version,
    settings: { languages: supportedLanguages, general: { platformName } = {} } = {},
    menu,
    header,
    env: { color } = {},
  } = appFrame

  const language = useMemo(() => {
    const cookies = document.cookie.split(/;\s?/).reduce((data, pair) => {
      const [key, value] = pair.split('=')
      return { ...data, [key]: value }
    }, {})

    return (cookies.language || navigator.language || '').toLowerCase().substr(0, 2)
  }, [])

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return supportedLanguages.find(({ id }) => id === language) || supportedLanguages[0]
  })

  const [showMenu, setShowMenu] = useState(null)

  const { classes, children, routeKey, contentKey, busy } = props

  const padding = componentName && componentName.includes('Login') ? '' : classes.pagePadding

  const handleDrawerToggle = () => {
    setShowMenu(!showMenu)
  }

  const languageSwitchInterceptors = useRef([])

  const handleLanguageChange = selectedLanguage => {
    if (languageSwitchInterceptors.current.some(interceptor => interceptor(selectedLanguage)))
      return

    document.cookie = `language=${selectedLanguage.id};path=/`
    setSelectedLanguage(selectedLanguage)
  }

  const onRegisterLanguageSwitchInterceptor = interceptor => {
    languageSwitchInterceptors.current.push(interceptor)

    return () => {
      const i = languageSwitchInterceptors.current.indexOf(interceptor)
      if (i >= 0) languageSwitchInterceptors.current.splice(i, 1)
    }
  }

  return (
    <AppFrameProvider value={appFrame}>
      <div className={classes.root}>
        {color && <EnvBar color={color} />}
        <ReCaptcha />

        <LanguageProvider
          value={{
            supportedLanguages,
            selectedLanguage,
            registerLanguageSwitchInterceptor: onRegisterLanguageSwitchInterceptor,
            onLanguageChange: handleLanguageChange,
          }}
        >
          {header && (
            <Header
              data={header}
              member={member}
              api={api}
              version={version}
              platformName={platformName}
              handleDrawerToggle={handleDrawerToggle}
            />
          )}

          <div
            className={classnames(classes.contentWrapper, {
              [classes.contentWrapperHeadless]: !header,
            })}
          >
            {menu && (
              <Menu
                menu={menu}
                header={header}
                member={member}
                platformName={platformName}
                showMenu={showMenu}
                handleDrawerToggle={handleDrawerToggle}
              >
                {children}
              </Menu>
            )}
            <main className={classnames(classes.content, { [classes.contentHeadless]: !header })}>
              {menu && (
                <React.Fragment>
                  <Breadcrumbs className={classes.breadcrumbs} menu={menu} />
                  <Divider />
                </React.Fragment>
              )}
              <TransitionGroup className={classnames(classes.page, padding)}>
                <CSSTransition
                  key={routeKey}
                  timeout={{ enter: 1000, exit: 0 }}
                  classNames={{
                    enter: classes.fadeEnter,
                    enterActive: classes.fadeEnterActive,
                  }}
                >
                  <div key={contentKey}>{children}</div>
                </CSSTransition>
              </TransitionGroup>
            </main>
          </div>

          <PageProgress visible={busy} className={classes.loader} />
        </LanguageProvider>
      </div>
    </AppFrameProvider>
  )
})
