import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import Link from 'shared-ui/components/Link'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import withWidth from '@material-ui/core/withWidth'
import Logo from './Logo'
import CommonHeaderItems from './CommonHeaderItems'

const styles = ({ palette, spacing: { unit }, breakpoints }) => ({
  root: {
    height: 64,
    paddingLeft: 0,
    backgroundColor: palette.paper,
    color: palette.grey500,
    display: 'flex',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    zIndex: 2,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    '& > *+*': {
      marginLeft: unit,
    },
  },
})

class Header extends React.Component {
  state = {
    scrolled: false,
    showLogoutDialog: false,
  }

  render() {
    const {
      classes,
      member,
      handleDrawerToggle,
      data: { items = [] },
      platformName,
      version,
      width,
    } = this.props

    return (
      <Toolbar className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'space-between' }}>
          {(width === 'sm' || width === 'xs') && member && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <Icon>menu</Icon>
            </IconButton>
          )}
          <Logo platformName={platformName.toUpperCase()} />
        </div>

        <div className={classes.items}>
          {width !== 'xs' ? <CommonHeaderItems items={items} member={member} /> : null}
          {version && (
            <Chip
              label={`v${version}`}
              onClick={() => {}}
              clickable
              component={Link}
              to={'/version'}
            />
          )}
        </div>
      </Toolbar>
    )
  }
}

export default withWidth()(withStyles(styles)(Header))
