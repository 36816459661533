import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import IntlUtil from 'shared-ui/utils/IntlUtil'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

class CustomTimeStamp extends Component {
  render() {
    const { classes } = this.props
    let { value } = this.props

    // converting 10 digit format to 13 one
    if (value && value.toString().length === 10) {
      value = (Number(value) || 0) * 1000
    }

    if (value != null) {
      value = IntlUtil.date(value, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
    }

    return (
      <TextField
        {...this.props}
        onChange={() => {}}
        onBlur={() => {}}
        value={value}
        className={classes.textField}
        disabled
      />
    )
  }
}

export default withStyles(styles)(CustomTimeStamp)
