import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const Code = ({ classes, children }) => {
  return (
    <pre className={classes.root}>
      <code>{children}</code>
    </pre>
  )
}

const styles = () => ({
  root: {
    margin: '0',
    padding: '1rem',
    overflow: 'auto',
    width: '100%',
    border: '1px solid #ccc',
    backgroundColor: '#dddddd',
    boxSizing: 'border-box',
  },
})

export default withStyles(styles)(Code)
