import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import Code from './Code'

const ErrorLog = ({ classes, log, date, file }) => {
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Typography variant={'body2'}>
          <code>{file}</code>
        </Typography>
        {date && <Typography variant={'body2'}>{IntlUtil.dateTime(date)}</Typography>}
      </div>
      <Code>{log}</Code>
    </div>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  code: {
    padding: '1rem',
    overflow: 'auto',
    width: '100%',
    border: '1px solid #ccc',
    backgroundColor: '#dddddd',
    boxSizing: 'border-box',
  },
})

export default withStyles(styles)(ErrorLog)
