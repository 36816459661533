import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { Icon, IconButton } from '@material-ui/core'

const PropertyType = ({ classes, type, settings, expanded, hasChangesInExpanded, render }) => {
  let result
  switch (type) {
    case 'reference':
    case 'link':
      result = (settings.collection ? `${type} (${settings.collection})` : type) || null
      break
    case 'enum':
      if (expanded !== undefined)
        return (
          <div className={classes.enumContainer}>
            <IconButton
              className={classNames(classes.enumExpandIcon, {
                [classes.bgChange]: !expanded && hasChangesInExpanded,
              })}
            >
              <Icon fontSize={'small'}>{expanded ? 'expand_less' : 'expand_more'}</Icon>
            </IconButton>
            {render ? render(type) : type}
          </div>
        )
      result = type || null
      break
    default:
      result = type || null
  }

  return render ? render(result) : result
}

const styles = ({ palette, spacing: { unit } }) => ({
  enumContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  enumExpandIcon: {
    marginRight: unit * 0.25,
    marginLeft: -unit * 0.5,
    padding: unit * 0.25,
  },
  bgChange: {
    backgroundColor: palette.common.orange,
  },
})

export default withStyles(styles)(PropertyType)
