export default {
  GOTO: 'goto',
  PATCH: 'patch',
  PATCH_SINGLE: 'patchSingle',
  POST: 'post',
  CREATE: 'create',
  CREATE_FROM_FILE: 'createFromFile',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  DELETE_SINGLE: 'deleteSingle',
  DELETE_SELECTED: 'deleteSelected',
  OPEN_DETAIL: 'openDetail',
  POST_SELECTED: 'postSelected',
  DOWNLOAD: 'download',
  SSOMETADATA: 'ssometadata',
  DOWNLOAD_CSV: 'downloadCsv',
  UPLOAD_CSV: 'uploadCsv',
  UPLOAD_FILES: 'uploadFiles',
  SUFFIX_SELECTED: 'selected',
  TEST: 'test',
  SAVE_FROM_DRAFT: 'saveFromDraft',
  SAVE_FILTERS: 'saveFilters',
}
