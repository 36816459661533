import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { CardContent } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import LinearProgress from '@material-ui/core/LinearProgress'

const KubeRegionStats = props => {
  const { data: cluster = {}, classes, className, api, onChange, onMoreClick } = props
  const { name: clusterName } = cluster
  const [data, setData] = useState({})
  const [drawerData, setDrawerData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      let { request } = api.getKubeCluster(clusterName)
      const { data: { markets = {}, service = {} } = {}, unhealthyArr } = await request
      setData(markets)
      setDrawerData(unhealthyArr)
      setLoading(false)
      const { up = 0, down = 0, total = 0 } = markets || {}
      onChange &&
        onChange(prev => {
          // const { up = 0, down, total } = data
          return {
            ...prev,
            [clusterName]: {
              up: (prev.up || 0) + up,
              down: (prev.down || 0) + down,
              total: (prev.total || 0) + total,
            },
          }
        })
    }

    fetchData()

    const intervalId = setInterval(fetchData, 10000) // Call every 5 seconds

    return () => clearInterval(intervalId) //
  }, [])

  const { up, down, total, name, healthy } = data || {}
  const coverage = ((up / total) * 100).toFixed(2)
  return (
    <Card className={classes.card + ` ${className}`}>
      <CardContent className={'relative flex flex-col gap-y-3'}>
        <Typography variant="h5" className={classes.title} color="textSecondary" gutterBottom>
          {clusterName}
        </Typography>
        <Typography variant="h6" component="h2">
          {isNaN(coverage) ? 'Loading...' : `${((up / total) * 100).toFixed(2)}% Coverage`}{' '}
          {!loading && down > 0 && ' ⚠️'}
        </Typography>
        {loading ? (
          <LinearProgress variant="indeterminate" />
        ) : (
          <LinearProgress variant="determinate" value={coverage} />
        )}
        {/*<StatusCircle a={up} b={down} c={total}/>*/}
        <div className={'flex flex-col gap-y-2'}>
          {down > 0 && (
            <div className={'flex gap-x-2'}>
              <Typography variant={'h5'}>{down}</Typography>
              <Icon color={'error'}>{'arrow_circle_down'}</Icon>
              <Typography variant={'h5'}>down</Typography>
            </div>
          )}
          <div className={'flex gap-x-2'}>
            <Typography variant={'h5'}>{up}</Typography>
            <Icon color={'primary'}>{'arrow_circle_up'}</Icon>
            <Typography variant={'h5'}>UP</Typography>
          </div>
          <div className={'flex gap-x-2'}>
            <Typography variant={'h5'}>{total}</Typography>
            <Icon color={''}>{'brightness_1'}</Icon>
            <Typography variant={'h5'}>Total</Typography>
          </div>
        </div>
      </CardContent>
      {down > 0 && (
        <CardActions>
          <Button size="small" onClick={() => onMoreClick(drawerData)}>
            Show Warning
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

const styles = {
  card: {
    // minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}

export default withStyles(styles)(KubeRegionStats)
