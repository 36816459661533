import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Link from 'shared-ui/components/Link'
import { ReactComponent as LogoSVG } from 'shared-ui/assets/img/logo-black.svg'

const styles = ({ palette, spacing: { unit }, breakpoints }) => ({
  logo: {
    marginTop: unit * 0.25,
    marginLeft: unit,
    position: 'relative',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.up('md')]: {
      marginLeft: unit * 5,
    },
  },
  platformName: {
    flip: false,
    fontSize: '0.977em',
    color: palette.grey500,
    alignSelf: 'flex-end',
    bottom: 0,
  },
})

const Logo = ({ classes, platformName }) => (
  <Link to={'/'} className={classes.logo} style={{ flip: false, direction: 'ltr' }}>
    <LogoSVG />
    <Typography className={classes.platformName} variant={'caption'}>
      {platformName}
    </Typography>
  </Link>
)

export default withStyles(styles)(Logo)
