import { Quill } from 'react-quill'

let BlockEmbed = Quill.import('blots/block/embed')
class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create()
    node.setAttribute('alt', value.alt)
    node.setAttribute('src', value.src)
    node.setAttribute('data_object_id', value.data_object_id)
    node.setAttribute('style', 'max-width: 300px; max-height: 300px')
    return node
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('src'),
      data_object_id: node.getAttribute('data_object_id'),
    }
  }
}
ImageBlot.blotName = 'image'
ImageBlot.tagName = 'img'

export default ImageBlot
