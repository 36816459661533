import { useEffect, useState } from 'react'
import KubeMarket from '~components/page/KubernetesPage/KubeMarket'
import Grid from '@material-ui/core/Grid'
import { withApi } from 'shared-ui/api/ApiContext'
import CircularProgress from '@material-ui/core/CircularProgress'

const KubeMarkets = props => {
  const { api, expanded, panelRefs, handleExpansion } = props
  const [data, setData] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      let { request } = api.getAllNamespaces()
      const data = await request
      setData(data)
      setLoading(false)
    }

    fetchData()

    const intervalId = setInterval(fetchData, 30000) // Call every 5 seconds
    return () => clearInterval(intervalId) //
  }, [])

  const { markets = [] } = data

  if (loading)
    return (
      <div className="flex h-52 w-full items-center justify-center">
        <CircularProgress align="center" size={32} />
      </div>
    )
  return (
    <Grid>
      {markets?.map((market, index) => {
        const { name } = market
        return (
          <KubeMarket
            index={name}
            expanded={expanded[name]}
            onChange={() => handleExpansion(name)}
            panelRefs={panelRefs}
            {...market}
          />
        )
      })}
    </Grid>
  )
}

export default withApi(KubeMarkets)
