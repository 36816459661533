import React, { useCallback, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Button,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import ErrorLog from './ErrorLog'
import Code from './Code'
import { withApi } from 'shared-ui/api/ApiContext'
import IntlUtil from 'shared-ui/utils/IntlUtil'

const AdminIssuesPage = props => {
  const { classes, errors, warnings, api, env } = props
  const [tabIndex, setTabIndex] = useState(() => {
    const value = window.localStorage.getItem('admin:issues_tab_index')
    return parseInt(value) || 0
  })
  const [showResolved, setShowResolved] = useState(() => {
    const value = window.localStorage.getItem('admin:show_resolved_issues')
    return value === 'true'
  })

  const onTabChange = (e, idx) => {
    window.localStorage.setItem('admin:issues_tab_index', idx.toString())
    setTabIndex(idx)
  }

  const onResolveIssueClick = useCallback(e => {
    const hash = e.currentTarget.getAttribute('data-hash')
    api.resolveIssue(hash)
  }, [])

  const onResolveAllIssuesClick = useCallback(() => {
    api.resolveIssue('reset')
  }, [])

  const onToggleShowResolved = useCallback(() => {
    setShowResolved(currentValue => {
      const newValue = !currentValue
      window.localStorage.setItem('admin:show_resolved_issues', newValue.toString())
      return newValue
    })
  }, [])

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary>
          <Typography variant={'h5'}>{`ENV ${(env.HOST || '').toUpperCase()}`}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Button
            className={classes.resolveButton}
            variant={'contained'}
            onClick={onResolveAllIssuesClick}
          >
            RESET ALL TRACKED ISSUES
          </Button>

          <Code>{JSON.stringify(env, null, 4)}</Code>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <FormControlLabel
        control={<Switch onClick={onToggleShowResolved} checked={showResolved} />}
        label="Show resolved"
      />

      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="🚨 ERRORS" />
        <Tab label="⚠️ WARNINGS" />
      </Tabs>

      {renderIssues(tabIndex)}
    </div>
  )

  function renderIssues(idx) {
    const issues = idx === 0 ? errors : warnings

    return issues.map(issue => {
      const { count, details, hash, known, lastDate, message } = issue

      if (known && !showResolved) return null

      return (
        <ExpansionPanel className={{ [classes.issueResolved]: known }}>
          <ExpansionPanelSummary>
            <div className={classes.summaryContent}>
              <div>
                <Typography variant={'h6'}>
                  <code>
                    <strong>{message}</strong>
                  </code>
                </Typography>
                <Typography variant={'body1'}>{IntlUtil.dateTime(lastDate)}</Typography>
              </div>

              <Chip className={classes.countChip} label={count} />
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <Button
              disabled={known}
              className={classes.resolveButton}
              variant={'contained'}
              onClick={onResolveIssueClick}
              data-hash={hash}
            >
              RESOLVE
            </Button>

            {details &&
              details.map(detail => {
                return <ErrorLog {...detail} />
              })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    })
  }
}

const styles = ({ palette, spacing: { unit } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  issueResolved: {
    opacity: 0.66,
  },
  resolveButton: {
    margin: '0 auto',
  },
  summaryContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  countChip: {
    minWidth: '4rem',
    justifyContent: 'center',
    backgroundColor: palette.common.red,
    color: palette.paper,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    ['& > *+*']: {
      marginTop: '1rem',
    },
  },
})

export default withApi(withStyles(styles)(AdminIssuesPage))
