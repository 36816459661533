import React from 'react'
import EnhancedTable from '~components/molecules/EnhancedTable'
import {
  findAction,
  processActionRequest,
} from '~components/page/ModelViewWrapper/ModelViewWrapper'
import ACTION_NAMES from '~components/page/ModelViewWrapper/actionNames'
import { withLanguage } from '~src/LanguageContext'
import debounce from 'lodash/debounce'
import ModelNote from '~components/molecules/ModelNote'
import {
  encodeFilters,
  getEncodedFilterFromQuery,
  getUpdatedQuery,
  decodeFilters,
} from './searchHelper'
import queryString from 'query-string'
import ImagePreviewDrawer from '~components/molecules/ImagePreviewDrawer'

const nonToolbarActions = [
  ACTION_NAMES.PATCH,
  ACTION_NAMES.DUPLICATE,
  ACTION_NAMES.DELETE_SINGLE,
  ACTION_NAMES.OPEN_DETAIL,
]

const isSelectionAction = ({ action }) =>
  action.toLowerCase().indexOf(ACTION_NAMES.SUFFIX_SELECTED) > -1

class ModelList extends React.Component {
  constructor(props) {
    super(props)

    let { actions = [], columnData, rowData, numRows, page, rowsPerPage } = props

    const openDetailAction = findAction(actions, ACTION_NAMES.OPEN_DETAIL)
    let detailUrl = openDetailAction ? openDetailAction.url : undefined

    const patchAction = findAction(actions, ACTION_NAMES.PATCH)
    const inlineEditableColumns = patchAction && (patchAction.columns || 'all')

    const encodedSearchFilters = getEncodedFilterFromQuery()
    const searchFilters = decodeFilters(encodedSearchFilters)

    this.state = {
      selected: [],
      actionsFiltered:
        actions &&
        actions
          .filter(({ action }) => !nonToolbarActions.includes(action))
          .map(action => ({
            ...action,
            selectable: isSelectionAction(action),
          })),
      isRowDeletable: Boolean(findAction(actions, ACTION_NAMES.DELETE_SINGLE)),
      isRowDuplicatable: Boolean(findAction(actions, ACTION_NAMES.DUPLICATE)),
      isRowSelectable: actions && Boolean(actions.find(action => isSelectionAction(action))),
      isRowDetailLink: Boolean(openDetailAction),
      inlineEditableColumns,
      columnData,
      rowData,
      numRows,
      searchFilters,
      encodedSearchFilters,
      page,
      rowsPerPage,
      detailUrl,
      disabledActions: this._updateSaveFiltersActionState(encodedSearchFilters),
      drawerOpen: false,
    }
  }

  _updateSaveFiltersActionState = encodedSearchFilters => {
    const { savedFilters } = this.props
    return {
      [ACTION_NAMES.SAVE_FILTERS]:
        !encodedSearchFilters ||
        !!(
          savedFilters &&
          savedFilters.find(({ filterString }) => filterString === encodedSearchFilters)
        ),
    }
  }

  updateDisabledActions = (selectedIds, rowData, actions) => {
    const disabledActions = {}
    const evaluateCondition = (condition, row) => {
      let negate = false
      if (condition.startsWith('!')) {
        negate = true
        condition = condition.slice(1)
      }
      const [key, value] = condition.split('.')
      const conditionMet = row && row[key] === value
      return negate ? !conditionMet : conditionMet
    }

    actions.forEach(({ disabledIf, action }) => {
      if (disabledIf) {
        const hasCondition = selectedIds.some(selectedId => {
          const row = rowData.find(r => r._id === selectedId)
          return evaluateCondition(disabledIf, row)
        })

        disabledActions[action] = hasCondition
      } else {
        disabledActions[action] = false
      }
    })

    this.setState(prevState => {
      const saveFiltersState = this._updateSaveFiltersActionState(prevState.encodedSearchFilters)
      return {
        disabledActions: {
          ...prevState.disabledActions,
          ...disabledActions,
          ...saveFiltersState,
        },
      }
    })
  }
  fetchRows = async (data, immediate = false) => {
    let { searchFilters, disabledActions } = this.state

    const params = queryString.parse(window.location.search)
    let state = {}

    if (data) {
      const { page, rowsPerPage, ...rest } = data
      data = { ...searchFilters, ...rest }

      Object.keys(data).forEach(key => {
        if (data[key] === undefined) delete data[key]
      })

      if (page !== undefined) state.page = params.p = page
      if (rowsPerPage !== undefined) state.rowsPerPage = params.r = rowsPerPage
    } else data = {}

    let encodedSearchFilters = null

    if (Object.keys(data).length > 0) {
      encodedSearchFilters = encodeFilters(data)
      params.s = encodeFilters(data)
    } else {
      delete params.s
    }

    let query = queryString.stringify(params)
    window.history.replaceState(null, '', `${window.location.pathname}${query ? `?${query}` : ''}`)

    this.setState({
      ...state,
      encodedSearchFilters,
      searchFilters: data,
      isLoading: true,
      disabledActions: {
        ...disabledActions,
        ...this._updateSaveFiltersActionState(encodedSearchFilters),
      },
    })

    if (immediate) this._requestRowsDebouncedLeadingAndTrailing(query)
    else this._requestRowsDebounced(query)
  }

  handleInlineChange = async e => {
    const { target, ref } = e
    const { row, col } = ref.props
    const { api, actions, selectedLanguage } = this.props

    let { name } = col
    const { settings: { multilang } = {} } = col
    if (multilang) name = `${name}__${selectedLanguage.id}`

    let initialValue = name in row ? row[name] : col.defaultValue
    if (target.value === initialValue) return

    const patchAction = findAction(actions, ACTION_NAMES.PATCH)

    let apiPath
    let actionUrl
    let actionMethod = 'PATCH'
    if (patchAction) {
      actionUrl = patchAction.url
      actionMethod = patchAction.method
    } else return

    if (!actionUrl) return

    apiPath = actionUrl.replace(/\[id\]/gi, row._id)

    let result

    try {
      const { request } = api.updateModelEntry(
        apiPath,
        {
          [col.name]: target.value,
        },
        actionMethod,
        selectedLanguage.id,
      )
      ;({ result } = await request)
    } catch (err) {
      console.error('Updating the model entry failed:', err)
      return
    }

    if (!result) return

    this.updateRow(col, row, { name: name, value: result.rawData[name] })
  }

  updateRow = (col, row, update) => {
    let { rowData } = this.state
    const index = rowData.findIndex(r => r._id === row._id)

    rowData = [...rowData]
    let data = { ...rowData[index] }

    let { name, value } = update
    rowData[index] = { ...data, [name]: value }

    this.setState({ rowData })
  }

  handleActionRequest = async action => {
    const actionResult = await processActionRequest(action)
    if (actionResult === true) return

    let { selected } = this.state
    const { api, member } = this.props
    let { action: actionName, url, confirm, dynPrompt, method = 'POST' } = action

    switch (actionName) {
      case ACTION_NAMES.CREATE: {
        return api.createModelEntry(url)
      }
      case ACTION_NAMES.CREATE_FROM_FILE: {
        if (this.fileUploadInput) {
          this.handleUpload = this._handleFileUpload
          this.fileUploadInput.click()

          return new Promise(resolve => {
            this._uploadResolver = resolve
          })
        }
        break
      }
      case ACTION_NAMES.DOWNLOAD_CSV: {
        const { encodedSearchFilters } = this.state
        if (encodedSearchFilters) url = url + '&' + getUpdatedQuery(encodedSearchFilters)
        try {
          return api.downloadModel(url)
        } catch (e) {
          console.error('Error while downloading CSV file', e)
        }
      }

      case ACTION_NAMES.UPLOAD_FILES: {
        if (this.fileUploadInput) {
          this.handleUpload = this._handleFilesUpload
          this.fileUploadInput.click()

          return new Promise(resolve => (this._uploadResolver = resolve))
        }
        break
      }

      case ACTION_NAMES.UPLOAD_CSV: {
        if (this.fileUploadInput) {
          try {
            this.handleUpload = this._handleCSVUpload
            this.fileUploadInput.click()

            return new Promise(resolve => {
              this._uploadResolver = resolve
            })
          } catch (e) {
            console.error('Error while uploading csv file', e)
            return Promise.reject(e)
          }
        }
        break
      }
      case ACTION_NAMES.POST_SELECTED: {
        if (confirm && !window.confirm(confirm.replace('%s', selected.length))) return
        const body = { [actionName]: selected, actionOwner: member?._id }
        if (prompt && dynPrompt) {
          const { message = '', field, defaultValue } = dynPrompt
          const value = window.prompt(message, defaultValue)
          if (!value?.trim()) return
          Object.assign(body, { [field]: value })
        }
        api.connector[method.toLowerCase()](url, body)
        break
      }
      case ACTION_NAMES.DELETE_SELECTED: {
        this.handleRowsDelete(action, selected)
        break
      }
      case ACTION_NAMES.SAVE_FILTERS: {
        let title = window.prompt('Please enter a title', 'Unnamed search filter')
        if (title === null) return

        const { modelName } = this.props
        const { encodedSearchFilters } = this.state

        const { request } = api.connector[method.toLowerCase()](url, {
          data: {
            title,
            model: modelName,
            filterString: encodedSearchFilters,
          },
        })

        return request
      }
      default:
        break
    }
  }

  handleToggleRow = row => {
    this.setState(prevState => {
      let prevSelected = prevState.selected
      let selected = [...prevSelected]
      let index = prevSelected.indexOf(row._id)
      if (index === -1) {
        selected.push(row._id)
      } else {
        selected.splice(index, 1)
      }
      const disabledActions = this.updateDisabledActions(
        selected,
        prevState.rowData,
        this.props.actions,
      )
      return { selected, disabledActions }
    })
  }

  handleSelectAll = (e, visibleRows = []) => {
    this.setState(prevState => {
      const { selected, rowData = [] } = prevState
      const numRows = rowData.length

      if (selected.length === numRows || visibleRows.length === selected.length) {
        return { selected: [] }
      }
      const disabledActions = this.updateDisabledActions(
        visibleRows.map(row => row._id),
        rowData,
        this.props.actions,
      )
      return { selected: visibleRows.map(row => row._id), disabledActions }
    })
  }

  handleRowDelete = async row => {
    const { api, actions } = this.props
    const action = actions.find(({ action }) => action === ACTION_NAMES.DELETE_SINGLE)

    const actionRequest = await processActionRequest(action, api, row)

    if (!actionRequest) return
    try {
      await actionRequest.request
    } catch (err) {
      return console.error(err)
    }

    let { rowData } = this.state
    rowData = [...rowData]
    const index = rowData.findIndex(otherRow => row._id === otherRow._id)

    if (index >= 0) {
      rowData.splice(index, 1)
      this.setState({ rowData })
    }
  }

  handleRowDuplicate = async row => {
    const { api, actions } = this.props
    const action = actions.find(({ action }) => action === ACTION_NAMES.DUPLICATE)

    processActionRequest(action, api, row)
  }

  handleRowsDelete = async (action, selected) => {
    const confirm = window.confirm('Are you sure you want to delete the selected rows?')

    if (!confirm) {
      return
    }

    try {
      const { api } = this.props
      let { rowData = [] } = this.state
      const { action: actionName, url, method = 'POST' } = action

      const { request } = api.connector[method.toLowerCase()](url, {
        data: { [actionName]: selected },
      })
      const response = await request

      if (!response) {
        return
      }

      rowData = rowData.filter(({ _id }) => !selected.includes(_id))

      this.setState({ selected: [] })
      this.setState({ rowData })
    } catch (e) {
      console.error('Error while deleting row', e)
    }
  }

  handleUploadClick = () => {
    // handling upload dialog cancellation

    document.body.onfocus = () => {
      if (this.fileUploadInput && !this.fileUploadInput.value.length)
        this._uploadResolver && this._uploadResolver(null)

      document.body.onfocus = null
    }
  }

  handleUpload = async () => {} // to be dynamically assigned

  _handleCSVUpload = async () => {
    if (!this.fileUploadInput || !this.fileUploadInput.files || !this.fileUploadInput.files.length)
      return

    const { api, actions } = this.props

    const action = actions.find(({ action }) => action === ACTION_NAMES.UPLOAD_CSV)
    const { url } = action

    const file = this.fileUploadInput.files[0]
    this.fileUploadInput.value = ''

    try {
      const { request } = api.uploadModelData(url, file)
      this._resolveUpload(await request)
    } catch (e) {
      console.error('Error uploading csv file', e)
    }
  }

  _handleFilesUpload = async () => {
    if (!this.fileUploadInput || !this.fileUploadInput.files || !this.fileUploadInput.files.length)
      return

    const { api, actions, modelName = [] } = this.props

    const action = actions.find(({ action }) => action === ACTION_NAMES.UPLOAD_FILES)
    const { url } = action

    const files = [...this.fileUploadInput.files]
    this.fileUploadInput.value = ''
    const uploadTags = Array.isArray(modelName) ? modelName : [modelName]
    let req
    try {
      req = {
        request: Promise.all(
          files.map(file => {
            const { request } = api.uploadMedia(url, file, uploadTags)
            return request
          }),
        ),
      }
    } catch (e) {
      console.error('Error uploading media:', e)
    }

    this._resolveUpload(req)
  }

  _handleFileUpload = async () => {
    const { api, actions } = this.props

    const action = actions.find(({ action }) => action === ACTION_NAMES.CREATE_FROM_FILE)
    const { url } = action

    const file = this.fileUploadInput.files[0]
    this.fileUploadInput.value = ''

    try {
      const { request } = api.createModelEntryFromFile(url, file)
      this._resolveUpload(await request)
    } catch (e) {
      console.error('Error while uploading file')
    }
  }

  _resolveUpload = req => {
    if (this._uploadResolver) {
      this._uploadResolver(req)
      this._uploadResolver = undefined
    }
  }

  _requestRows = async query => {
    const { api, modelName, onAPICall } = this.props
    const { request, cancel } = api.getModelListData(modelName, query || '')

    if (this.pendingRowRequestCancel) this.pendingRowRequestCancel()

    this.pendingRowRequestCancel = cancel

    try {
      let data = await request

      if (onAPICall) onAPICall(data)

      let { rowData, page, rowsPerPage, numRows } = data

      this.setState({ isLoading: false, rowData, page, rowsPerPage, numRows })
    } catch (err) {
      this.setState({ isLoading: false, error: err })
    }
  }

  _requestRowsDebounced = debounce(this._requestRows, 500)
  _requestRowsDebouncedLeadingAndTrailing = debounce(this._requestRows, 500, { leading: true })

  handlePageChange = async (event, page) => {
    this.fetchRows({ page })
  }

  handleSelectFilters = filters => {
    this.fetchRows(filters ? decodeFilters(filters) : null)
  }

  handleSearchReqest = async (search, immediate) => {
    this.fetchRows({ search }, immediate)
  }

  handleSortRequest = async (orderBy, orderDir) => {
    this.fetchRows({ orderBy, orderDir })
  }

  handleColumnsChangeRequest = async columns => {
    try {
      const { api, modelName } = this.props
      const { request } = api.customizeColumns(columns, modelName)
      await request
    } catch (e) {
      console.error('Error while changing columns', e)
    }
  }

  handleFilterRequest = async filter => {
    let {
      searchFilters: { filters },
    } = this.state
    filters = { ...filters, ...filter }

    Object.keys(filters).forEach(key => {
      if (filters[key] === undefined || filters[key].length === 0) delete filters[key]
    })

    if (Object.keys(filters).length === 0) filters = undefined

    this.fetchRows({ filters })
  }

  handleRowsPerPageChange = event => {
    const rowsPerPage = event.target.value
    this.fetchRows({ rowsPerPage })
  }

  handleDrawerToggle = () => {
    this.setState(prev => {
      return { ...prev, drawerOpen: !prev.drawerOpen }
    })
  }

  onCellClick = _id => {
    this.setState({ selectedRow: _id })
  }

  render() {
    const {
      className,
      isRowDeletable,
      isRowDuplicatable,
      isRowSelectable,
      selected,
      actionsFiltered,
      rowData,
      columnData,
      inlineEditableColumns,
      numRows,
      page,
      rowsPerPage,
      searchFilters,
      isLoading,
      detailUrl,
      encodedSearchFilters,
      disabledActions,
      selectedRow,
      drawerOpen,
    } = this.state

    const { filters, search, orderBy, orderDir } = searchFilters

    const {
      selectedLanguage,
      supportedLanguages,
      selectMultiple = false,
      notes,
      isCMS,
      actions,
      savedFilters,
      isSearchLimited,
    } = this.props

    const saveFiltersAction = findAction(actions, ACTION_NAMES.SAVE_FILTERS)

    return (
      <div className={className}>
        {notes && <ModelNote {...notes} />}

        <ImagePreviewDrawer
          open={drawerOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          data={rowData.find(({ _id }) => _id === selectedRow)}
        />

        <EnhancedTable
          inlineEditableColumns={inlineEditableColumns}
          onInlineChange={this.handleInlineChange}
          isRowSelectable={isRowSelectable}
          isRowDuplicatable={isRowDuplicatable}
          isRowDeletable={isRowDeletable}
          columnData={columnData}
          rowData={rowData}
          actions={actionsFiltered}
          disabledActions={disabledActions}
          savedFilters={saveFiltersAction && savedFilters}
          encodedSearchFilters={encodedSearchFilters}
          onActionRequested={this.handleActionRequest}
          onRowToggled={this.handleToggleRow}
          onDeleteRowRequested={this.handleRowDelete}
          onDuplicateRowRequested={this.handleRowDuplicate}
          onSelectAllRequested={this.handleSelectAll}
          onSelectFiltersRequested={this.handleSelectFilters}
          selected={selected}
          supportedLanguages={supportedLanguages}
          selectedLanguage={selectedLanguage}
          page={page}
          rowsPerPage={rowsPerPage}
          numRows={numRows}
          orderBy={orderBy}
          orderDir={orderDir}
          filters={filters}
          detailUrl={detailUrl}
          onRowsPerPageChangeRequest={this.handleRowsPerPageChange}
          onPageChangeRequest={this.handlePageChange}
          onSearchRequested={this.handleSearchReqest}
          onSortRequest={this.handleSortRequest}
          onFilterRequest={this.handleFilterRequest}
          onColumnsChangeRequest={this.handleColumnsChangeRequest}
          searchText={search}
          isLoading={isLoading}
          isCMS={isCMS}
          isSearchLimited={isSearchLimited}
          handleDrawerToggle={this.handleDrawerToggle}
          onCellClick={this.onCellClick}
          selectedRow={selectedRow}
        />
        <input
          ref={ref => (this.fileUploadInput = ref)}
          style={{ display: 'none' }}
          type="file"
          onClick={this.handleUploadClick}
          multiple={selectMultiple}
          onChange={(...params) => this.handleUpload(...params)}
        />
      </div>
    )
  }
}

export default withLanguage(ModelList)
