import React, { PureComponent } from 'react'
import DatePicker from 'material-ui-pickers/DatePicker'
import DateTimePicker from 'material-ui-pickers/DateTimePicker'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'
import PropTypes from 'prop-types'
import DateRangePicker from './DateRangePicker'

const CustomDate = props => {
  return <DateWrapper Component={DatePicker} format="DD/MM/YYYY" {...props} />
}

const CustomDateTime = props => {
  return <DateWrapper Component={DateTimePicker} format="DD/MM/YYYY HH:mm:ss" {...props} />
}

const CustomRangeDate = props => {
  return <DateWrapper Component={DateRangePicker} format="DD/MM/YYYY" {...props} />
}

class DateWrapper extends PureComponent {
  handleClear = () => {
    this.handleDateChange(null)
  }

  handleDateChange = date => {
    const { onChange, type } = this.props

    let value = null

    if (Array.isArray(date)) {
      value = date.map(d => moment.utc(d).toISOString())
    } else if (date) {
      value = moment.utc(date).toISOString()
    }

    onChange &&
      onChange({
        target: {
          name: this.props.name,
          value,
          type,
        },
      })
  }

  render() {
    const { Component, classes, required, type, iconOnly, value, ...props } = this.props

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <Component
          {...props}
          classes={iconOnly ? { root: classes.iconOnly } : {}}
          className={classes.root}
          clearable={true}
          required={required}
          onChange={this.handleDateChange}
          onClear={this.handleClear}
          iconOnly={iconOnly}
          autoOk
          value={value || null}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

const styles = {
  root: {
    '& > div': {
      alignItems: 'center',
    },
  },
  iconOnly: {
    '& > div': {
      '&:before': {
        display: 'none',
      },
      '& > div': {
        margin: 0,
        height: 'auto',
        '& > button': {
          padding: 0,
        },
      },
    },
    '& input': {
      display: 'none',
    },
  },
}

CustomDate.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  iconOnly: PropTypes.bool,
  onChange: PropTypes.func,
}

CustomDateTime.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  iconOnly: PropTypes.bool,
  onChange: PropTypes.func,
}

CustomRangeDate.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  iconOnly: PropTypes.bool,
  onChange: PropTypes.func,
}

DateWrapper = withStyles(styles)(DateWrapper)

export { CustomDate, CustomDateTime, CustomRangeDate }
