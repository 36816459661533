import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import hotkeys from 'hotkeys-js'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'

import Factory from '~components/atom/FieldFactory/FieldFactory'
import ModelActionBar from '~components/molecules/ModelActionBar/ModelActionBar'
import { processActionRequest } from '~components/page/ModelViewWrapper/ModelViewWrapper'
import ACTION_NAMES from '~components/page/ModelViewWrapper/actionNames'
import { withLanguage } from '~src/LanguageContext'
import { Prompt } from 'react-router-dom'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    top: -unit,
    zIndex: 999,
    backgroundColor: palette.paper,
    padding: unit,
    paddingTop: unit * 0.5,
    paddingBottom: unit * 0.5,
    margin: -unit,
    marginBottom: unit,
  },
  noPadding: {
    padding: 0,
  },
  singleButton: {
    paddingLeft: unit * 2,
  },
})

const nonToolbarActions = [ACTION_NAMES.TEST]

class ModelDetails extends React.Component {
  constructor(props) {
    super(props)

    const { structure } = props

    this.state = {
      id: 0,
      ...props,
      expanded: structure.map(({ optional }) => !optional),
      changed: {},
    }
  }

  componentDidMount() {
    const { actions, registerLanguageSwitchInterceptor } = this.props

    let hotkeysActios = actions.reduce((acc, elem) => {
      if (elem.shortCut) {
        elem.shortCut.forEach(h => (acc[h] = elem))
      }
      return acc
    }, {})

    const shortCutsList = [].concat(...Object.keys(hotkeysActios)).join(',')

    hotkeys(shortCutsList, (event, handler) => {
      event.preventDefault()
      const action = hotkeysActios[handler.shortcut]
      this.handleActionClick(action)
    })

    hotkeys.filter = () => true

    this.unregisterInterceptor = registerLanguageSwitchInterceptor(this.checkChangedLangFields)
  }

  componentWillUnmount() {
    this.unregisterInterceptor && this.unregisterInterceptor()
  }

  static getChangedLangFields = (changed, schemeData) => {
    return Object.keys(changed).filter(key => {
      const scheme = schemeData.find(s => s.name === key)
      return scheme && scheme.settings && scheme.settings.multilang
    })
  }

  checkChangedLangFields = () => {
    return ModelDetails.getChangedLangFields(this.state.changed, this.props.schemeData).length > 0
      ? !window.confirm(
          'You have unsaved changes, are you sure you want to discard them and switch language?',
        )
      : false
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.selectedLanguage !== prevState.selectedLanguage) {
      let changed = { ...prevState.changed }
      const { schemeData } = props

      ModelDetails.getChangedLangFields(changed, schemeData).forEach(field => delete changed[field])

      return { changed, selectedLanguage: props.selectedLanguage, id: prevState.id + 1 }
    }

    return null
  }

  handleActionClick = async action => {
    const { api } = this.props
    const { pendingPatch } = this.state
    const { action: actionName, url } = action

    switch (actionName) {
      case ACTION_NAMES.PATCH: {
        if (pendingPatch) return
        await this.saveAllChanges(action)
        break
      }
      case ACTION_NAMES.SAVE_FROM_DRAFT: {
        return api.createModelEntry(url, this.state.changed)
      }
      default:
        const { rawData, member } = this.props
        return processActionRequest(action, api, { ...rawData, actionOwner: member?._id })
    }
  }

  handleRequestSave = async () => {
    return this.saveAllChanges()
  }

  handleChange = async event => {
    const { selectedLanguage } = this.props
    const { target } = event
    let { value, name } = target

    this.setState(prevState => {
      const schemeDataItem = prevState.schemeData.find(item => item.name === name) || {}

      let { settings: { multilang: itemMultilang } = {} } = schemeDataItem
      let fieldName = name

      if (itemMultilang) fieldName += `__${selectedLanguage.id}`

      let initialValue = prevState.rawData[fieldName]

      let hasChanged = value !== initialValue

      let { changed } = prevState
      let updateValue = {}

      if (!hasChanged) {
        changed = { ...changed }
        delete changed[name]
        return { changed }
      }

      return {
        ...updateValue,
        changed: {
          ...changed,
          [name]: value,
        },
      }
    })
  }

  saveAllChanges = async () => {
    const { changed } = this.state

    const hasChanges = Object.keys(changed).length > 0
    if (!hasChanges) {
      return
    }

    const result = await this._callSaveApi(changed)

    if (!result) return

    this.setState({
      ...result,
      changed: {},
    })
  }

  async _callSaveApi(data) {
    const {
      api,
      actions,
      selectedLanguage,
      rawData: { _id },
    } = this.props

    const action = actions.find(a => a.action === ACTION_NAMES.PATCH)
    let { url, method } = action
    url = url.replace('[id]', _id)

    let response
    let result
    try {
      this.setState({ pendingPatch: true })
      const lang = selectedLanguage.locale && selectedLanguage.locale.substring(0, 2)
      const { request } = api.updateModelEntry(url, data, method, lang)

      response = await request
      ;({ result } = response)
    } catch (err) {
      this.setState({ pendingPatch: false })
      return null
    }

    this.setState({ pendingPatch: false })

    if (!result) {
      return null
    }

    return result
  }

  handleTrigger = async (actionId, responseCallback) => {
    const { api, rawData, actions } = this.props
    const { changed } = this.state
    const action = actions.find(({ id }) => id === actionId)
    if (action) {
      try {
        const result = await processActionRequest(action, api, rawData, changed)
        if (typeof result === 'object') await result.request
      } catch (err) {
        console.error('Trigger execution failed:', err)
      }
    }
    responseCallback && responseCallback()
  }

  checkFieldVisibility(visibleIf) {
    if (!visibleIf) return true

    const { rawData, schemeData, changed } = this.state

    const matchCond = c => {
      let [name, targetValue] = c.split('.')
      let not = false
      name.startsWith('!') && (name = name.slice(1)) && (not = true)
      let value
      if (name in changed) {
        value = changed[name]
      } else {
        if (name in rawData) value = rawData[name] || false
        else {
          const scheme = schemeData.find(s => s.name === name)
          value = scheme ? scheme.defaultValue : false
        }
      }
      return not ? value.toString() !== targetValue : value.toString() === targetValue
    }

    if (visibleIf.includes('&')) {
      return visibleIf.split('&').every(matchCond)
    } else {
      return visibleIf.split('|').some(matchCond)
    }
  }

  _renderFields(_items, _state, _props, _methods, __renderFields) {
    const { schemeData, rawData, changed } = _state
    const { api, selectedLanguage, supportedLanguages, sectionType } = _props

    const multilang = supportedLanguages.length > 1

    const allowedFields = [
      // CRM Contact fields
      'contactFirstName',
      'middleName',
      'alias',
      'contactLastName',
      'contactBirthDate',
      'emailAddress',
      'mobilePhone',
      'contactStatus',
      'workPhoneNb',
      'fiscalCode',
      'origin',
      'accountCode',
      'contactType',
      'preferredLanguage',
      'loyaltyType',
      'cardUid',
      'isPrimary',
      // CRM Account fields
      'accountName',
      'accountType',
      'consultantCode',
      'salesRepCode',
      'accountClass',
      'accountStatus',
      'accountSegmentName',
      'vatNumber',
      'orderDay',
      'deliveryDay',
      'accountAddress1',
      'country',
      'city',
      'region',
      'postalCode',
      'branch',
      'province',
      'phoneNumber',
      'location',
      'productListCode',
      'priceListCode',
      'planogramCode',
      'accountCat1',
      'accountCat2',
      'accountCat3',
      'acountLocal1',
      'acountLocal2',
      'acountLocal3',
      'brandFocus',
      'subsegment',
    ]

    const disabledFields = ['contactStatus', 'origin', 'accountCode', 'accountStatus']

    const filteredItems = _items.filter(item => allowedFields.includes(item))

    return filteredItems.map((itemName, i) => {
      const item = schemeData.find(item => item.name === itemName)

      if (!item) return null

      let {
        name,
        label,
        type,
        settings: { multilang: itemMultilang } = {},
        visibleIf,
        subScheme,
        hidden,
        disabled,
      } = item

      if (hidden) return null

      if (type === 'media') {
        item[`${name}__multilang`] = rawData[`${name}__multilang`]
      }

      if (!_methods.checkFieldVisibility(visibleIf)) return null

      let itemNameMultilang = itemName

      if (itemMultilang || (type === 'media' && rawData[`${name}__multilang`]))
        itemNameMultilang += `__${selectedLanguage.id}`

      if (disabledFields.includes(name)) {
        item.disabled = true
      }

      let value = itemName in changed ? changed[itemName] : rawData[itemNameMultilang]

      if (multilang && itemMultilang) label = `${label || name} (${selectedLanguage.id})`

      const { columnSize = 6 } = item

      return (
        <Grid item xs={3} md={item.name === 'location' ? 6 : 3} key={i}>
          {Factory.getFieldByType({
            ...item,
            document: rawData,
            selectedLanguage,
            label,
            onChange: _methods.onChange,
            onRequestSave: _methods.onRequestSave,
            onUpdate: _methods.onChange,
            onTrigger: _methods.onTrigger,
            api,
            value,
            sectionType,
            controlled: true,
            _renderFields: __renderFields,
            _language: selectedLanguage.id,
          })}
        </Grid>
      )
    })
  }

  renderFields(items, renderProps = {}) {
    return this._renderFields(
      items,
      renderProps.state || this.state,
      this.props,
      renderProps.methods || this.methods,
      this.renderFields.bind(this),
    )
  }

  handleExpansion = event => {
    const { currentTarget: { id } = {} } = event
    const index = parseInt(id, 10)

    this.setState(prevSate => {
      const expanded = [...prevSate.expanded]
      expanded[index] = !expanded[index]
      return { expanded }
    })
  }

  methods = {
    checkFieldVisibility: this.checkFieldVisibility.bind(this),
    onChange: this.handleChange.bind(this),
    onRequestSave: this.handleRequestSave.bind(this),
    onTrigger: this.handleTrigger.bind(this),
  }

  render() {
    const {
      structure,
      classes,
      actions,
      history,
      versionHistory,
      rawData: { _id, state },
      className,
      isDraft,
      sectionName,
    } = this.props
    const { expanded, changed, id, pendingPatch } = this.state
    const actionsFiltered =
      actions &&
      actions
        .filter(({ id }) => !nonToolbarActions.includes(id))
        .filter(({ id }) => !id.includes('_'))

    const hasChanges = Object.keys(changed).length > 0

    const visibleSections = structure.filter(
      section => !section.hide && !section.hidden && this.checkFieldVisibility(section.visibleIf),
    )

    const shouldShowParentPanel = visibleSections.length > 1

    const renderSections = () => (
      <Grid container spacing={8} className={className} key={id}>
        {structure.map((section, i) => {
          const {
            name,
            label,
            hide,
            hidden,
            fields,
            columnSize = 12,
            visibleIf,
            noContentPadding,
          } = section

          const crmNameSection = name === 'CRM Information' ? 'Contact Information' : name

          if (section.name === 'Customer 360 - Integrations') return null
          if (hide || hidden) return null
          if (!this.checkFieldVisibility(visibleIf)) return null

          return (
            <Grid item xs={columnSize} key={i}>
              <ExpansionPanel expanded={expanded[i] === true} onChange={this.handleExpansion}>
                <ExpansionPanelSummary id={i} expandIcon={<Icon>expand_more</Icon>}>
                  <Typography
                    variant={structure.length === 1 ? 'h5' : 'h7'}
                    align="left"
                    className="!text-grey-400"
                  >
                    {crmNameSection}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={noContentPadding ? classes.noPadding : undefined}>
                  <Grid container spacing={24}>
                    {this.renderFields(fields)}
                  </Grid>
                </ExpansionPanelDetails>
                {structure.length === 1 && (
                  <div className={classes.singleButton}>
                    <div className={classes.actions}>
                      <ModelActionBar
                        modelId={_id}
                        history={history}
                        versionHistory={versionHistory}
                        actions={actionsFiltered}
                        disabled={{
                          patch: !hasChanges || pendingPatch,
                          saveFromDraft: Object.keys(changed).length === 0,
                        }}
                        onActionClick={this.handleActionClick}
                      />
                    </div>
                  </div>
                )}
              </ExpansionPanel>
            </Grid>
          )
        })}
      </Grid>
    )

    return (
      <React.Fragment>
        {!isDraft && (
          <Prompt
            when={hasChanges}
            message={location => {
              return location.pathname === window.location.pathname
                ? true
                : `You have unsaved changes. Are you sure you want to leave this page?`
            }}
          />
        )}

        {shouldShowParentPanel ? (
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
              <Typography variant="h5" align="left" className="!text-grey-400">
                {sectionName}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                {renderSections()}
                <div className={classes.actions}>
                  <ModelActionBar
                    modelId={_id}
                    history={history}
                    versionHistory={versionHistory}
                    actions={actionsFiltered}
                    disabled={{
                      patch: !hasChanges || pendingPatch,
                      saveFromDraft: Object.keys(changed).length === 0,
                    }}
                    onActionClick={this.handleActionClick}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          renderSections()
        )}
      </React.Fragment>
    )
  }
}
export default withLanguage(withStyles(styles)(ModelDetails))
