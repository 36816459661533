import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'

class TextInputField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      rating: {
        bad: false,
        medium: false,
        good: false,
      },
    }
  }

  changeSelection = button => {
    const { rating } = this.state

    for (let obj in rating) {
      rating[obj] = false
    }

    rating[button] = true

    this.setState({
      ...this.state,
      value: button,
    })
  }

  render() {
    const { classes, data, className, invalid, onBlur, onFocus, tipRender, fullWidth } = this.props

    const { id, readOnly, placeholder, required } = data

    const {
      rating: { bad, medium, good },
    } = this.state

    let errorText
    if (this.props.errorText) {
      errorText = this.props.errorText
    } else {
      errorText = data.errorText
    }

    return (
      <FormControl
        fullWidth={fullWidth}
        key={id}
        className={className}
        required={required}
        aria-describedby={`${id}-error-text`}
        disabled={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        error={invalid}
        style={{ marginBottom: 0 }}
      >
        <InputLabel htmlFor={id} required={false}>
          {placeholder}
        </InputLabel>
        <Input
          style={{ display: 'none' }}
          id={data.id}
          inputRef={ref => (this.inputRef = ref)}
          value={this.state.value}
          inputProps={
            {
              // type: 'hidden',
              // pattern: pattern
            }
          }
        />
        <div className={classes.ratingContainer}>
          <IconButton
            color="primary"
            className={classNames(classes.button, bad && classes.iconActiveBad)}
            size="large"
            onClick={() => {
              this.changeSelection('bad')
            }}
          >
            <Icon className={classes.icon}>sentiment_dissatisfied</Icon>
          </IconButton>
          <IconButton
            color="primary"
            className={classNames(classes.button, medium && classes.iconActiveMedium)}
            size="large"
            onClick={() => {
              this.changeSelection('medium')
            }}
          >
            <Icon className={classes.icon}>sentiment_satisfied</Icon>
          </IconButton>
          <IconButton
            color="primary"
            className={classNames(classes.button, good && classes.iconActiveGood)}
            size="large"
            onClick={() => {
              this.changeSelection('good')
            }}
          >
            <Icon className={classes.icon}>sentiment_very_satisfied</Icon>
          </IconButton>
        </div>
        <FormHelperText id={`${id}-error-text`}>
          {errorText && invalid ? errorText : ''}
        </FormHelperText>
        {tipRender && tipRender({ className: classes.tipBox })}
      </FormControl>
    )
  }

  blur = () => this.inputRef && this.inputRef.blur()

  _handleChange = e => {
    this.setState({ value: e.target.value })
  }

  _handleMouseDownPassword = event => {
    event.preventDefault()
  }

  checkValidity = () => {
    return this.inputRef && this.inputRef.checkValidity()
  }

  getValue = () => this.state.value
}

const styles = ({ palette, spacing: { unit } }) => ({
  passwordBullets: {
    letterSpacing: 3.8,
    // paddingBottom: unit
  },
  inputText: {
    // paddingBottom: unit
  },
  visibilityButton: {
    position: 'absolute',
    right: -unit,
  },
  tipBox: {
    position: 'absolute',
    top: '100%',
    right: 0,
    marginTop: -20,
    zIndex: 100,
  },
  button: {
    width: unit * 8,
    height: unit * 8,
    transition: 'all .2s',
    '&:hover': {
      transform: 'rotate(-20deg) scale(1.1)',
    },
  },
  ratingContainer: {
    display: 'flex',
    padding: `${unit * 5}px 0 0 0`,
  },
  icon: {
    fontSize: unit * 6,
  },
  iconActiveMedium: {
    color: palette.common.yellow,
  },
  iconActiveGood: {
    color: palette.common.green,
  },
  iconActiveBad: {
    color: palette.common.red,
  },
})

export default withStyles(styles)(TextInputField)
