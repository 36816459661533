import moment from 'moment'

const anchorDate = '2000-12-31T03:24:00'

const getLocaleFormat = () => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  }

  const numericFormat = new Date(anchorDate).toLocaleDateString(navigator.language, options)
  return numericFormat.replace('2000', 'YYYY').replace('12', 'MM').replace('31', 'DD')
}

const getMobileDateMask = () => {
  return moment(anchorDate).format(getLocaleFormat()).replace(/[0-9]/g, '#').split('')
}

const convertToStandardFormat = dateToConvert => {
  return moment(dateToConvert, getLocaleFormat(), true).format('YYYY-MM-DD')
}

export { getMobileDateMask, convertToStandardFormat, getLocaleFormat }
