import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Grid from '@material-ui/core/Grid'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { useState } from 'react'
import KuberenetesNamespace from '~components/page/KubernetesPage/KuberenetesNamespace'
import withStyles from '@material-ui/core/styles/withStyles'
import { toUpper } from 'lodash'

const marketToRegion = {
  we: 'West Europe',
  ea: 'East Asia',
  eu: 'East US',
}
const KubeMarket = props => {
  const { expanded, onChange, name, namespaces = [], classes, panelRefs } = props

  const [selectedTab, setSelectedTab] = useState(namespaces[0]?.namespace)

  const handleTabChange = value => {
    setSelectedTab(value)
  }

  const region = namespaces[0]?.region?.split('-')[0]

  const namespace = namespaces.find(item => item.namespace === selectedTab)

  return (
    // <RootRef rootRef={isSelected ? ref : null}>
    <ExpansionPanel expanded={expanded} id={name} onChange={(e, expanded) => onChange(name)}>
      <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
        {/*<div ref={el => (panelRefs.current[name] = el)}></div>*/}
        <div className={'flex w-full items-center justify-between'}>
          <Typography variant="h5" align="left" className="!text-grey-400">
            {renderStatusIndicator(props)}
          </Typography>
          {region && (
            <Typography variant="h6" align="left" className="!text-grey-400">
              {marketToRegion[region]}
            </Typography>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Tabs value={selectedTab} className={'w-full'}>
            {namespaces?.map((item, index) => {
              const { deployments, namespace } = item
              const unhealthy = deployments.some(x => x.availableReplicas < x.desiredReplicas)
              return (
                <Tab
                  classes={classes}
                  value={namespace}
                  label={(unhealthy ? '⚠️ ' : '') + toUpper(namespace.split('-')[2])}
                  onClick={() => handleTabChange(namespace)}
                />
              )
            })}
          </Tabs>
          {namespace && <KuberenetesNamespace {...namespace} />}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    // </RootRef>
  )
}

const renderStatusIndicator = props => {
  const { name, namespaces, healthy } = props
  // const unhealthy = namespaces.some(y =>
  //   y.deployments.some(x => x.availableReplicas < x.desiredReplicas),
  // )
  return (
    <Grid container className={'flex items-center gap-x-5 gap-y-1'}>
      <Typography variant={'h4'}>{name}</Typography>
      <Grid className={'flex flex-grow items-center justify-between gap-x-5 gap-y-1'}>
        {namespaces.map(namespace => {
          const { deployments, namespace: name, up, down, total } = namespace
          const env = toUpper(name.split('-')[2])
          return (
            <Grid
              item
              className={
                'flex gap-x-4 rounded-[5px] border-[1px] border-[gray] border-[solid] p-[3px]'
              }
            >
              <div>{env}</div>
              <div className={'flex'}>
                <div>{up}</div>
                <Icon color={'primary'}>{'arrow_circle_up'}</Icon>
              </div>
              {down > 0 && (
                <div className={'flex'}>
                  <div>{down}</div>
                  <Icon color={'error'}>{'arrow_circle_down'}</Icon>
                </div>
              )}

              {/*<div className={'flex'}>*/}
              {/*  <div>{total}</div>*/}
              {/*  <Icon color={'primary'}>{'brightness_1'}</Icon>*/}
              {/*</div>*/}
              {/*{deployments.map(deployment => {*/}
              {/*  const { name, availableReplicas, desiredReplicas, healthy } = deployment*/}
              {/*  // const unhealthy = availableReplicas < desiredReplicas*/}

              {/*  return (*/}
              {/*    <Tooltip title={name}>*/}
              {/*      <Icon color={!healthy ? 'error' : 'primary'}>*/}
              {/*        {!healthy ? 'arrow_circle_down' : 'arrow_circle_up'}*/}
              {/*      </Icon>*/}
              {/*    </Tooltip>*/}
              {/*  )*/}
              {/*})}*/}
            </Grid>
          )
        })}
      </Grid>
      {!healthy ? ' ⚠️' : ''}
    </Grid>
  )
}

const styles = ({ palette, spacing: { unit } }) => ({
  root: {
    // 'font-weight': 'bold',
    // 'text-transform': 'capitalize',
    fontSize: '13px',
  },
  label: {},
})

export default withStyles(styles)(KubeMarket)
