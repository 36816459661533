import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import amber from '@material-ui/core/colors/amber'

import PointsBox from 'shared-ui/components/atoms/PointsBox'
import { htmlTextAnchorParser } from 'shared-ui/utils'
import Button from '@material-ui/core/Button'
import DropDown from '../DropDown'

const styles = ({ breakpoints, spacing: { unit }, palette }) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 80,
  },
  circSuccess: {
    color: palette.primary.main,
  },
  circError: {
    color: palette.error.main,
  },
  circWarning: {
    color: amber[700],
  },
  title: {
    marginTop: unit * 2,
  },
  message: {
    marginTop: unit * 1.5,
    maxWidth: unit * 60,
  },
  points: {
    marginTop: unit * 2,
  },
  actionButton: {
    marginTop: unit * 2,
  },
  dropDown: {
    marginTop: unit * 2,
    width: unit * 30,
  },
})

const iconByVariant = {
  success: 'check_circle',
  error: 'highlight_off',
}
const iconByName = {
  'msg-signup-requires-validation': 'hourglass_empty',
}

class GenericMessageContent extends React.Component {
  state = {
    isSubmitting: false,
  }
  onDropDownChange = e => {
    console.log(e)
    this.setState({ actionOption: e })
  }
  handleAction = async () => {
    const { api, name } = this.props
    const { actionOption } = this.state
    this.setState({ isSubmitting: true })
    const { request } = api.genericMessageAction({ action: name, actionOption })
    try {
      await request
    } catch (err) {
      console.log(err)
    }
    this.setState({ isSubmitting: false })
  }

  componentDidMount() {
    const { actionOptions } = this.props
    actionOptions && this.setState({ actionOption: actionOptions[0].value })
  }

  render() {
    const {
      classes,
      className,
      style,
      variant,
      name,
      title,
      message,
      points,
      action,
      actionOptions,
    } = this.props
    const { isSubmitting } = this.state

    return (
      <div className={classNames(classes.root, className)} style={style}>
        <div
          className={classNames({
            [classes.circSuccess]: variant === 'success',
            [classes.circError]: variant === 'error',
            [classes.circWarning]: variant === 'warning',
          })}
        >
          <Icon className={classes.icon}>
            {iconByName[name] || iconByVariant[variant] || 'priority_high'}
          </Icon>
        </div>

        {title && <Typography variant="h3">{title}</Typography>}
        {message && (
          <Typography className={classes.message} variant="subtitle1">
            {htmlTextAnchorParser(message)}
          </Typography>
        )}
        {points && <PointsBox points={points} className={classes.points} />}
        {actionOptions && (
          <DropDown
            value={'yolo'}
            className={classes.dropDown}
            label={'Reason'}
            onChange={this.onDropDownChange}
            // invalid={invalid}
            enumData={actionOptions}
          ></DropDown>
        )}
        {action && (
          <Button
            className={classes.actionButton}
            disabled={isSubmitting}
            variant={'outlined'}
            onClick={this.handleAction}
          >
            {action}
          </Button>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(GenericMessageContent)
