import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

const styles = ({ palette, breakpoints, spacing: { unit } }) => {
  const bRad = 'var(--radius-outer)'
  return {
    container: {
      overflow: 'hidden',
      backgroundColor: palette.accent400.main,
      fontSize: '11px',
      color: palette.paper,
      borderRadius: bRad,
      padding: `0 ${unit}px`,
      height: unit * 3 - 4,
      minHeight: unit * 3 - 4,
      [breakpoints.down('xs')]: {
        fontSize: 11,
        height: '24px',
        minHeight: '24px',
        padding: `0 8px`,
      },
    },
    compact: {
      height: 'unset',
      minHeight: 0,
      padding: '0.25rem 0.5rem',
    },
    pale: {
      backgroundColor: palette.primary.main,
    },
    bright: {
      backgroundColor: palette.common.green,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    hot: {
      backgroundColor: palette.common.red + '!important',
      color: palette.common.persistentWhite,
    },
    alert: {
      backgroundColor: palette.common.orange,
      color: palette.common.persistentBlack,
    },
    dull: {
      backgroundColor: palette.grey400,
    },
    dark: {
      color: palette.paper,
      backgroundColor: palette.grey500,
    },
    transparent: {
      color: palette.accent400.main,
      backgroundColor: 'transparent',
    },
    square: {
      borderRadius: 0,
    },
    plus: {
      verticalAlign: 'middle',
      fontSize: 20,
      marginLeft: -5,
      [breakpoints.down('xs')]: {
        fontSize: 11,
      },
    },
    plusCompact: {
      fontSize: 12,
    },
    tl: { borderRadius: `0 0 ${bRad} 0` },
    t: { borderRadius: `0 0 ${bRad} ${bRad}` },
    tr: {},
    r: { borderRadius: `${bRad}` },
    br: { borderRadius: `${bRad} 0 0 0` },
    b: { borderRadius: `${bRad} ${bRad} 0 0` },
    bl: { borderRadius: `0 ${bRad} 0 0` },
    l: { borderRadius: `0 ${bRad} ${bRad} 0` },
    c: { borderRadius: bRad },
  }
}

class Pill extends React.Component {
  render() {
    const {
      className,
      style,
      classes,
      label,
      hot,
      alert,
      dull,
      plus,
      bright,
      pale,
      dark,
      transparent,
      placement,
      square,
      compact,
    } = this.props

    const Component = label ? props => <Typography {...props} /> : 'div'
    const children = label || this.props.children

    if (!children) return null

    return (
      <Component
        style={style}
        className={classNames(
          classes.container,
          placement && classes[placement],
          {
            [classes.pale]: pale,
            [classes.hot]: hot,
            [classes.alert]: alert,
            [classes.dull]: dull,
            [classes.square]: square,
            [classes.label]: label,
            [classes.bright]: bright,
            [classes.dark]: dark,
            [classes.transparent]: transparent,
            [classes.compact]: compact,
          },
          className,
        )}
      >
        {plus && (
          <Icon className={classNames(classes.plus, { [classes.plusCompact]: compact })}>add</Icon>
        )}
        {children}
      </Component>
    )
  }
}

Pill.propTypes = {
  pale: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  hot: PropTypes.bool,
  alert: PropTypes.bool,
  plus: PropTypes.bool,
  square: PropTypes.bool,
  placement: PropTypes.oneOf(['tl', 't', 'tr', 'r', 'br', 'b', 'bl', 'l', 'c']),
}

export default withStyles(styles)(Pill)
