import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import Link from 'shared-ui/components/Link'

function AdminVersionControlPage(props) {
  const [expanded, setExpanded] = useState('@next')

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const {
    classes,
    version: { history },
    modelHistory,
  } = props

  return (
    <React.Fragment>
      <div className={classes.versionList}>
        {history.map(version => {
          const { releaseVersion, features } = version
          const bareVersion = releaseVersion.startsWith('@')
            ? releaseVersion
            : releaseVersion.slice(1)
          return (
            <ExpansionPanel
              expanded={expanded === releaseVersion}
              onChange={handleChange(releaseVersion)}
            >
              <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                <span>
                  <Chip label={releaseVersion} color="secondary" />
                  {modelHistory.includes(bareVersion) && (
                    <Button
                      classes={{ root: classes.modelDiffButton }}
                      component={Link}
                      to={'/version/diff/' + bareVersion}
                    >
                      model changes
                    </Button>
                  )}
                </span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.versionTable}>
                <Table className={classes.versionTable}>
                  <TableBody>
                    {features.map(({ hash, title, link }) => (
                      <TableRow>
                        <TableCell>
                          <a href={link} target="_blank" rel="noopener noreferrer">
                            <Button size="small" className={classes.button}>
                              TICKET
                            </Button>
                          </a>
                        </TableCell>
                        <TableCell>{hash}</TableCell>
                        <TableCell>{title}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
    </React.Fragment>
  )
}

const styles = ({ spacing: { unit } }) => ({
  heading: {
    fontSize: '1.5rem',
  },
  versionList: {
    // margin: '3rem'
  },
  versionTable: {
    padding: 0,
  },
  dangerZone: {
    marginBottom: unit * 2,
    padding: unit * 3,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  logWindow: {
    backgroundColor: '#efefef',
    borderRadius: unit,
  },
  log: {
    fontFamily: 'monospace',
    fontSize: '12px',
  },
  modelDiffButton: {
    marginLeft: unit,
    paddingRight: `12px !important`,
    border: '1px solid #efefef',
  },
})

export default withStyles(styles)(AdminVersionControlPage)
