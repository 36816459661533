import React, { useRef, useState } from 'react'
import './KubernetesPage.css'
import Grid from '@material-ui/core/Grid'
import KubeRegionStats from '~components/page/KubernetesPage/KubeRegionStats'
import KubeTotalStats from '~components/page/KubernetesPage/KubeTotalStats'
import { withApi } from 'shared-ui/api/ApiContext'
import KubeMarkets from '~components/page/KubernetesPage/KubeMarkets'
import KubernetesDrawer from '~components/page/KubernetesPage/KubernetesDrawer'

const KubernetesPage = props => {
  const { markets, regions, stats, api, contexts } = props

  const [selectedNamespace, setSelectedNamespace] = useState('All')
  const [selectedEnv, setSelectedEnv] = useState('All')
  const [expanded, setExpanded] = useState({})

  const panelRefs = useRef([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerData, setDrawerData] = useState([])
  // Extract unique namespaces and environments
  // const [namespaces, setNamespaces] = useState(['All', ...new Set(data.map(d => d.namespace))])
  // const namespaces1 = ["All", ...new Set(data.map((d) => d.namespace))];
  const environments = ['All', 'qa', 'dev']

  // Group and filter deployments
  // const filteredData = data
  //   ?.map(({ namespace, deployments }) => {
  //     const env = namespace.split('-').pop() // Extract env (e.g., 'qa' or 'dev')
  //
  //     if (
  //       (selectedNamespace === 'All' || namespace === selectedNamespace) &&
  //       (selectedEnv === 'All' || env === selectedEnv)
  //     ) {
  //       // Push the namespace and deployment into the array
  //       return {
  //         namespace,
  //         deployments,
  //       }
  //     }
  //     return null
  //   })
  //   .filter(Boolean)

  // const handleEnvChange = e => {
  //   const env = e.target.value
  //   setSelectedEnv(env)
  //   if (env === 'All') {
  //     setNamespaces(['All', ...new Set(data.map(d => d.namespace))])
  //   } else
  //     setNamespaces([
  //       'All',
  //       ...new Set(data.filter(d => d.namespace.split('-').pop() === env).map(d => d.namespace)),
  //     ])
  // }
  let unhealthyCount = 0
  let totalCount = 0
  //
  // data.map(({ markets }) => {
  //   markets.map(market => {
  //     const { namespaces } = market
  //     namespaces.map(ns => {
  //       const { deployments } = ns
  //       deployments.map(x => {
  //         const unhealthy = x.availableReplicas < x.desiredReplicas
  //         if (unhealthy) unhealthyCount++
  //         totalCount++
  //       })
  //       // console.log('unhealthy', unhealthy)
  //     })
  //   })
  // })

  const handleExpansion = index => {
    setExpanded(prevSate => {
      const expanded = { ...prevSate }
      expanded[index] = !expanded[index]
      return expanded
    })
  }

  // const handleTabChange = (event, value) => {
  //   console.log('newValue', value)
  //   setSelectedTab(value)
  // }

  // const handleTabClick = tabName => {
  //   // console.log('handleTabClick', event)
  //   setSelectedTab(prev => {
  //     const newSelectedTab = { ...prev }
  //     newSelectedTab[tabName] = !newSelectedTab[tabName]
  //     return newSelectedTab
  //   })
  // }

  const [overallStats, setOverallStats] = useState({})

  // const { up, down, coverage, total } = stats || {}

  const handleDrawerOpen = data => {
    setDrawerData(data)
    setDrawerOpen(true)
  }

  const scrollAndOpenCollapsible = namespace => {
    const market = namespace?.split('-')[1]
    setExpanded({ [market]: true })
    panelRefs.current[market]?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // setIsSelectedMarket(market)
  }

  const closeDrawerAndScrollToMarket = namespace => {
    setDrawerOpen(false)
    if (typeof namespace === 'string') {
      const market = namespace?.split('-')[1]
      setExpanded(prev => {
        return { [market]: true }
      })
    }
    // panelRefs.current[namespace]?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    // })
  }

  return (
    <div className="app flex flex-col gap-y-10">
      <KubeTotalStats {...overallStats} />
      <Grid className={'flex w-full flex-wrap'}>
        {contexts.map((context, index) => {
          return (
            <Grid className={'flex w-1/3 p-4'}>
              <KubeRegionStats
                className={'w-full'}
                data={context}
                api={api}
                onChange={setOverallStats}
                onMoreClick={handleDrawerOpen}
              />
            </Grid>
          )
        })}
      </Grid>
      <KubernetesDrawer
        open={drawerOpen}
        onClose={closeDrawerAndScrollToMarket}
        // onItemClick={scrollAndOpenCollapsible}
        data={drawerData}
      />

      {/*<h1>Deployments Dashboard</h1>*/}

      {/* Filters */}
      {/*<div className="filters">*/}
      {/*  <label>*/}
      {/*    Environment:*/}
      {/*    <select value={selectedEnv} onChange={e => handleEnvChange(e)}>*/}
      {/*      {environments.map(env => (*/}
      {/*        <option key={env} value={env}>*/}
      {/*          {env.toUpperCase()}*/}
      {/*        </option>*/}
      {/*      ))}*/}
      {/*    </select>*/}
      {/*  </label>*/}

      {/*  <label>*/}
      {/*    Namespace:*/}
      {/*    <select value={selectedNamespace} onChange={e => setSelectedNamespace(e.target.value)}>*/}
      {/*      {namespaces.map(ns => (*/}
      {/*        <option key={ns} value={ns}>*/}
      {/*          {ns}*/}
      {/*        </option>*/}
      {/*      ))}*/}
      {/*    </select>*/}
      {/*  </label>*/}
      {/*</div>*/}

      {/*// by market*/}

      <KubeMarkets expanded={expanded} handleExpansion={handleExpansion} panelRefs={panelRefs} />
      {/*// good code*/}
      {/*<Grid>*/}
      {/*  {markets?.map((market, index) => {*/}
      {/*    // const { markets = [], region: name } = region*/}
      {/*    return (*/}
      {/*      <KubeMarket*/}
      {/*        index={index}*/}
      {/*        expanded={expanded[index]}*/}
      {/*        onChange={() => handleExpansion(index)}*/}
      {/*        {...market}*/}
      {/*      />*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</Grid>*/}

      {/* Grouped Deployments Table */}
      {/*<div className="deployments-container">*/}
      {/*  {filteredData.length ? (*/}
      {/*    filteredData?.map(({ namespace, deployments }) => (*/}
      {/*      <div key={namespace} className="namespace-group">*/}
      {/*        <h2 className="namespace-header">{namespace}</h2>*/}
      {/*        <table className="deployments-table">*/}
      {/*          <thead>*/}
      {/*            <tr>*/}
      {/*              <th>Name</th>*/}
      {/*              <th>Available Replicas</th>*/}
      {/*              <th>Desired Replicas</th>*/}
      {/*              <th>Status</th>*/}
      {/*            </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody>*/}
      {/*            {deployments.map((deployment, index) => {*/}
      {/*              const unhealthy = deployment.availableReplicas < deployment.desiredReplicas*/}
      {/*              return (*/}
      {/*                <tr key={index} className={unhealthy ? 'unhealthy' : 'healthy'}>*/}
      {/*                  <td>{deployment.name}</td>*/}
      {/*                  <td>{deployment.availableReplicas}</td>*/}
      {/*                  <td>{deployment.desiredReplicas}</td>*/}
      {/*                  <td>{unhealthy ? 'Unhealthy' : 'Healthy'}</td>*/}
      {/*                </tr>*/}
      {/*              )*/}
      {/*            })}*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    ))*/}
      {/*  ) : (*/}
      {/*    <p>No deployments match the selected filters.</p>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  )
}

export default withApi(KubernetesPage)
