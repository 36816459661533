import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import SelectMenuItem, { MenuProps } from '~components/atom/SelectMenuItem'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ModelActionBar from '~components/molecules/ModelActionBar/ModelActionBar'

const toolbarStyles = ({ spacing: { unit } }) => ({
  root: {
    padding: unit,
  },
  spacer: {
    flex: '1',
  },
  filters: {
    alignItems: 'flex-end',
    '&>*': {
      margin: '2px',
    },
  },
  searchForm: {
    display: 'flex',
    alignItems: 'flex-end',
    '&>*': {
      margin: '2px',
    },
  },
  searchButton: {
    padding: '2.5px',
    borderRadius: '5px',
  },
})

class EnhancedTableToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchInput: props.searchText || '' }
  }

  handleColumnChange = event => {
    const { onColumnsChange } = this.props
    onColumnsChange && onColumnsChange(event.target.value)
  }

  onSearchInputChange = event => {
    const { isSearchLimited } = this.props
    let input = event.target.value

    this.setState({ searchInput: input })

    if (!isSearchLimited) this.handleSearchRequest(input)
  }

  onSearchSubmit = e => {
    e.preventDefault()
    this.handleSearchRequest(this.state.searchInput, true)
  }

  handleSearchRequest = (input, immediate) => {
    const { searchText, onSearchChange } = this.props

    const requestedSearchText = input.length < 3 ? '' : input
    if (requestedSearchText !== searchText) {
      onSearchChange && onSearchChange(requestedSearchText, immediate)
    }
  }

  handleActionClick = action => {
    const { onActionRequested } = this.props

    if (onActionRequested) return onActionRequested(action)
  }

  handleFiltersSelect = e => {
    const {
      target: { value },
    } = e
    const { onSelectFiltersRequested } = this.props

    if (onSelectFiltersRequested) onSelectFiltersRequested(value)
  }

  render() {
    const {
      classes,
      columnData,
      actions,
      disabledActions,
      savedFilters,
      encodedSearchFilters,
      numSelectedRows = 0,
      selectedColumns = [],
      searchText = '',
      isSearchLimited,
    } = this.props

    const { searchInput } = this.state
    const validSearchInput = searchInput.length < 3 ? '' : searchInput

    let selectedFilters =
      savedFilters && savedFilters.find(({ filterString }) => filterString === encodedSearchFilters)

    return (
      <Grid container className={classes.root}>
        <Grid item xs={10} sm={6} className={classes.filters}>
          <TextField
            component={'form'}
            onSubmit={e => e.preventDefault()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: isSearchLimited ? (
                <IconButton
                  color="secondary"
                  className={classes.searchButton}
                  variant={'outlined'}
                  type={'submit'}
                  disabled={searchText === validSearchInput}
                  onClick={this.onSearchSubmit}
                >
                  <Icon fontSize={'small'}>keyboard_return</Icon>
                </IconButton>
              ) : undefined,
            }}
            placeholder="Search"
            helperText={isSearchLimited ? <span>⚠️️ Only exact matches supported!</span> : null}
            onChange={this.onSearchInputChange}
            value={searchInput}
          />

          <Select
            multiple
            displayEmpty
            value={selectedColumns}
            onChange={this.handleColumnChange}
            input={<Input />}
            renderValue={() => 'Columns'}
            MenuProps={MenuProps}
          >
            {columnData.map(col => {
              const { name: colName, label: colLabel, hideFromColumns } = col
              return hideFromColumns ? null : (
                <SelectMenuItem
                  key={colName}
                  value={colName}
                  label={colLabel || colName}
                  checked={selectedColumns.includes(colName)}
                />
              )
            })}
          </Select>

          {savedFilters && (
            <Select
              displayEmpty
              value={selectedFilters && selectedFilters.filterString}
              onChange={this.handleFiltersSelect}
              renderValue={() => (selectedFilters ? selectedFilters.title : 'Saved Filters')}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {savedFilters.map(filter => {
                const { title, filterString } = filter
                return <MenuItem value={filterString}>{title}</MenuItem>
              })}
            </Select>
          )}
        </Grid>

        <Grid item xs={2} sm={6}>
          <ModelActionBar
            actions={actions}
            numSelectedRows={numSelectedRows}
            onActionClick={this.handleActionClick}
            disabled={disabledActions}
          />
        </Grid>
      </Grid>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
