import { Quill } from 'react-quill'

let InlineEmbed = Quill.import('blots/embed')
class DocBlot extends InlineEmbed {
  static create(value) {
    let node = super.create()

    if (typeof value !== 'object') return node

    // const p = document.createElement('div')
    // p.appendChild(node)

    node.innerText = value.text || value.alt
    node.setAttribute('alt', value.text || value.alt)
    node.setAttribute('target', value.target || '_blank')
    node.setAttribute('href', value.src)
    node.setAttribute('data_object_id', value.data_object_id)

    if (value.sitemapid) {
      node.setAttribute('sitemapid', value.sitemapid)
    }

    node.setAttribute(
      'style',
      'color: #ffffff; background-color: #00b2a9; border: 1px solid #ffffff; margin: 2px; padding: 8px 24px; width: fit-content; font-size: 11px; letter-spacing: 1px; text-transform: uppercase; font-weight: bolder',
    )
    node.onclick = function () {
      window.open(value.src)
    }
    return node
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('href'),
      target: node.getAttribute('target'),
      data_object_id: node.getAttribute('data_object_id'),
      sitemapid: node.getAttribute('sitemapid'),
    }
  }
}
DocBlot.blotName = 'doc'
DocBlot.tagName = 'button'
DocBlot.className = 'ql-docContent'

// "\f02a"

export default DocBlot
