import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Chip from '@material-ui/core/Chip'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import { withApi } from 'shared-ui/api/ApiContext'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  avatarChildren: {
    width: 'auto',
    height: 'auto',
  },
  avatar: {
    backgroundColor: `transparent`,
  },
  content: {
    minWidth: 250,
    padding: unit,
  },
  button: {
    color: palette.error.main,
    '&:hover': {
      backgroundColor: fade(palette.error.main, 0.1),
    },
  },
})

class HeaderUserItem extends React.Component {
  state = {}

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleLogout = async () => {
    try {
      const { api } = this.props
      const { request } = api.logout()
      await request
    } catch (e) {
      console.error('Error while logging out', e)
    }
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const {
      classes,
      member: { userRole, userName },
      className,
    } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <React.Fragment>
        <Chip
          className={className}
          classes={{ avatarChildren: classes.avatarChildren }}
          avatar={
            <Avatar className={classes.avatar}>
              <Icon>face</Icon>
            </Avatar>
          }
          label={userName}
          onClick={this.handleClick}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography className={classes.content}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>User Name:</td>
                  <td>{userName}</td>
                </tr>
                <tr>
                  <td>Role:</td>
                  <td>{userRole}</td>
                </tr>
              </tbody>
            </table>
          </Typography>
          <Divider />
          <Button onClick={this.handleLogout} classes={{ root: classes.button }} fullWidth>
            Logout
          </Button>
        </Popover>
      </React.Fragment>
    )
  }
}

export default withApi(withStyles(styles)(HeaderUserItem))
