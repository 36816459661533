import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import FormElement from 'shared-ui/components/molecules/FormElement'

const styles = ({ spacing: { unit } }) => ({})

const FormElementsGroup = withStyles(styles)(
  class FormElementsGroup extends Component {
    updateElementsDisabledState = () => {
      const { elements = [], values = {} } = this.props

      const nElements = !Array.isArray(elements) ? [] : elements

      return nElements.reduce((a, { id, disabledIf, disabled: elemDisabled, readOnly }) => {
        if (elemDisabled !== undefined || readOnly === true) {
          a[id] = true
          return a
        }

        let disabled = false

        if (disabledIf) {
          let invert = false
          if (disabledIf.startsWith('!')) {
            invert = true
            disabledIf = disabledIf.slice(1)
          }

          let viRefElem = this._elementRefs[disabledIf]

          let value = viRefElem ? !!viRefElem.getValue() : !!values[id]
          if (invert) value = !value

          disabled = value

          a[id] = disabled
        }

        return a
      }, {})
    }

    _elementRefs = {}

    state = { invalid: {}, disabledElems: this.updateElementsDisabledState() }

    static getDerivedStateFromProps({ externalErrors }, { externalErrors: currentExternalErrors }) {
      if (externalErrors === undefined) return null

      if (externalErrors === currentExternalErrors) return { useExternalErrors: undefined }

      return { externalErrors, useExternalErrors: true }
    }

    setExternalErrors = externalErrors => {
      this.setState({ externalErrors, useExternalErrors: true })
    }

    getValues = () => {
      const result = {}
      this._forEachRefElement((refElement, id) => {
        let value = refElement ? refElement.getValue() : undefined
        if (value !== undefined) {
          result[id] = value
        }
      })

      return result
    }

    checkValidity() {
      let isValid = true

      this._forEachRefElement(refElement => {
        if (refElement && !refElement.hidden && !refElement.checkValidity()) {
          isValid = false
          // return true // breaks the loop
        }
      })

      return isValid
    }

    _forEachRefElement = method => {
      const { elements } = this.props
      for (const element of elements) {
        if (typeof element === 'string' || element.hidden === true) {
          continue
        }
        if (method(this._elementRefs[element.id], element.id)) {
          return
        }
      }
    }

    _handleFieldFocus = (...params) => {
      this.setState({ useExternalErrors: undefined })

      const { onFieldFocus } = this.props
      onFieldFocus && onFieldFocus(...params)
    }

    onCheckboxChange = () => {
      this.setState({ disabledElems: this.updateElementsDisabledState() })
    }

    onChange = (element, event) => {
      const { onChangeField } = this.props

      if (element.type.toLowerCase() === 'checkbox') {
        this.onCheckboxChange()
      } else {
        onChangeField && onChangeField(element.id, event)
      }
    }

    render() {
      const { elements = [], values = {}, size = '' } = this.props
      const { useExternalErrors } = this.state
      let externalErrors = {}

      const nElements = !Array.isArray(elements) ? [] : elements

      if (useExternalErrors) {
        externalErrors = this.state.externalErrors
      }

      return nElements.map((element, index) => {
        // TODO remove after the backend return the correct data
        if (element.type === 'boolean' && typeof element.value === 'string') {
          element.value = element.value === 'Yes'
        }

        if (element.hidden === true) return null

        let disabled = this.state.disabledElems[element.id]

        return (
          <FormElement
            size={size}
            key={element.id || index}
            externalError={element.id && externalErrors[element.id]}
            innerRef={ref => (this._elementRefs[element.id] = ref)}
            value={values[element.id]}
            data={element}
            onFieldFocus={this._handleFieldFocus}
            onChange={e => this.onChange(element, e)}
            disabled={disabled}
          />
        )
      })
    }
  },
)

FormElementsGroup.propTypes = {
  elements: PropTypes.array.isRequired,
}

export default FormElementsGroup
