import React, { Component } from 'react'
import classNames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'
import { htmlTextAnchorParser } from 'shared-ui/utils'
import FormHelperText from '@material-ui/core/FormHelperText'

class CustomCheckBox extends Component {
  state = {}

  constructor(props) {
    super(props)

    let { text } = props

    if (!text || typeof text !== 'string') {
      this.state = { text }
      return
    }

    const textChildren = htmlTextAnchorParser(text)

    this.state = { text: <React.Fragment>{textChildren}</React.Fragment> }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.checked !== undefined) {
      return {}
    }
    return { checked: props.value }
  }

  handleChange = e => {
    const { onChange } = this.props
    const {
      target: { checked },
    } = e

    this.setState({ checked }, () => {
      onChange && onChange(e)
    })
  }

  render() {
    const { id, classes, invalid, className, required, hideStar, disabled } = this.props
    const { checked, text } = this.state

    let errorText
    if (this.props.errorText) {
      errorText = this.props.errorText
    }

    // Append asterisk to text if required
    const labelText = required && !hideStar ? (
      <span>
        {text} <span className={classes.asterisk}>*</span>
      </span>
    ) : (
      text
    )

    return (
      <React.Fragment>
        <FormControlLabel
          className={classNames(
            classes.checkBoxControl,
            className,
            hideStar ? classes.hideStar : null,
          )}
          control={
            <Checkbox
              id={id}
              checked={checked}
              onChange={this.handleChange}
              color={'secondary'}
              disabled={disabled}
              className={`${classes.checkBox} ${invalid ? classes.error : ''}`}
            />
          }
          label={labelText}
        />
        <FormHelperText
          id={`${id}-error-text`}
          className={classNames(classes.error, classes.errorText)}
        >
          {errorText && invalid ? errorText : ''}
        </FormHelperText>
      </React.Fragment>
    )
  }

  checkValidity = () => !this.props.required || this.state.checked

  getValue = () => this.state.checked
}

const styles = ({ palette, spacing: { unit } }) => ({
  checkBoxControl: {
    alignItems: 'inherit',
    margin: 0,
    paddingRight: unit * 3,
    boxSizing: 'border-box',
    '& span:last-child': {
      marginBottom: unit,
    },
  },
  error: {
    color: palette.error.main,
  },
  errorText: {
    marginTop: 0,
    position: 'relative',
    top: -(unit + 6),
  },
  checkBox: {
    marginTop: -unit,
    marginLeft: -unit - 3,
    marginRight: unit * 2,
  },
})

export default withStyles(styles)(CustomCheckBox)
