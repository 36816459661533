import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import TagSelection from './TagSelection'
import CustomSwitchField from '~components/atom/FieldFactory/CustomSwitchField'

class PreviewMedia extends React.Component {
  handleDelete(key) {
    this.props.onHandleDeleteChip(key)
  }

  isImage = type => /image/.test(type)
  isPdf = type => /pdf/.test(type)
  isVideo = type => /video/.test(type)

  render() {
    const {
      classes,
      currentFile,
      onHandleAddChip,
      onClickHandle,
      useThisMedia,
      parsedTags = [],
      onHandleCreate,
      onAutoplayChange,
      isList,
    } = this.props

    const contentType = () => {
      switch (true) {
        case this.isImage(currentFile.type):
          return (
            <img style={{ maxWidth: '100%', maxHeight: '630px' }} src={currentFile.path} alt="" />
          )
        case this.isPdf(currentFile.type):
          return (
            <a
              href={currentFile.path}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.mediaAnchor}
            >
              <Icon className={classes.previewIcon}>picture_as_pdf</Icon>
            </a>
          )
        case this.isVideo(currentFile.type):
          return (
            <video width="100%" controls>
              <source src={currentFile.path + '?opt'} type={currentFile.type} />
              Your browser does not support HTML5 video.
            </video>
          )
        default:
          return 'format unrecoganized'
      }
    }

    const defaultValue = currentFile.tags.map(tag => {
      return { value: tag, label: tag }
    })

    return (
      <React.Fragment>
        <Grid container className={classes.parentWrapper}>
          <Button
            variant="fab"
            aria-label="Back"
            className={classes.backAction}
            onClick={onClickHandle}
          >
            <Icon>arrow_back</Icon>
          </Button>
          <Grid item xs={8} className={classNames(classes.mediaPreview, classes.card)}>
            {contentType()}
          </Grid>
          <Grid item xs={4} className={classes.mediaDescription}>
            <Typography variant="h6" gutterBottom>
              {currentFile.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {currentFile.type}
            </Typography>
            <Typography>
              <TagSelection
                parsedTags={parsedTags}
                defaultValue={defaultValue}
                isFullWidth
                onHandleCreate={onHandleCreate}
                onHandleAddChip={onHandleAddChip}
              />
            </Typography>

            {this.isVideo(currentFile.type) && (
              <CustomSwitchField
                name="autoplay"
                value={currentFile.autoplay || false}
                label="autoplay"
                onChange={onAutoplayChange}
              />
            )}

            <div className={classes.actionsCurrentFile}>
              {!isList && (
                <Button
                  variant="contained"
                  className={classes.actionsIconsCurrentFile}
                  onClick={useThisMedia.bind(this, currentFile)}
                  classes={{
                    root: classes.rootActionsCurrentFile,
                  }}
                >
                  Use it
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default PreviewMedia
