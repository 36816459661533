import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useEffect } from 'react'

const KubeTotalStats = stats => {
  let up = 0
  let down = 0
  let total = 0
  Object.values(stats).map(({ up: u, down: d, total: t }) => {
    up += u
    down += d
    total += t
  })

  const coverage = ((up / total) * 100).toFixed(2)

  return (
    <div>
      <LinearProgress variant="determinate" value={coverage} />
      <Paper className={'p-8'}>
        <div className={'flex w-full justify-around gap-y-2'}>
          <div className={'flex gap-x-2'}>
            <Icon color={'error'}>{'arrow_circle_down'}</Icon>
            <Typography variant={'h5'}>{down} down</Typography>
          </div>
          <div className={'flex gap-x-2'}>
            <Icon color={'primary'}>{'arrow_circle_up'}</Icon>
            <Typography variant={'h5'}>{up} UP</Typography>
          </div>
          <div className={'flex gap-x-2'}>
            <Icon color={''}>{'brightness_1'}</Icon>
            <Typography variant={'h5'}>Total {total}</Typography>
          </div>
          <div className={'flex gap-x-2'}>
            <Typography variant={'h5'}>Coverage {coverage}%</Typography>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default KubeTotalStats
