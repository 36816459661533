import React from 'react'
import HTMLParser from 'html-parse-stringify'
import Link from 'shared-ui/components/Link'

/**
 * Converts string into array of strings and react <br /> elements so you have line breaks instead of literal <br />s
 * @param text with '<br />' tags
 * @returns {String}
 */
const breakLine = (text = '') => {
  if (!text) return ''

  if (typeof text !== 'string') {
    return text
  }

  let regex = /(<br \/>)/g
  return text.split(regex).map(function (line, index) {
    return line.match(regex) ? <br key={'br_' + index} /> : line
  })
}

const capitalize = str => str && str.slice(0, 1).toUpperCase() + str.slice(1)

const insertBetween = (list, toInsert) => {
  return list.reduce((acc, cur, i) => {
    acc.push(cur)

    if (i < list.length - 1) acc.push(typeof toInsert === 'function' ? toInsert(i) : toInsert)

    return acc
  }, [])
}

const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode
const isFirefox = navigator.userAgent.includes('Firefox')
const isEdge = navigator.userAgent.includes('Edge')
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const stripHtml = string => {
  const regex = /(<([^>]+)>)/gi
  const result = string.replace(regex, '')
  return result
}

const checkHasLabel = string => {
  const result = /({-(.*?)-})+/g.test(string)
  return result
}

const shallowCompare = (a, b) => {
  if (!a && !b) return true

  if (!a || !b) return false

  if (typeof a !== typeof b) return false

  if (typeof a === 'string' || typeof a === 'number') return a === b

  let keysPrev = Object.keys(a)
  let keysCurrent = Object.keys(b)

  if (keysPrev.length !== keysCurrent.length) return false

  return !keysPrev.find(key => b[key] !== a[key])
}

const htmlTextAnchorParser = text => {
  let parsedRawText = HTMLParser.parse(`<span>${text}</span>`)

  const textChildren = []
  parsedRawText[0].children.forEach(contentItem => {
    const { type, name, content, children = [], attrs } = contentItem
    switch (type) {
      case 'text':
        textChildren.push(content)
        break
      case 'tag':
        const { href, target } = attrs
        switch (name) {
          case 'a':
            if (href && !href.includes('http')) {
              textChildren.push(
                <Link to={href} target={target || undefined}>
                  {children[0].content}
                </Link>,
              )
            } else {
              textChildren.push(<a href={href || ''}>{children[0].content}</a>)
            }
            break
          case 'br':
            textChildren.push(<br />)
            break
          default:
            break
        }
        break
      default:
        break
    }
  })
  return textChildren
}

const getBarCodeId = (length, barcodeFormat) => {
  if (barcodeFormat) return barcodeFormat

  let bcid = `ean${length}`

  if (length === 12) bcid = 'upca'
  else if (length === 14) bcid = 'itf14'

  return bcid
}

export {
  breakLine,
  capitalize,
  isIE,
  stripHtml,
  checkHasLabel,
  shallowCompare,
  htmlTextAnchorParser,
  isFirefox,
  isEdge,
  isSafari,
  insertBetween,
  getBarCodeId,
}
