import React from 'react'

const LanguageContext = React.createContext({})

const { Provider: LanguageProvider, Consumer: LanguageConsumer } = LanguageContext

const withLanguage = Component => props => {
  return <LanguageConsumer>{data => <Component {...props} {...data} />}</LanguageConsumer>
}

export { LanguageProvider, LanguageConsumer, withLanguage }
