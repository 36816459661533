import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { capitalize } from '@material-ui/core/utils/helpers'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  input: {
    textAlign: 'center',
  },
  button: {
    padding: 0,
    width: unit * 3,
    height: unit * 3,
  },
  buttonDisabled: {
    color: palette.action.disabled,
  },
  buttonStart: {
    marginLeft: -unit * 0.5,
    marginRight: 0,
  },
  buttonEnd: {
    marginRight: -unit * 0.5,
  },
})

class NumberField extends React.Component {
  handleBlur = e => {
    const { onBlur } = this.props
    let {
      target: { value },
    } = e

    this._propagateChange(value)

    onBlur && onBlur(this._sanatizeValue(value))
  }

  handleButtonDown = e => {
    e.preventDefault()
  }

  handleButtonClick = add => e => {
    const { step = 1, enforceStep, disabled, disableFocus } = this.props
    let { value } = this.input

    if (disabled) return

    if (!disableFocus && this.input) this.input.focus()

    value = parseFloat(value)
    if (isNaN(value)) {
      value = 0
    }

    if (add) {
      value += step
    } else {
      value -= step
    }
    if (enforceStep) {
      value /= step
      value = (add ? Math.floor(value) : Math.ceil(value)) * step
    }

    this._propagateChange(value)
  }

  _sanatizeValue = value => {
    const { type, name, min, max, step, enforceStep } = this.props

    value = parseFloat(value)
    if (isNaN(value)) value = 0

    if (enforceStep) value = Math.round(value / step) * step
    if (max !== undefined) value = Math.min(max, value)
    if (min !== undefined) value = Math.max(min, value)

    return {
      target: {
        type,
        name,
        value,
        input: this.input,
      },
    }
  }

  _propagateChange = value => {
    const { onChange } = this.props
    onChange && onChange(this._sanatizeValue(value))
  }

  _getButtonProps = (disabled, position, handler) => {
    const { classes } = this.props

    return {
      className: classNames(classes.button, classes[`button${capitalize(position)}`], {
        [classes.buttonDisabled]: disabled,
      }),
      disableRipple: disabled,
      disableTouchRipple: disabled,
      onClick: handler,
      onMouseDown: this.handleButtonDown,
      onTouchStart: this.handleButtonDown,
      tabIndex: -1,
    }
  }

  render() {
    const {
      className,
      classes,
      label,
      value,
      name,
      min,
      max,
      onChange,
      helperText,
      disabled,
      InputProps,
      fullWidth,
    } = this.props

    const addDisabled = max !== undefined && value >= max
    const subtractDisabled = min !== undefined && value <= min

    const { classes: inputPropsClasses = {} } = InputProps || {}

    return (
      <FormControl className={classNames(className, classes.root)} fullWidth={fullWidth}>
        {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
        <Input
          inputRef={ref => (this.input = ref)}
          min={min}
          max={max}
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={this.handleBlur}
          startAdornment={
            <InputAdornment position="start" style={{ marginRight: 0 }}>
              <IconButton
                {...this._getButtonProps(
                  subtractDisabled || disabled,
                  'start',
                  this.handleButtonClick(false),
                )}
              >
                <Icon>remove</Icon>
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end" style={{ marginLeft: 0 }}>
              <IconButton
                {...this._getButtonProps(
                  addDisabled || disabled,
                  'end',
                  this.handleButtonClick(true),
                )}
              >
                <Icon>add</Icon>
              </IconButton>
            </InputAdornment>
          }
          {...InputProps}
          classes={{ ...inputPropsClasses, input: classes.input }}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withStyles(styles)(NumberField)
