import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IntlUtil from 'shared-ui/utils/IntlUtil'

const styles = theme => ({
  root: {},
  item: {
    textAlign: 'center',
  },
})

class PointsSummary extends React.Component {
  render() {
    const { classes, points = 23487, pointsInCurrency = 2313248 } = this.props
    return (
      <Grid container>
        <Grid item xs={6} className={classes.item}>
          <Typography variant="h4">{IntlUtil.num(points)}</Typography>
          <Typography>
            Overall Points Balance Points Balance in local currency
            <br />
            (unredeemed)
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.item}>
          <Typography variant="h4">{IntlUtil.price(pointsInCurrency)}</Typography>
          <Typography>Points Balance in local currency</Typography>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PointsSummary)
