import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import CustomReference from './CustomReference'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import CustomReferenceFilter from '~components/atom/FieldFactory/CustomReferenceFilter'
import LinkTypes from 'shared-ui/constants/LinkTypes'

const styles = theme => {
  return {
    listItemChip: {
      backgroundColor: theme.palette.common.red,
    },
    filterHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
    },

    searchButton: {
      padding: '2.5px',
      borderRadius: '5px',
    },
  }
}

class CustomVReference extends React.Component {
  state = {
    isValidInput: false,
    selectedType: undefined,
    inputValue: '',
  }

  render() {
    const { isValidInput, selectedType, inputValue } = this.state
    const {
      classes,
      settings,
      settings: {
        allowExternalUrl = false,
        allowInternalLink = true,
        allowExternalLink = true,
      } = {},
    } = this.props

    const onLinkTypeSelection = (event, type) => {
      this.setState({ selectedType: type })
    }

    const onInputChange = value => {
      this.setState({ inputValue: value })
    }

    const onSearchSubmit = async event => {
      if (!allowExternalUrl) return

      if (inputValue === '') return
      try {
        const { api } = this.props
        const collection = 'sys_external_urls'
        const { request } = api.createModelEntry(`/@/createSilent/${collection}?context=link`, {
          url: inputValue,
          title: inputValue.toUpperCase(),
        })
        const reponse = await request

        this.setState({
          value: {
            _name: `${inputValue.toUpperCase()} [${inputValue}]`,
            title: inputValue.toUpperCase(),
            type: LinkTypes.EXTERNAL_URL,
            _id: reponse.result,
          },
        })
      } catch (e) {
        console.error('Error on search submit', e)
      }
    }

    const filterSuggestions = (rawSuggestions, value) => {
      if (!rawSuggestions) return

      if (value === '' && !selectedType) {
        return searchSuggestions(rawSuggestions)
      }

      let suggestions = rawSuggestions
      if (allowExternalUrl) {
        if (value.startsWith('http://') || value.startsWith('https://')) {
          if (!this.state.isValidInput) this.setState({ isValidInput: true })
        } else {
          if (this.state.isValidInput) this.setState({ isValidInput: false })
        }
      } else {
        suggestions = suggestions.filter(suggestion => suggestion.linkType !== 'externalUrl')
      }

      switch (selectedType) {
        case LinkTypes.EXTERNAL_LINK:
          suggestions = suggestions.filter(suggestion =>
            suggestion.linkType?.startsWith('external'),
          )
          break
        case LinkTypes.INTERNAL_LINK:
          suggestions = suggestions.filter(suggestion =>
            suggestion.linkType?.startsWith('internal'),
          )
          break
        case !selectedType:
        default:
      }

      return searchSuggestions(suggestions)

      function searchSuggestions(rawSuggestions) {
        let suggestions = rawSuggestions
        if (!allowExternalUrl) {
          suggestions = rawSuggestions.filter(suggestion => suggestion.linkType !== 'externalUrl')
        }
        if (!allowExternalLink) {
          suggestions = suggestions.filter(suggestion => suggestion.linkType !== 'externalLink')
        }
        if (!allowInternalLink) {
          suggestions = suggestions.filter(suggestion =>
            suggestion.linkType?.startsWith('external'),
          )
        }

        return suggestions.filter(suggestion => {
          if (value === '') return true
          const { _name, title, _id } = suggestion
          return (_name || title || _id).toLowerCase().indexOf(value.toLowerCase()) >= 0
        })
      }
    }

    const inputEndAdornment = allowExternalUrl ? (
      <>
        <IconButton
          color="secondary"
          className={classes.searchButton}
          variant={'outlined'}
          type={'submit'}
          disabled={!isValidInput}
          onClick={onSearchSubmit}
        >
          <Icon fontSize={'small'}>keyboard_return</Icon>
        </IconButton>
        <Tooltip
          title={'Create new URL starting with http:// or https://'}
          style={{ zIndex: 9999 }}
        >
          <Icon>info</Icon>
        </Tooltip>
      </>
    ) : null

    const onKeyDown = async e => {
      if (e.keyCode === 13 || e.key === 'Enter') {
        await onSearchSubmit(e)
      }
    }

    const _renderfilterHeader = (
      <CustomReferenceFilter
        component={MenuItem}
        selectedType={selectedType}
        onSelection={onLinkTypeSelection}
        settings={settings}
      />
    )

    return (
      <CustomReference
        {...this.props}
        settings={{ ...settings, disableCreatingItem: true }}
        classes={{ listItemChip: classes.listItemChip }}
        referenceFilter={_renderfilterHeader}
        filterSuggestionsByType={filterSuggestions}
        inputChange={onInputChange}
        inputEndAdornment={inputEndAdornment}
        onNewValueCreated={this.state.value}
        onKeyDown={onKeyDown}
      />
    )
  }
}

export default withStyles(styles)(CustomVReference)
