import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import bgSquare from 'shared-ui/assets/img/bg-square.png'
import { withApi } from 'shared-ui/api/ApiContext'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { checkContentType } from 'shared-ui/utils/mediaContent'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  card: {
    position: 'relative',
    backgroundImage: `url("${bgSquare}")`,
    backgroundSize: 18,
  },
  cardList: {
    height: '15rem',
    '&:hover $overlay': {
      opacity: 1,
    },
  },
  media: {
    height: '100%',
  },
  preview: {
    backgroundSize: 'contain',
  },
})

const ImagePreviewDrawer = props => {
  const { classes, open, handleDrawerToggle, data = {}, api } = props
  let { upload } = data

  if (!upload) return null

  if (!Array.isArray(upload)) {
    upload = [upload]
  }

  return (
    <Drawer variant="temporary" anchor="right" open={open} onClose={handleDrawerToggle}>
      <div
        style={{
          padding: '2rem',
          height: '100%',
        }}
      >
        <div className={'flex flex-row justify-between items-center'}>
          <Typography variant={'h5'}>Image Preview</Typography>
          <IconButton onClick={handleDrawerToggle}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <Grid container className={'flex'} spacing={8}>
          {upload?.map((image, index) => {
            image.type = image.type ?? image.mimetype
            image.path = `${api.connector.baseUrl}/@/userChallengeMedia/${image.fileName}`
            return (
              <Grid className={'w-[20rem]'} item>
                <Card className={classNames(classes.cardList, classes.card)}>
                  <CardMedia
                    image={checkContentType(image)}
                    className={
                      !/image/.test(image.type)
                        ? classes.media
                        : classNames(classes.media, classes.preview)
                    }
                    title={image.name}
                  />
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Drawer>
  )
}
export default withApi(withStyles(styles)(ImagePreviewDrawer))
