const PasswordToStrength = props => {
  let {
    password,
    patterns: { required, optional: { patterns = [] } = {} },
  } = props
  if (!password) return null

  let strength = 0
  const allPatterns = [...required, ...patterns]

  allPatterns.forEach(pattern => {
    if (new RegExp(pattern).test(password)) {
      strength++
    }
  })
  strength /= allPatterns.length
  return props.children(strength)
}
export default PasswordToStrength
