import axios from 'axios'
import queryString from 'query-string'
import Subscriber from 'shared-ui/utils/Subscriber'
import captcha from 'shared-ui/utils/captcha'

axios.defaults.withCredentials = true

export default class ApiConnector {
  alertSubscriber = new Subscriber()
  barcodeSubscriber = new Subscriber()
  onNavigateTo
  _createCaptchaToken

  msal
  _msalLogout
  _msalTokenCreator

  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  setupMsalTokenCreator(fn) {
    this._msalTokenCreator = fn
  }

  setupMsal(msal) {
    this.msal = msal
  }

  setupMsalLogout(fn) {
    this._msalLogout = fn
  }

  setupCaptcha(captchaId, captchaVisibility) {
    this._createCaptchaToken = captcha(captchaId, captchaVisibility)
  }

  listenToAlerts = listener => {
    return this.alertSubscriber.subscribe(listener)
  }

  listenToBarcodes = listener => {
    return this.barcodeSubscriber.subscribe(listener)
  }

  makeRequest(url, method, config = {}, data) {
    const source = axios.CancelToken.source()
    config = { ...config, cancelToken: source.token }

    let request = this._makeAsyncRequest(url, method, config, data)

    return {
      request,
      cancel: () => {
        source.cancel('cancelled')
      },
    }
  }

  async _makeAsyncRequest(url, method, config = {}, data) {
    if (!config.headers) config.headers = {}

    config.validateStatus = status => true

    if (this._msalTokenCreator) {
      const token = await this._msalTokenCreator()
      if (token) config.headers['Authorization'] = `Bearer ${token}`
    }

    let responsePromise
    if (data !== undefined) {
      if (config && config.newTab) {
        window.open(`${this.baseUrl}${url}?${queryString.stringify(data)}`, '_blank')
        return
      }

      let token
      if (method === 'post' && this._createCaptchaToken)
        token = await this._createCaptchaToken('default')

      if (token) {
        if (data instanceof FormData) data.append('token', token)
        else data = { ...data, token }

        config.headers['x-captcha-token'] = token
      }
      responsePromise = axios[method](this.baseUrl + url, data, config)
    } else {
      responsePromise = axios[method](this.baseUrl + url, config, config)
    }

    let reponseData
    try {
      ;({ data: reponseData } = await responsePromise)
    } catch (error) {
      if (axios.isCancel(error)) {
        // since this request is cancelled
        // we don't want it to resolve/reject anymore
        return new Promise(() => {})
      }

      const { response: { data: { navigateTo, alert, logoutClient, reload = false } = {} } = {} } =
        error
      if (alert) this.dispatchAlert(alert)
      setTimeout(() => {
        if (logoutClient) this._msalLogout && this._msalLogout()
      }, 3000)

      if (navigateTo) this.onNavigateTo && this.onNavigateTo(navigateTo, reload)

      throw error
    }

    const { navigateTo, alert, scan, logoutClient, reload = false } = reponseData
    if (logoutClient) this._msalLogout && this._msalLogout()
    if (navigateTo) this.onNavigateTo && this.onNavigateTo(navigateTo, reload)
    if (alert) this.dispatchAlert(alert)

    return reponseData
  }

  dispatchAlert = alert => {
    this.alertSubscriber.dispatch(alert)
  }

  dispatchBarcode = data => {
    this.barcodeSubscriber.dispatch(data)
  }

  get = (url, config, data) => {
    return this.makeRequest(url, 'get', config, data)
  }

  delete = (url, config) => {
    return this.makeRequest(url, 'delete', config)
  }

  post = (url, data, config) => {
    return this.makeRequest(url, 'post', config, data)
  }

  put = (url, data, config) => {
    return this.makeRequest(url, 'put', config, data)
  }

  patch = (url, data, config) => {
    return this.makeRequest(url, 'patch', config, data)
  }
}
