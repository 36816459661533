import React, { useCallback, useMemo, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { TableCell, TableRow } from '@material-ui/core'
import TypeChip from './TypeChip'
import PropertyValue from './PropertyValue'
import PropertyType from './PropertyType'
import { changeToType } from './functions'

const renderChange = change => {
  const changeType = changeToType(change)
  if (changeType) return <TypeChip type={changeType} />
  return changeType
}

const renderCell = (value, change) => {
  if (change) return <TypeChip label={value} type={change} />
  return value
}

const renderExtraRow = (id, option, isFirst, options, change, classRemoved) => {
  const changeType = changeToType(change)
  const cellClasses = (changeType === 'removed' && { root: classRemoved }) || undefined
  const label = JSON.stringify(option.label)
  return (
    <TableRow key={id + '_' + option.value}>
      {isFirst && <TableCell rowSpan={options.length} colSpan={2} />}
      <TableCell align={'right'} classes={cellClasses}>
        <PropertyValue value={option.value} />
      </TableCell>
      <TableCell classes={cellClasses}>
        {change && change.label ? (
          <TypeChip label={label} type={changeToType(change.label)} />
        ) : (
          label
        )}
      </TableCell>
      <TableCell classes={cellClasses} />
      <TableCell classes={cellClasses}>
        {changeType && changeType !== 'updated' && <TypeChip type={changeType} />}
      </TableCell>
    </TableRow>
  )
}

const PropertyRow = ({
  classes,
  data: { options, ident, type, settings, label, defaultValue } = {},
  change,
  id,
}) => {
  const [showOptions, setShowOptions] = useState(
    change === 'removed' || options === undefined ? undefined : false,
  )

  const renderedOptions = useMemo(
    () =>
      showOptions &&
      options &&
      options
        .map((o, i) => {
          const res = []
          let optionChange
          if (change && change.options) {
            optionChange = change.options[i]
          }
          res.push(renderExtraRow(id, o, i === 0, options, optionChange, classes.row_removed))
          return res
        })
        .flat(1),
    [showOptions, options, change && change.options],
  )

  const handleTypeClick = useCallback(() => setShowOptions(show => !show), [])

  const changeType = changeToType(change)

  return (
    <React.Fragment>
      <TableRow classes={{ root: changeType ? classes['row_' + changeType] : undefined }}>
        <TableCell>
          {renderCell(
            <span style={ident ? undefined : { opacity: 0 }} role={'img'} aria-label={'ident'}>
              🔑
            </span>,
            change && change['ident'],
          )}
        </TableCell>
        <TableCell>{id}</TableCell>
        <TableCell onClick={showOptions !== undefined ? handleTypeClick : undefined}>
          <PropertyType
            type={type}
            settings={settings}
            expanded={showOptions}
            hasChangesInExpanded={!!(change && change.options)}
            render={val => renderCell(val, change && change['type'])}
          />
        </TableCell>
        <TableCell>{renderCell(label, change && change['label'])}</TableCell>
        <TableCell>
          <PropertyValue
            value={defaultValue}
            render={val => renderCell(val, change && change['defaultValue'])}
          />
        </TableCell>
        <TableCell>{renderChange(changeType)}</TableCell>
      </TableRow>
      {renderedOptions}
    </React.Fragment>
  )
}

const styles = ({ palette, spacing: { unit } }) => ({
  none: {},
  row_removed: {
    backgroundColor: palette.grey100,
  },
  added: {
    backgroundColor: palette.primary.main,
  },
  updated: {
    backgroundColor: palette.common.orange,
  },
  removed: {
    backgroundColor: palette.common.red,
  },
})

export default withStyles(styles)(PropertyRow)
