import React, { Component } from 'react'

export default class BaseField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
      originalValue: props.value,
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.controlled) return this.props.value !== nextProps.value
    return true
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.originalValue) {
      return {
        value: props.value,
        originalValue: props.value,
      }
    }
    return null
  }

  handleChange = async e => {
    const { disabled } = this.props
    if (disabled) return
    const { onChange, name: nameProps } = this.props
    const {
      target: { value, name },
    } = e

    if (name === nameProps) this.setState({ value })
    else this.setState({ [name]: value })

    onChange && (await onChange(e))
  }

  handleUpdate = value => {
    const { disabled } = this.props
    if (disabled) return

    this.setState({ value })

    const { onUpdate, name } = this.props
    onUpdate &&
      onUpdate({
        target: {
          name,
          value,
        },
      })
  }

  render() {
    const {
      defaultColumn,
      columnSize,
      originalName,
      children,
      type,
      maxLength,
      pattern,
      required,
      step,
      settings,
      settings: { min, max } = {},
      helperText,
      label,
      name,
      ident,
      unique,
      ...restProps
    } = this.props

    const { value, originalValue, ...restState } = this.state

    let newLabel = label

    if (process.env.NODE_ENV !== 'production' && name && type !== 'button') {
      const nlabel = label || name
      const nname = label ? `~ [${name}]` : ''
      newLabel = `${nlabel} ${nname}`
    }

    let newProps = {
      ...restProps,
      ...restState,
      fullWidth: true,
      label: newLabel || name,
      ident: `${ident}`,
      unique: `${unique}`,
      name: `${name}`,
      helperText,
      required,
      settings,
      inputProps: {
        maxLength,
        pattern,
        required,
        step: type === 'integer' ? 1.0 : step,
      },
      min,
      max,
      type: type === 'float' || type === 'integer' ? 'number' : type,
      onChange: this.handleChange,
      onUpdate: this.handleUpdate,
      value,
    }

    return React.cloneElement(this.props.children, newProps)
  }
}
