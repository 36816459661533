import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import Hidden from '@material-ui/core/Hidden'
import Menu from '@material-ui/core/Menu'

const styles = ({ palette, spacing: { unit }, breakpoints }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&>*': {
      margin: '2px',
    },
    flexWrap: 'wrap',
  },
  label: {
    display: 'flex',
  },
  actionIcon: {
    // width: unit * 2,
    // height: unit * 2,
    // position: 'absolute',
    // left: unit * 0.5
  },
  actionLabelWithIcon: {
    marginLeft: unit / 2,
  },
  button: {
    fontSize: '11px',
  },
  status: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  published: {
    color: palette.primary.main,
  },
  draft: {
    color: palette.error.main,
  },
  archived: {
    color: palette.grey400,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 2,
  },
  patch: {
    color: palette.paper,
    backgroundColor: palette.primary.main,
    border: `solid 1px ${palette.paper}`,
    '&:disabled': {
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      backgroundColor: palette.paper,
    },
    '&:hover': {
      backgroundColor: palette.accent200.main,
    },
  },
  fab: {
    minWidth: unit * 3.33,
    minHeight: unit * 3.33,
    width: unit * 3.33,
    height: unit * 3.33,
    borderRadius: '50%',
  },
})

class ModelActionBar extends React.Component {
  state = { showActions: null }

  handleActionClick = async event => {
    const { actions, onActionClick } = this.props
    if (!onActionClick) return

    const { currentTarget: { id } = {} } = event
    const action = actions[parseInt(id, 10)]

    this.setState({ processingActionId: id })

    const { request } = (await onActionClick(action)) || {}

    if (request) {
      try {
        await request
      } catch (err) {}
    }

    this.setState({ processingActionId: undefined, showActions: null })
  }

  handleChange = e => {
    const { history, versionHistory } = this.props
    let {
      target: { value },
    } = e

    value = parseInt(value, 10)
    const { href } = versionHistory[value]
    history.push(href)
  }

  renderDateAndUser = (verb, createdAt, createdBy) => {
    return (
      createdAt && (
        <Typography style={{ fontSize: '0.7rem' }}>
          {verb} at <b>{IntlUtil.dateTime(createdAt)}</b>
          {createdBy && (
            <React.Fragment>
              {' '}
              by <b>{createdBy.name}</b>
            </React.Fragment>
          )}
        </Typography>
      )
    )
  }

  handleShowActions = e => {
    this.setState({
      showActions: e.currentTarget,
    })
  }
  handleClose = () => {
    this.setState({ showActions: null })
  }

  renderActions = Component => {
    const { classes, actions, numSelectedRows } = this.props
    const { processingActionId } = this.state

    return actions.map((action, index) => {
      const { disabled = {} } = this.props
      const { action: actionName, id: actionId, selectable, styles } = action
      const { icon, label } = action
      const isDisabled = disabled[actionName] === true || disabled[actionId] === true
      const buttonClass =
        styles === true ? classNames(classes.button, classes[actionName]) : classes.button

      return (
        <Component
          id={index}
          disabled={
            isDisabled || processingActionId !== undefined || (selectable && numSelectedRows === 0)
          }
          size="small"
          variant="outlined"
          className={buttonClass}
          classes={{
            label: classes.label,
          }}
          key={actionName}
          onClick={this.handleActionClick}
        >
          {icon && <Icon className={classes.actionIcon}>{icon}</Icon>}

          <span
            className={classNames({
              [classes.actionLabelWithIcon]: icon !== undefined,
            })}
          >
            {label || actionName}
          </span>

          {processingActionId === index.toString() && (
            <LinearProgress className={classes.progress} />
          )}
        </Component>
      )
    })
  }

  render() {
    const { classes, actions, versionHistory, modelId } = this.props
    const { showActions } = this.state
    const status = versionHistory && versionHistory.find(item => item._id === modelId).status
    const open = Boolean(showActions)

    return (
      <div className={classes.root}>
        {status && (
          <Typography className={classNames(classes.status, classes[status])}>{status}</Typography>
        )}
        {versionHistory && (
          <Select
            displayEmpty
            value={versionHistory.findIndex(({ _id }) => modelId === _id)}
            onChange={this.handleChange}
            input={<Input />}
            renderValue={() => 'Version History'}
          >
            {versionHistory.map((item, index) => {
              const { _id, createdAt, createdBy, publishedAt, publishedBy, status } = item
              return (
                <MenuItem
                  key={_id}
                  value={index}
                  style={{
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  {this.renderDateAndUser('created', createdAt, createdBy)}
                  {this.renderDateAndUser('published', publishedAt, publishedBy)}

                  <Typography
                    style={{ fontSize: '0.75rem', textTransform: 'uppercase' }}
                    className={classes[status]}
                  >
                    {status}
                  </Typography>
                </MenuItem>
              )
            })}
          </Select>
        )}
        <Hidden smUp>
          {actions && actions.length > 0 ? (
            <div className={classes.fabContainer}>
              <Button size="small" className={classes.fab} onClick={this.handleShowActions}>
                <Icon>more_vert</Icon>
              </Button>
              <Menu open={open} anchorEl={showActions} onClose={this.handleClose}>
                {this.renderActions(MenuItem)}
              </Menu>
            </div>
          ) : null}
        </Hidden>
        <Hidden xsDown>{this.renderActions(Button)}</Hidden>
      </div>
    )
  }
}

export default withStyles(styles)(ModelActionBar)
