import React from 'react'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

const styles = () => ({
  root: {
    marginBottom: 15,
    padding: 15,
  },
  iconDisplay: {
    display: 'inline-flex',
    verticalAlign: 'top',
    fontSize: '2rem',
  },
})

class ModelNote extends React.Component {
  render() {
    const {
      classes,
      header: { title, description },
      background = 'white',
      icon = 'error_outline',
      iconColor = '#ffa000',
      body = [],
    } = this.props

    return (
      <Paper className={classes.root} style={{ backgroundColor: background }}>
        <Typography variant="h5" color={'primary'}>
          <Icon slot="start" className={classes.iconDisplay} style={{ color: iconColor }}>
            {icon}
          </Icon>{' '}
          {title}
        </Typography>
        <Typography variant="subtitle" style={{ padding: '10px 0', fontSize: 16 }}>
          {description}
        </Typography>

        <Divider variant="fullWidth" />

        {Array.isArray(body) && body.length > 0 && (
          <List dense={true}>
            {body.map((message, index) => {
              return (
                <React.Fragment>
                  <ListItem key={index}>
                    <ListItemText style={{ fontSize: 14 }} primary={message.title} />
                  </ListItem>

                  {message.items && (
                    <List dense={true} style={{ paddingLeft: 30 }}>
                      {message.items.map((sub, subIndex) => {
                        return (
                          <ListItem key={subIndex}>
                            <ListItemText style={{ fontSize: 14 }} primary={sub.title} />
                          </ListItem>
                        )
                      })}
                    </List>
                  )}
                </React.Fragment>
              )
            })}
          </List>
        )}
      </Paper>
    )
  }
}

export default withStyles(styles)(ModelNote)
