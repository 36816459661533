import { Quill } from 'react-quill'
import ImageBlot from './ImageBlot'

let InlineEmbed = Quill.import('blots/embed')
class ReferenceBlot extends InlineEmbed {
  static create(value) {
    // this.ref = value
    let node = super.create()

    if (value.style === 'button') {
      node = document.createElement('button')
      node.setAttribute(
        'style',
        'color: #ffffff; background-color: #00b2a9; border: 1px solid #ffffff; margin: 2px; padding: 8px 24px; width: fit-content; font-size: 11px; letter-spacing: 1px; text-transform: uppercase; font-weight: bolder',
      )
    }

    if (typeof value !== 'object') return node

    // const p = document.createElement('div')
    // p.appendChild(node)

    node.innerText = value.text || (value.alt && value.alt.trim()) || ''
    node.setAttribute('alt', value.text || (value.alt && value.alt.trim()) || '')
    // node.setAttribute('target', '_blank')
    node.setAttribute('href', '')
    node.setAttribute('style', value.style)
    node.setAttribute('sitemapid', value._id || value.sitemapid)
    node.setAttribute('target', value.target)
    node.setAttribute('_target', value.target)
    node.setAttribute('data_object_id', value.data_object_id)

    if (value.pendingFile) {
      const { pendingFile } = value
      // insert an image
      let image = ImageBlot.create({
        src: pendingFile.path,
        alt: pendingFile.name,
        data_object_id: pendingFile._id,
      })
      node.appendChild(image)
    }

    // if (value.style === 'button') {
    //   node.setAttribute('style', 'color: rgba(0, 0, 0, 0.87); background-color: #b0d121; padding: 20px; width: fit-content;')
    // }

    // node.onclick = function () { window.open(value.src) }
    return node
  }

  static value(node) {
    // return this.ref
    const img = node.querySelector('img')
    const attributes = {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('href'),
      target: node.getAttribute('target'),
      _target: node.getAttribute('target'),
      style: node.getAttribute('style'),
      sitemapid: node.getAttribute('sitemapid'),
      data_object_id: node.getAttribute('data_object_id'),
    }

    if (img) {
      attributes.pendingFile = {
        path: img.getAttribute('src'),
        name: img.getAttribute('alt'),
        data_object_id: img.getAttribute('data_object_id'),
      }
    }

    return attributes
  }
}
ReferenceBlot.blotName = 'reference'
ReferenceBlot.tagName = 'a'
ReferenceBlot.className = 'ql-referenceLabel'

export default ReferenceBlot
