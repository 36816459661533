import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextInputField from 'shared-ui/components/organisms/TextInputField'
import PasswordStrengthBox from 'shared-ui/components/molecules/PasswordStrengthBox'
import PasswordToStrength from 'shared-ui/components/atoms/PasswordToStrength'

class PasswordWithRetype extends React.Component {
  state = { areMatching: true }
  _passwordRef
  _retypeRef

  static getDerivedStateFromProps({ invalid }, prevState) {
    if (invalid !== undefined && prevState.invalid !== invalid) {
      return { invalid }
    }

    return null
  }

  render() {
    const { value, data, className, errorText, classes } = this.props
    const { areMatching, isPasswordInvalid, currentValue } = this.state
    const { retypeData, mismatchErrorText } = data.custom
    const { passwordStrength } = data

    const areNotMatching = areMatching === false

    return (
      <div className={className}>
        <TextInputField
          className={classes.password}
          innerRef={ref => (this._passwordRef = ref)}
          fullWidth
          tabIndex={1}
          data={{
            ...data,
            type: 'password',
            custom: undefined,
            ...(errorText ? { errorText } : {}),
          }}
          invalid={isPasswordInvalid || errorText}
          value={value}
          onBlur={this._handleBlur}
          onFocus={target => this._handleFocus(target, true)}
          onChange={this._handleValueChange}
          tipRender={(props = {}) => (
            <PasswordToStrength patterns={passwordStrength.patterns} password={currentValue}>
              {strength => (
                <PasswordStrengthBox
                  {...props}
                  className={`${props.className} ${classes.strengthBox}`}
                  config={passwordStrength.description}
                  strength={strength}
                  width="90%"
                  description={passwordStrength.text}
                />
              )}
            </PasswordToStrength>
          )}
        />
        <TextInputField
          fullWidth
          tabIndex={2}
          innerRef={ref => (this._retypeRef = ref)}
          data={{
            ...retypeData,
            type: 'password',
          }}
          errorText={areNotMatching ? mismatchErrorText : undefined}
          invalid={areNotMatching}
          onBlur={this._handleChange}
          onFocus={this._handleFocus}
        />
      </div>
    )
  }

  blur = () => {
    this._passwordRef && this._passwordRef.blur()
    this._retypeRef && this._retypeRef.blur()
  }

  _handleValueChange = ({ target: { value } }) => {
    this.setState({ currentValue: value })
  }

  _handleBlur = event => {
    this.setState({ currentValue: undefined })
    this._handleChange(event)
  }

  _handleFocus = ({ target }, setState = false) => {
    this.props.onFocus && this.props.onFocus()
    this.setState({
      areMatching: true,
      currentValue: setState ? target.value : undefined,
      externalError: false,
    })
  }

  _handleChange = () => {
    if (!this._passwordRef.checkValidity()) {
      this.setState({ isPasswordInvalid: true, areMatching: true })
      return
    }

    const value = this._passwordRef.getValue()
    const retypedValue = this._retypeRef.getValue()
    const areMatching = (!value && !retypedValue) || value === retypedValue
    this.setState({ areMatching, isPasswordInvalid: false })
  }

  checkValidity = () => {
    if (!this._passwordRef.checkValidity()) {
      this.setState({ isPasswordInvalid: true })
      return false
    }

    const { data } = this.props
    const {
      passwordStrength: { patterns },
      required,
    } = data

    if (required && !this.testPassword(patterns, this.getValue())) {
      this.setState({ isPasswordInvalid: true })
      return false
    }

    const retypedValue = this._retypeRef.getValue()
    if (required && !retypedValue) {
      this.setState({ areMatching: false, isPasswordInvalid: false })
      return false
    }

    return this.state.areMatching === true
  }

  getValue = () => {
    let value = this._passwordRef.getValue()
    if (!value) return undefined

    return value
  }

  testPassword = (passwordPatterns, password) => {
    let { required, optional: { min, patterns } = {} } = passwordPatterns

    if (required) {
      if (!required.every(p => new RegExp(p).test(password))) return false
    }

    if (patterns && patterns.length) {
      if (min === undefined) min = patterns.length - 1
      let score = 0

      patterns.forEach(p => {
        if (new RegExp(p).test(password)) ++score
      })

      return score >= min
    }

    return true
  }
}

const styles = ({ spacing: { unit } }) => ({
  password: {
    marginBottom: unit,
  },
  strengthBox: {
    boxShadow:
      '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14) !important',
  },
})

export default withStyles(styles)(PasswordWithRetype)
