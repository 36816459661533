import React from 'react'
import Alpha from 'react-color/lib/components/common/Alpha'
import Checkboard from 'react-color/lib/components/common/Checkboard'
import ColorWrap from 'react-color/lib/components/common/ColorWrap'
import Hue from 'react-color/lib/components/common/Hue'
import Saturation from 'react-color/lib/components/common/Saturation'
import SketchFields from 'react-color/lib/components/sketch/SketchFields'
import SketchPresetColors from 'react-color/lib/components/sketch/SketchPresetColors'
import Button from '@material-ui/core/Button'

export const Sketch = ({
  classes,
  width = 200,
  rgb,
  hex,
  hsv,
  hsl,
  onChange,
  onClear,
  onSwatchHover,
  disableAlpha,
  presetColors,
  renderers,
}) => {
  return (
    <div className="bg-paper flex flex-col rounded-md p-3" style={{ width }}>
      <div className="relative aspect-[4/3] w-full overflow-hidden">
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
      </div>
      <div className="flex">
        <div className="flex-1 py-2">
          <div className="relative h-[10px] overflow-hidden">
            <Hue hsl={hsl} onChange={onChange} />
          </div>
          {!disableAlpha && (
            <div className="relative mt-1 h-[10px] overflow-hidden">
              <Alpha
                className={classes.Alpha}
                rgb={rgb}
                hsl={hsl}
                renderers={renderers}
                onChange={onChange}
              />
            </div>
          )}
        </div>
        <div className="relative ms-1 mt-1 size-6 rounded-sm">
          {!disableAlpha && <Checkboard />}
          <div
            className="absolute inset-0 rounded-sm"
            style={{ background: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})` }}
          />
        </div>
      </div>

      <SketchFields rgb={rgb} hsl={hsl} hex={hex} onChange={onChange} disableAlpha={disableAlpha} />
      {presetColors && (
        <SketchPresetColors
          colors={presetColors}
          onClick={onChange}
          onSwatchHover={onSwatchHover}
        />
      )}
      <Button className="self-end" onClick={onClear}>
        Clear
      </Button>
    </div>
  )
}

export default ColorWrap(Sketch)
