import sizeImagePath from './sizeImagePath'

export const checkContentType = (file, base64) => {
  if (/image/.test(file.type)) {
    if (base64) return file.src
    if (/svg\+xml/.test(file.type)) return file.path
    return sizeImagePath(file.path, 'xxs')
  } else if (/pdf/.test(file.type)) {
    return '/static/images/pdf_black.png'
  } else if (/video/.test(file.type)) {
    return '/static/images/video_black.png'
  }
}
