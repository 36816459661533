import { Dialog, SwipeableDrawer } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const KubernetesDrawer = props => {
  // const { classes, open, onClose, data, onItemClick } = props

  return (
    <Dialog aria-labelledby="simple-dialog-title" {...props}>
      <div
        className={'w-1/2 translate-x-1/2 transform'}
        // tabIndex={0}
        role="button"
        // onClick={this.toggleDrawer('bottom', false)}
        // onKeyDown={this.toggleDrawer('bottom', false)}
      >
        <ListData {...props} />
      </div>
    </Dialog>
  )
}

const FullList = props => {
  const { classes, data, onItemClick, onClose } = props

  // const onClick = namespace => {
  //   // onItemClick(namespace)
  //   onClose(namespace)
  // }

  return (
    <div className={classes.fullList}>
      <List>
        {data?.map(({ namespace, market }, index) => (
          <>
            <ListItem button key={namespace} onClick={e => onClose(namespace)}>
              <Typography variant={'h6'}>{market}</Typography>
              {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
              <ListItemText primary={namespace} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </div>
  )
}

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}

const paperStyles = {
  paper: {
    width: '100%',
    // transform: 'translateX(50%) !important',
  },
}

const ListData = withStyles(styles)(FullList)
export default withStyles(paperStyles)(KubernetesDrawer)
