import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withLanguage } from '~src/LanguageContext'
import classNames from 'classnames'

const styles = ({ spacing: { unit } }) => ({
  root: {
    minWidth: unit * 12,
  },
})

class LanguageSelector extends React.Component {
  render() {
    const { supportedLanguages, selectedLanguage, classes, className } = this.props

    if (!selectedLanguage) return null

    return (
      <Select
        className={classNames(classes.root, className)}
        classes={{
          root: classes.root,
          selectMenu: classes.selectMenu,
          icon: classes.icon,
        }}
        value={selectedLanguage.id}
        onChange={this._handleChange}
      >
        {supportedLanguages.map(({ id, label, labelShort }) => (
          <MenuItem key={id} value={id}>
            {`${labelShort} ${label}`}
          </MenuItem>
        ))}
      </Select>
    )
  }

  _handleChange = event => {
    const { onLanguageChange, supportedLanguages } = this.props
    const chosenLanguage = supportedLanguages.find(({ id }) => id === event.target.value)

    onLanguageChange && onLanguageChange(chosenLanguage)
  }
}

export default withLanguage(withStyles(styles)(LanguageSelector))
