const LinkTypes = {
  EXTERNAL_URL: 'externalUrl',
  EXTERNAL_LINK: 'externalLink',
  INTERNAL_LINK: 'internalLink',
  INTERNAL_LIGHTBOX: 'internalLightbox',
  EXTERNAL: 'external',
  INTERNAL: 'internal',
}
export default LinkTypes
// there is a redundancy of this exact constants in shared-api -> ideally both should be merged and maintained as one shared lib across the project
