import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  topSpacer: {
    marginTop: unit,
  },
})

class CustomCheckBoxField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
    }
  }

  _handleChange = item => event => {
    let newState
    if (event.target.checked) {
      newState = [...this.state.value, item]
      this.setState({ value: newState })
    } else {
      newState = this.state.value.filter(v => v !== item)
      this.setState({ value: newState })
    }

    this.props.onChange.apply(this, [
      {
        target: {
          name: this.props.name,
          value: newState,
        },
      },
    ])
  }

  render() {
    let { classes, theme, helperText, value, options, ...props } = this.props

    return (
      <FormControl className={classes.formControl} fullWidth {...props}>
        {this.props.label && <InputLabel shrink="true">{this.props.label}</InputLabel>}
        <FormGroup className={classes.topSpacer} name={this.props.name}>
          {this.props.options.map(option => (
            <FormControlLabel
              key={option.label}
              control={
                <Checkbox
                  disabled={option.disabled}
                  value={option.value}
                  checked={this.state.value.includes(option.value)}
                  onChange={this._handleChange(option.value)}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

CustomCheckBoxField.propTypes = {
  classes: PropTypes.object.isRequired,
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomCheckBoxField)
