import React, { useCallback, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Icon,
  ButtonBase,
} from '@material-ui/core'
import EntityPanel from './EntityPanel'

let cachedHideUnchanged
let cachedSearchQuery = ''

const AdminVersionDiffPage = ({
  classes,
  entities = {},
  version,
  versions,
  changes,
  comparedVersion,
}) => {
  const [hideUnchanged, setHideUnchanged] = useState(cachedHideUnchanged)
  const [searchQuery, setSearchQuery] = useState(cachedSearchQuery)

  const handleVersionSelect = useCallback(
    e => window.navigateTo('/version/diff/' + e.target.value),
    [],
  )
  const handleShowOnlyChangedChange = useCallback(e => {
    cachedHideUnchanged = e.target.checked
    setHideUnchanged(e.target.checked)
  }, [])
  const handleSearchInputChange = useCallback(e => {
    cachedSearchQuery = e.target.value
    setSearchQuery(e.target.value)
  }, [])
  const handleSearchClearClick = useCallback(() => {
    cachedSearchQuery = ''
    setSearchQuery('')
  }, [])

  return (
    <div>
      <div className={classes.headContainer}>
        <Select onChange={handleVersionSelect} value={version} className={classes.versionSelect}>
          {versions.map(v => {
            const label = v.startsWith('@') ? v : `v${v}`
            return (
              <MenuItem key={v} value={v}>
                {label}
              </MenuItem>
            )
          })}
        </Select>
        <Typography>compared to v{comparedVersion}</Typography>
        <FormControlLabel
          className={classes.headToggle}
          control={<Switch onChange={handleShowOnlyChangedChange} checked={hideUnchanged} />}
          labelPlacement={'start'}
          label={'Hide unchanged'}
        />
        <TextField
          classes={{ root: classes.searchField }}
          placeholder="search"
          onChange={handleSearchInputChange}
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <ButtonBase
                onClick={handleSearchClearClick}
                style={{ order: 1, visibility: searchQuery ? undefined : 'hidden' }}
              >
                <Icon color="disabled" fontSize="small">
                  clear
                </Icon>
              </ButtonBase>
            ),
          }}
        />
      </div>
      <Grid container spacing={8}>
        {Object.values(entities).map(e =>
          (searchQuery &&
            ![e.name || (e.settings && e.settings.name) || '', e.colName]
              .join('\n')
              .toLowerCase()
              .includes(searchQuery)) ||
          (hideUnchanged && !changes[e.colName]) ? undefined : (
            <Grid item xs={12} key={e.colName}>
              <EntityPanel data={e} change={changes[e.colName]} />
            </Grid>
          ),
        )}
      </Grid>
    </div>
  )
}

const styles = ({ spacing: { unit } }) => ({
  versionSelect: {
    minWidth: unit * 10,
    marginRight: unit,
  },
  headContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: unit,
  },
  headToggle: {
    marginLeft: 'auto',
  },
  searchField: {
    marginLeft: unit,
  },
})

export default withStyles(styles)(AdminVersionDiffPage)
