import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function PendingButton({ children, pending, disabled, ...rest }) {
  return (
    <Button disabled={disabled || pending} {...rest}>
      <div className={`flex flex-row items-center ${pending ? 'invisible' : 'visible'}`}>
        {children}
      </div>
      {pending && (
        <div className="absolute inset-0 flex items-center justify-center">
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
    </Button>
  )
}
