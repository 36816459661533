import React, { useState } from 'react'
import NumberField from 'shared-ui/components/NumberField'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'

const CustomGeoPoint = props => {
  const {
    label,
    value: { coordinates },
  } = props

  const [newCoordinates, setNewCoordinates] = useState({
    longitude: coordinates[0],
    latitude: coordinates[1],
  })

  const handleBlur = event => {
    const { onChange } = props
    onChange && onChange(event)
  }
  const handleChange = (value, key) => {
    const { onUpdate } = props
    setNewCoordinates(coords => {
      const points = { ...coords, [key]: value }
      onUpdate &&
        onUpdate({
          type: 'Point',
          coordinates: [points['longitude'], points['latitude']],
        })
      return { ...coords, [key]: value }
    })
  }

  return (
    <div container className={'gap-4'}>
      <div className={'flex gap-6'}>
        <NumberField
          className={'w-full'}
          value={newCoordinates.latitude}
          label={`${label}: Latitude`}
          onBlur={handleBlur}
          name={'latitude'}
          helperText={'Range: -90 to 90'}
          min={-90}
          max={90}
          onChange={e => handleChange(e.target.value, 'latitude')}
        />
        <NumberField
          className={'w-full'}
          value={newCoordinates.longitude}
          label={`${label}: Longitude`}
          onBlur={handleBlur}
          name={'longitude'}
          helperText={'Range: -180 to 180'}
          min={-180}
          max={180}
          onChange={e => handleChange(e.target.value, 'longitude')}
        />
      </div>
    </div>
  )
}

export default CustomGeoPoint
