import React from 'react'
import ModelList from '~components/page/ModelList/ModelList'
import ModelDetails from '~components/page/ModelDetails/ModelDetails'
import withStyles from '@material-ui/core/styles/withStyles'
import ModelInfoTile from '~components/molecules/ModelInfoTile/ModelInfoTile'
import ACTION_NAMES from './actionNames'
import CustomMedia from '~components/atom/FieldFactory/CustomMedia/CustomMedia'
import ModelStats from './ModelStats'
import ModelDetailsCustom from '../ModelDetails/ModelDetailsCustom'

const findAction = (actions, actionName) =>
  actions && actions.find(({ action }) => action === actionName)

class ModelViewWrapper extends React.Component {
  constructor() {
    super()
    this.state = { statsData: null }
  }

  componentDidMount() {
    const { statsdata } = this.props
    this.setState({ statsData: statsdata })
  }

  renderList = hasDetails => {
    let { classes, elements, actions, columnData, type, ...rest } = this.props

    elements = elements || (columnData && { actions, columnData })

    if (elements) {
      if (type === 'media') {
        return <CustomMedia isRichText isList />
      }

      return (
        <ModelList className={classes.item} {...elements} {...rest} onAPICall={this.updateStats} />
      )
    }

    return null
  }

  updateStats = data => {
    const newStats = (data && data.statsdata) || null
    this.setState({ statsData: newStats })
  }

  render() {
    let { classes, infoTile, structure, rawData, schemeData, actions, versionHistory, ...rest } =
      this.props

    const { statsData } = this.state
    const hasDetails = structure && rawData && schemeData
    const details = { structure, rawData, schemeData, actions, versionHistory }

    // Customization for pending registration section
    if (this.props.crmContactDetail && this.props.crmAccountDetail) {
      const crmContactModel = this.props.crmContactDetail
      const crmAccountModel = this.props.crmAccountDetail

      const hasMultipleModelDetails = crmContactModel && crmAccountModel

      const crmContactModelDetails = {
        structure: crmContactModel.structure,
        rawData: crmContactModel.rawData,
        schemeData: crmContactModel.schemeData,
        actions: crmContactModel.actions,
      }

      const crmAccountModelDetails = {
        structure: crmAccountModel.structure.filter(section => section.name !== 'Tracking Data'),
        rawData: crmAccountModel.rawData,
        schemeData: crmAccountModel.schemeData,
        actions: crmAccountModel.actions,
      }

      const filteredStructure = structure.filter(section => section.name !== 'Tracking Data')

      return (
        <React.Fragment>
          {hasMultipleModelDetails && (
            <>
              <ModelDetails className={classes.item} {...details} {...rest} />
              <ModelDetailsCustom
                sectionName="Contact Information"
                className={classes.item}
                {...crmContactModelDetails}
                {...rest}
              />
              <ModelDetailsCustom
                sectionName="Account Information"
                className={classes.item}
                {...crmAccountModelDetails}
                {...rest}
              />
            </>
          )}

          {this.renderList(hasDetails)}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {infoTile && <ModelInfoTile {...infoTile} className={classes.item} />}

        {statsData && <ModelStats {...statsData} className={classes.item} />}

        {hasDetails && <ModelDetails className={classes.item} {...details} {...rest} />}

        {this.renderList(hasDetails)}
      </React.Fragment>
    )
  }
}

const styles = ({ spacing: { unit } }) => ({
  item: {
    marginBottom: unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const processActionRequest = async (action, api, data, changed) => {
  const { action: actionName, url } = action

  switch (actionName) {
    case ACTION_NAMES.GOTO: {
      const { target = '_blank' } = action
      window.open(url, target)
      return true
    }
    case ACTION_NAMES.DUPLICATE:
    case ACTION_NAMES.POST: {
      const {
        url,
        method = 'POST',
        confirm,
        addFields = [],
        prompt: { field, message = '', defaultValue } = {},
      } = action
      const { _id } = data
      const body = { _id, changed }

      addFields.forEach(f => (body[f] = data[f]))
      if (prompt && field) {
        body[field] = window.prompt(message, defaultValue)
        if (!body[field]) return
      }
      if (confirm && !window.confirm(confirm)) return

      return api.connector[method.toLowerCase()](url.replace('[id]', _id), body)
    }
    case ACTION_NAMES.DELETE_SINGLE:
    case ACTION_NAMES.DELETE: {
      if (!window.confirm('Are you sure you want to delete this?')) {
        return null
      }

      const { url, method = 'DELETE' } = action
      const { _id } = data

      return api.connector[method.toLowerCase()](url.replace('[id]', _id))
    }
    case ACTION_NAMES.SSOMETADATA:
    case ACTION_NAMES.DOWNLOAD: {
      const { url } = action
      const { _id } = data

      return api.download(url.replace('[id]', _id))
    }
    default:
      break
  }

  return false
}

export { processActionRequest, findAction }

export default withStyles(styles)(ModelViewWrapper)
