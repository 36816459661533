import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedTableHeadSelect from '~components/atom/EnhancedTableHeadSelect'
import { MenuProps } from '~components/atom/SelectMenuItem'

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.paper,
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  head: {
    whiteSpace: 'nowrap',
  },
  actionCell: {
    width: theme.spacing.unit * 4,
  },
})

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  handleEnumFilterChange = col => value => {
    if (!Array.isArray(value)) {
      const { selectedEnumFilters } = this.props
      let currentValue = selectedEnumFilters && selectedEnumFilters[col.name]
      // user clicked the same option so the selection is reset
      if (currentValue === value) value = undefined
    }

    const { onEnumFilterChangeRequested } = this.props
    onEnumFilterChangeRequested && onEnumFilterChangeRequested(col.name, value)
  }

  render() {
    const {
      classes,
      orderDir,
      orderBy,
      columnData,
      selectedColumns,
      selectedEnumFilters,
      isRowDeletable,
      isRowDuplicatable,
      isRowSelectable,
      onSelectAllRequested,
      numSelectedRows,
      numRows,
      supportedLanguages,
      selectedLanguage,
      visibleRows,
    } = this.props

    return (
      <TableHead className={classes.header}>
        <TableRow>
          {isRowDeletable && <TableCell padding="none" className={classes.actionCell} />}

          {isRowDuplicatable && <TableCell padding="none" className={classes.actionCell} />}

          {isRowSelectable && (
            <TableCell padding="none" className={classes.actionCell}>
              <Checkbox
                disabled={numRows === 0}
                indeterminate={numSelectedRows > 0 && numSelectedRows < numRows}
                checked={numRows > 0 && numSelectedRows === numRows}
                onChange={e => onSelectAllRequested(e, visibleRows)}
              />
            </TableCell>
          )}

          {columnData.map(col => {
            let {
              settings: { multilang } = {},
              name: colName,
              label: colLabel,
              ident = false,
              type: colType,
              options,
              noSearch = false,
            } = col
            if (!selectedColumns.includes(colName)) {
              return null
            }

            const canSearch = !noSearch

            let label = colLabel || colName
            if (multilang && supportedLanguages.length > 1)
              label = `${label} (${selectedLanguage.id})`

            let enumFilter
            if (options) {
              let { settings: { variant } = {} } = col
              let isMultiple = !['date', 'datetime', 'timestamp'].includes(colType)
              let selected = selectedEnumFilters && selectedEnumFilters[colName]
              let filterActive = isMultiple
                ? selected && selected.length > 0
                : selected !== undefined
              enumFilter = (
                <EnhancedTableHeadSelect
                  multiple={isMultiple}
                  filterActive={filterActive}
                  variant={variant}
                  selected={selected}
                  onChange={this.handleEnumFilterChange(col)}
                  MenuProps={MenuProps}
                  colType={colType}
                  options={options}
                />
              )
            }

            return (
              <TableCell
                key={colName}
                numeric={col.numeric}
                padding="dense"
                className={classes.head}
                sortDirection={orderBy === colName ? (orderDir === -1 ? 'desc' : 'asc') : false}
                style={
                  multilang && selectedLanguage && selectedLanguage.rtl
                    ? { textAlign: 'right' }
                    : undefined
                }
              >
                {canSearch && enumFilter}
                <Tooltip
                  title={canSearch ? 'Sort' : ''}
                  placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === colName}
                    direction={orderDir === -1 ? 'desc' : 'asc'}
                    onClick={canSearch ? this.createSortHandler(colName) : undefined}
                  >
                    {ident === true && (
                      <Icon style={{ marginRight: 5, fontSize: 'inherit' }}>vpn_key</Icon>
                    )}

                    {label}

                    {!canSearch && (
                      <Tooltip
                        style={{ marginLeft: 5, fontSize: '1.5rem' }}
                        title="searching, filtering and sorting is not supported for this column"
                        placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <Icon style={{ marginLeft: 5, fontSize: '1.5rem' }}>search_off</Icon>
                      </Tooltip>
                    )}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderDir: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
  selectedColumns: PropTypes.array.isRequired,
}

export default withStyles(styles)(EnhancedTableHead)
