import React from 'react'

import { createRoot } from 'react-dom/client'
import 'shared-ui/assets/styles/global.css'
import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

const errorsToWarn = ['Warning:']
const oldConsError = console.error

console.error = function (...args) {
  let toWarn = typeof args[0] === 'string' && errorsToWarn.some(s => args[0].startsWith(s))
  toWarn ? console.warn(...args) : oldConsError(...args)
}
