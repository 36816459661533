import React from 'react'
import classNames from 'classnames'
import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Colors from 'shared-ui/assets/styles/colors'

const TypeChip = ({ label, type = 'none', classes, className }) => (
  <Chip
    label={label || type}
    classes={{ root: classNames(classes.root, classes[type.toLowerCase()]) }}
    className={className}
  />
)

const styles = ({ palette, spacing: { unit } }) => ({
  none: {},
  root: {
    '&:first-child': {
      marginLeft: -unit + 1,
    },
  },
  added: {
    backgroundColor: palette.common.green,
    color: palette.common.persitentWhite,
  },
  updated: {
    backgroundColor: palette.common.orange,
  },
  removed: {
    backgroundColor: palette.common.red,
    color: palette.common.persitentWhite,
  },
  export: {
    backgroundColor: palette.grey400,
    color: palette.common.persitentWhite,
  },
  crm: {
    backgroundColor: palette.grey400,
    color: palette.common.persitentWhite,
  },
})

export default withStyles(styles)(TypeChip)
