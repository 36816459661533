import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'

const KuberenetesNamespace = props => {
  const { namespace: name, deployments } = props

  if (deployments.length < 1) return <Typography variant={'body1'}>No deployments found</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Available Replicas</TableCell>
          <TableCell>Desired Replicas</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deployments.map((deployment, index) => {
          const unhealthy = deployment.availableReplicas < deployment.desiredReplicas
          return (
            <TableRow key={index} className={unhealthy ? 'unhealthy' : ''}>
              <TableCell>{deployment.name}</TableCell>
              <TableCell>{deployment.availableReplicas}</TableCell>
              <TableCell>{deployment.desiredReplicas}</TableCell>
              <TableCell>{unhealthy ? 'Unhealthy' : 'Healthy'}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default KuberenetesNamespace
