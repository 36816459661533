import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageSelection from './ImageSelection'
import { styles } from './Styles'

class CustomUserMedia extends React.Component {
  render() {
    const { classes, api, settings: { height: customHeight } = {} } = this.props
    let { value } = this.props

    if (!Array.isArray(value)) value = [value]

    value.map(item => {
      item.path = `${api.connector.baseUrl}/@/userChallengeMedia/${item.fileName}`
      item.type = item.mimetype
    })

    return (
      <>
        {value.map(value => (
          <ImageSelection
            showControls={false}
            classes={classes}
            value={value}
            handleOpen={undefined}
            disabled={true}
            handleDeleteImageFromItem={undefined}
            clickedOnVideo={undefined}
            isPlaying={false}
            customUserMedia={true}
            customHeight={customHeight}
          />
        ))}
      </>
    )
  }
}

export default withStyles(styles)(CustomUserMedia)
