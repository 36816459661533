import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  root: {
    boxSizing: 'border-box',
    zIndex: 1,
    width: '100%',
    minHeight: unit * 4,
    backgroundColor: palette.paper,
    padding: unit,
    [breakpoints.up('sm')]: {
      padding: unit * 2,
    },
  },
})

export default withStyles(styles)(({ classes, className, style, children }) => {
  return (
    <div className={classNames(classes.root, className)} style={style}>
      {children}
    </div>
  )
})
