import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Pie, Line, Doughnut } from 'react-chartjs-2'

ChartJS.register(...registerables)

const chartComponentByType = {
  pie: Pie,
  line: Line,
  doughnut: Doughnut,
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  chart: {
    width: '100%',
  },
})

class ModelStats extends React.Component {
  render() {
    const {
      classes,
      className,
      title,
      subtitle,
      graph: { data, type },
    } = this.props

    const Component = chartComponentByType[type]

    return (
      <Paper elevation={1} className={className}>
        <Grid container className={classes.root}>
          <Grid xs={4} item>
            <Typography variant={'h5'}>{title}</Typography>
            <Typography>
              <b>{subtitle}</b>
            </Typography>
          </Grid>
          <Grid xs={8} item>
            {Component && (
              <div className={classes.chart}>
                <Component
                  options={{
                    maintainAspectRatio: false,
                    circumference: 180,
                    rotation: 270,
                    plugins: {
                      legend: {
                        position: 'right',
                      },
                    },
                  }}
                  width={300}
                  height={100}
                  data={data}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(ModelStats)
