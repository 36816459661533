import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  topSpacer: {
    marginTop: unit,
  },
})

class CustomRadioField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
    }
  }

  _handleChange = event => {
    this.setState({ value: event.target.value })
  }

  render() {
    const { classes, helperText, defaultValue, ...props } = this.props

    const { value } = this.state

    return (
      <FormControl fullWidth {...props} classes={{ root: classes.root }}>
        {props.label && <InputLabel shrink={true}>{props.label}</InputLabel>}

        <RadioGroup
          name={props.name}
          value={value === undefined ? defaultValue : value}
          onChange={this._handleChange}
          className={classes.topSpacer}
        >
          {props.options.map(option => (
            <FormControlLabel
              disabled={option.disabled}
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

CustomRadioField.propTypes = {
  classes: PropTypes.object,
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomRadioField)
