import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'

import classNames from 'classnames'

class MenuFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      valueType: props.preSelectedTypes,
      valueTag: [],
      valueName: '',
    }
  }

  _callParentChange(e) {
    return this.props.handleOnChange({ name: e.target.name, ...this.state })
  }

  handleTypeChange(e) {
    this.setState(
      {
        valueType: e.target.value,
      },
      this._callParentChange.bind(this, e),
    )
  }

  handleChange(e) {
    e.persist()

    let propToChange = {}

    if (e.target.name === 'type') {
      propToChange.valueType = e.target.value
    }

    if (e.target.name === 'tag') {
      propToChange.valueTag = e.target.value
    }

    if (e.target.name === 'name') {
      propToChange.valueName = e.target.value
    }

    this.setState(propToChange, this._callParentChange.bind(this, e))
  }

  render() {
    const { types, classes, tags } = this.props

    return (
      <React.Fragment>
        <Grid container className={classes.filterWrapper}>
          <Grid item xs={4}>
            <FormControl className={classNames(classes.formControl)} fullWidth>
              <TextField
                margin={'none'}
                label="Filter"
                type="search"
                name="name"
                placeholder="Search..."
                className={classes.searchField}
                onChange={this.handleChange.bind(this)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink>Types</InputLabel>
              <Select
                multiple
                className={classes.selectEmpty}
                onChange={this.handleChange.bind(this)}
                name="type"
                value={this.state.valueType}
                input={<Input id="select-multiple-chip" />}
                classes={{
                  selectMenu: classes.selectMenu,
                }}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {types.map((item, k) => {
                  return (
                    <MenuItem value={item} key={k}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink>Tags</InputLabel>
              <Select
                multiple
                className={classes.selectEmpty}
                classes={{
                  selectMenu: classes.selectMenu,
                }}
                onChange={this.handleChange.bind(this)}
                name="tag"
                value={this.state.valueTag}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {tags.map((item, k) => {
                  return (
                    <MenuItem value={item} key={k}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default MenuFilter
