import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {},
})

class CustomTextField extends Component {
  state = {}

  render() {
    const {
      value = '',
      onChange,
      onUpdate,
      settings,
      defaultValue,
      settings: { max, ...restSettings } = {},
      selectedLanguage,
      helperText,
      ...rest
    } = this.props
    const { error } = this.state

    const dir = settings?.multilang && selectedLanguage && selectedLanguage.rtl ? 'rtl' : undefined

    return (
      <TextField
        autocomplete={false}
        {...rest}
        {...restSettings}
        inputProps={max !== undefined ? { maxlength: max } : undefined}
        dir={dir}
        value={value}
        error={error}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        onChange={this._onChange}
        helperText={helperText}
      />
    )
  }

  _onChange = e => {
    const { target = {} } = e
    const { value, pattern, checkValidity } = target

    if (
      value !== '' &&
      pattern !== '' &&
      typeof checkValidity === 'function' &&
      !checkValidity.call(target)
    )
      return

    const { onUpdate } = this.props
    onUpdate && onUpdate(e.target.value)
  }

  _onFocus = e => {
    this.setState({ error: false })

    const { onFocus } = this.props
    onFocus && onFocus(e)
  }

  _onBlur = e => {
    try {
      const isValid = e.target.checkValidity()
      this.setState({ error: !isValid })
    } catch (err) {}

    const { onChange } = this.props
    onChange && onChange(e)
  }
}

CustomTextField.propTypes = {
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomTextField)
