import React, { useEffect, useState } from 'react'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const CustomChipsInput = ({ value, onChange, name, label, helperText, ...props }) => {
  const [chips, setChips] = useState(Array.isArray(value) ? value : value?.split(',') || [])
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    const { value } = event.target
    // Allow only integers in the input
    if (/^\d*$/.test(value)) {
      setInputValue(value)
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter' && inputValue !== '') {
      const intValue = parseInt(inputValue, 10)
      // Prevent duplication
      if (!chips.includes(intValue)) {
        setChips(prevChips => [...prevChips, intValue])
        setInputValue('') // Clear input after adding chip
      }
    }
  }

  const handleDelete = chipToDelete => () => {
    setChips(prevChips => prevChips.filter(chip => chip !== chipToDelete))
  }

  useEffect(() => {
    if (chips.length) {
      onChange({ target: { value: chips.join(','), name } })
    }
  }, [chips])

  return (
    <div>
      <TextField
        label={label}
        value={inputValue}
        helperText={helperText}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {chips?.map(chip => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={handleDelete(chip)}
                  style={{ margin: '2px' }}
                />
              ))}
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </div>
  )
}

export default CustomChipsInput
