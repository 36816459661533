import React, { useEffect, useMemo, useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import ElementsListItem from './ElementsListItem'

const ElementsList = SortableContainer(
  ({
    className,
    classes,
    elements,
    disabled,
    renderFields,
    language,
    desiredExpandId,
    onItemDelete,
    onItemCopy,
    onItemCut,
    elementsStructure,
  }) => {
    const [expandedItemId, setExpandedItemId] = useState(desiredExpandId)

    useEffect(() => {
      if (desiredExpandId !== expandedItemId) setExpandedItemId(desiredExpandId)
    }, [desiredExpandId])

    const renderedElements = useMemo(() => {
      return elements.map(({ value, id, ...props }, i) => (
        <ElementsListItem
          key={id}
          className={classes.item}
          index={i}
          value={{
            ...value,
            sectionTitle: value['sectionTitle__' + language] || value.sectionTitle,
          }} // TODO: make property `sectionTitle` not hardcoded
          disabled={disabled}
          sortable={!disabled}
          expanded={expandedItemId === id}
          onClick={() => setExpandedItemId(expandedItemId === id ? undefined : id)}
          renderFields={renderFields}
          onDelete={onItemDelete}
          onCopy={onItemCopy}
          onCut={onItemCut}
          id={id}
          elementsStructure={elementsStructure}
          {...props}
        />
      ))
    }, [
      elements,
      classes,
      disabled,
      expandedItemId,
      renderFields,
      onItemDelete,
      onItemCopy,
      onItemCut,
    ])

    return <ul className={className}>{renderedElements}</ul>
  },
)

export default ElementsList
