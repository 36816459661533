import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = ({ palette }) => ({
  root: { width: '100%', height: '100%' },
  label: { height: '100%', marginLeft: 0, marginRight: 0 },
  button: { width: '100%', height: '100%', borderColor: palette.primary.main },
})

class CustomButton extends Component {
  state = { enabled: true }
  unmounted

  handleClick = e => {
    this.fileUploadInput && this.fileUploadInput.click()
    const {
      onTrigger,
      settings: { action },
    } = this.props
    if (onTrigger) {
      this.setState({ enabled: false })
      onTrigger(action, () => !this.unmounted && this.setState({ enabled: true }))
    }
  }

  handleUpload = e => {
    const {
      api,
      settings: { uploadAction },
      settings,
    } = this.props
    if (!this.fileUploadInput || !this.fileUploadInput.files || !this.fileUploadInput.files.length)
      return

    const [file] = this.fileUploadInput.files
    this.fileUploadInput.value = ''
    // TODO: use normal action workflow
    api.uploadInlineButtonData(
      `${window.location.pathname.replace('~', '@/' + uploadAction)}`,
      file,
      settings,
    )
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  render() {
    const { enabled } = this.state
    const {
      classes,
      helperText,
      disabled,
      label,
      settings: { upload },
    } = this.props
    return (
      <FormControl className={classes.root}>
        <FormControlLabel
          className={classes.label}
          disabled={disabled}
          control={
            <Button
              className={classes.button}
              variant="outlined"
              disabled={disabled || !enabled}
              onClick={this.handleClick}
            >
              {label}
            </Button>
          }
        />
        {upload && (
          <input
            ref={ref => (this.fileUploadInput = ref)}
            style={{ display: 'none' }}
            type="file"
            onChange={(...params) => this.handleUpload(...params)}
          />
        )}
        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withStyles(styles)(CustomButton)
