import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
})

const ListDividers = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <Divider />
    </div>
  )
}

ListDividers.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ListDividers)
