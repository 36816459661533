import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'

import classNames from 'classnames'

import Link from 'shared-ui/components/Link'

import Constant from 'shared-ui/utils/Contants'
import Logo from '~components/molecules/Header/Logo'
import CommonHeaderItems from '~components/molecules/Header/CommonHeaderItems'

const styles = theme => ({
  root: {
    display: 'flex',
    width: 320,
    height: 'calc(100vh - 64px)', // 64px refers to Header height

    [theme.breakpoints.down('md')]: {
      width: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  list: {
    backgroundColor: theme.palette.paper,
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll',
  },
  menuItemRoot: {
    paddingLeft: 0,
  },
  menuItemSelected: {
    '&::before': {
      content: '""',
      border: `0 solid ${theme.palette.primary.main}`,
      borderLeftWidth: '2px !important',
      position: 'absolute',
      height: '100%',
    },
  },
  menuIconSelected: {
    color: theme.palette.primary.main,
  },
  menuLabel: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '0.875rem',
    '&:first-child': {
      paddingLeft: 72,
    },
  },
  menuText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    // width: 150
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    position: 'relative',
    height: 'auto',
    width: Constant.drawerWidth,
  },
  expandIcon: {
    color: theme.palette.action.active,
  },
  dPaper: {
    position: 'relative',
    zIndex: 0,
  },
  headerItem: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
})

class Menu extends React.Component {
  constructor(props) {
    super(props)

    const activeMenuItem = this.props.menu.find(menuItem => {
      return menuItem.items.find(item => window.location.pathname.startsWith(item.to))
    })

    this.state = {}
    if (activeMenuItem) {
      this.state[activeMenuItem.label] = true
    }

    this.renderMenuItem = this.renderMenuItem.bind(this)
  }

  static defaultProps() {
    return {
      menu: [],
    }
  }

  renderMenuItem(menuItem, k) {
    const { classes } = this.props
    let isMenuActive = false

    const createSubMenu = menuItem => {
      const { handleDrawerToggle } = this.props
      if (menuItem.items) {
        return menuItem.items.map((item, k) => {
          let slugs = window.location.pathname.split('/')
          let isActive = item.to.split('/').every((p, i) => slugs[i] === p)
          isMenuActive = isMenuActive || isActive

          return (
            <Collapse in={this.state[menuItem.label]} key={k} timeout="auto" unmountOnExit>
              <MenuItem
                component={Link}
                to={item.to}
                button
                onClick={handleDrawerToggle}
                selected={isActive}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
              >
                <ListItemText
                  inset
                  primary={item.label}
                  className={classes.menuLabel}
                  primaryTypographyProps={{
                    className: classes.menuText,
                  }}
                />
              </MenuItem>
            </Collapse>
          )
        })
      }
    }

    const subMenu = createSubMenu(menuItem)

    return (
      <React.Fragment key={k}>
        <ListItem button onClick={this.handleClick.bind(this, menuItem.label)}>
          <ListItemIcon>
            <Icon className={classNames({ [classes.menuIconSelected]: isMenuActive })}>
              {menuItem.icon}
            </Icon>
          </ListItemIcon>
          <ListItemText
            inset
            primary={menuItem.label}
            className={classes.menuLabel}
            primaryTypographyProps={{ className: classes.menuText }}
          />

          {menuItem.items && (
            <Icon className={classes.expandIcon}>
              {this.state[menuItem.label] ? 'expand_less' : 'expand_more'}{' '}
            </Icon>
          )}
        </ListItem>

        {subMenu}

        <Divider />
      </React.Fragment>
    )
  }

  handleClick(item) {
    this.setState({ [item]: !this.state[item] })
  }

  render() {
    const { classes, showMenu, header, member, handleDrawerToggle, platformName } = this.props

    const drawerContent = (
      <List disablePadding className={classes.list}>
        {this.props.menu.map(this.renderMenuItem)}
      </List>
    )

    return (
      <React.Fragment>
        <Hidden smDown>
          <div className={classes.root}>{drawerContent}</div>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            open={showMenu}
            variant="temporary"
            className={classes.root}
            classes={{ paper: classes.dPaper }}
            onClose={handleDrawerToggle}
          >
            <ListItem>
              <div style={{ display: 'flex', alignItems: 'space-between' }}>
                <Logo platformName={platformName.toUpperCase()} />
              </div>
            </ListItem>
            <CommonHeaderItems
              items={header.items}
              member={member}
              itemClass={classNames(classes.menuItemRoot, classes.headerItem)}
              wrapAsListItem
            />
            <Divider />
            {drawerContent}
          </Drawer>
        </Hidden>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Menu)
