import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from '@material-ui/core/Modal'
import Icon from '@material-ui/core/Icon'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { styles } from './Styles'
import SwipeableViews from 'react-swipeable-views'
import PreviewMedia from './PreviewMedia'
import ListMediaContainer from './ListMediaContainer'
import UploadMediaContainer from './UploadMediaContainer'
import ImageSelection from './ImageSelection'
import { withApi } from 'shared-ui/api/ApiContext'
import CustomSwitchField from '~components/atom/FieldFactory/CustomSwitchField'
import { checkContentType } from 'shared-ui/utils/mediaContent'

const imageMaxSize = 1000000000 // bytes
const defaultFilters = {
  valueType: [],
  valueTag: [],
  valueName: '',
}
// const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
// const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

const cachedFileData = {} // Used to display image if only id is supplied (given that it was cached before)

class CustomMedia extends React.Component {
  constructor(props) {
    super(props)

    let multilang = props[`${props.name}__multilang`] || false

    const fileId = props.value && typeof props.value[0] === 'string' && props.value[0]
    const fileData = fileId && cachedFileData[fileId]

    this.state = {
      multilang,
      prevMultilang: multilang,
      upstreamFiles: [],
      files: [],
      open: false,
      showFullImage: false,
      value: 0,
      tags: [],
      tagsOnUpload: [],
      updateMediaList: false,
      isPlaying: false,
      createdNewOption: false,
      filters: defaultFilters,
      ...(fileData ? { selectedFile: fileData } : {}),
    }
    this.tagsOnUpload = []
    this.parsedTags = []
    // A clone of the medias from the server
    this.medias = []
    this.nextProps = {}
  }

  static getDerivedStateFromProps(props, state) {
    const propsMultilang = props[`${props.name}__multilang`]

    if (propsMultilang !== state.prevMultilang) {
      return {
        multilang: propsMultilang,
        prevMultilang: propsMultilang,
      }
    }
    return null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.nextProps = nextProps
  }

  componentDidMount() {
    if (this.props.isRichText) {
      this.handleOpen()
    }
  }

  async fetchAllMedias() {
    try {
      const { api, typeMedia, sectionType, settings: { whiteListTypes } = {} } = this.props

      const filters = { filterByTags: [sectionType] }
      if (whiteListTypes) {
        filters.whiteListTypes = whiteListTypes.join('|')
      }

      const { request } = api.getMedias(filters)

      const response = await request

      this.setState(
        {
          upstreamFiles: response.mediaItems,
          types: response.filter.types,
          tags: response.filter.tags,
          showFullImage: false,
          visible: false,
        },
        () => {
          this.parsedTags = this.state.tags.map(t => {
            return { value: t, label: t }
          })
        },
      )

      this.medias = response.mediaItems
      this.searchMedias({
        valueType: this.getFilteredTypes(response.filter.types, typeMedia),
      })
    } catch (err) {
      console.error('Failed to fetch all media files:', err)
    }
  }

  handleOnDrop = (files, rejectedFiles) => {
    let allFiles = [...this.state.files, ...files]

    function readAndPreview(file, index) {
      if (allFiles && allFiles.length > 0) {
        const reader = new FileReader()
        reader.onload = function () {
          allFiles[index]['src'] = reader.result

          this.setState({
            files: allFiles,
          })
        }.bind(this)

        reader.readAsDataURL(file)
      }
    }

    if (files) {
      allFiles.forEach((file, i) => readAndPreview.bind(this, file, i)())
    }
  }

  handleOpen = () => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    this.setState({
      open: true,
      value: 0,
    })

    this.fetchAllMedias()
  }

  handleClose = () => {
    if (this.state.visible) {
      return
    }

    this.setState({ open: false })

    this.props.handleCloseParent && this.props.handleCloseParent()
  }

  async _processUpload(file, k, onlyOne) {
    const { api } = this.props

    this.setState({
      visible: true,
    })

    try {
      const { request } = api.uploadMedia(null, file, this.tagsOnUpload)

      await request
    } catch (e) {
      console.error('Error while processing upload', e)
    }

    this.removePreviewFile(file)

    await this.fetchAllMedias()
    if (onlyOne) {
      this.setState({
        visible: false,
      })
    }

    if (!this.state.files.length) {
      await this.fetchAllMedias()
      this.handleChange(null, 0)
    }
  }

  uploadFile(file, k) {
    try {
      this._processUpload(file, k, true)
    } catch (err) {
      console.error('Failed to upload file:', err)
    }
  }

  uploadAllFiles() {
    try {
      const { files } = this.state

      files.map((file, k) => this._processUpload(file, k))
    } catch (err) {
      console.error('Failed to upload all files:', err)
    }
  }

  removePreviewFile(file) {
    this.setState({
      files: this.state.files.filter(function (item, k) {
        return file.name !== item.name
      }),
    })
  }

  _handleAddChipOnUpload = (tags = []) => {
    if (tags.length) {
      this.tagsOnUpload = tags
    }
  }

  async _handleAddChip(tags = []) {
    const { currentFile } = this.state
    const { api } = this.props

    try {
      if (!currentFile.tags) {
        currentFile.tags = []
      }
      currentFile.tags = tags

      const { request } = api.updateMedia(currentFile._id, {
        set: {
          tags: tags,
        },
      })

      await request
    } catch (err) {
      console.error('Failed to add chip:', err)
    }

    this.setState({
      currentFile: currentFile,
      updateMediaList: true,
    })
  }

  _handleCreateOption = (inputValue, parsedValues, isUpload) => {
    const newOption = { label: inputValue, value: inputValue }
    this.parsedTags.push(newOption)

    if (isUpload) {
      return this._handleAddChipOnUpload(parsedValues)
    }

    this._handleAddChip(parsedValues)
  }

  searchMedias(e) {
    // keep the filters value in the state to apply the filter after open the media and return to the list view
    const valueType = (e && e.valueType) || defaultFilters.valueType
    const valueTag = (e && e.valueTag) || defaultFilters.valueTag
    const valueName = (e && e.valueName) || defaultFilters.valueName

    let finalMedias = this.medias.filter((file, i) => {
      return (
        (valueType.length === 0 || (Array.isArray(valueType) && valueType.includes(file.type))) &&
        (valueTag.length === 0 ||
          (Array.isArray(file.tags) && file.tags.some(tag => valueTag.includes(tag)))) &&
        (valueName === '' || file.name.indexOf(valueName) !== -1)
      )
    })

    this.setState({
      upstreamFiles: finalMedias,
      filters: { valueType, valueTag, valueName },
    })
  }

  viewFullImage(file) {
    this.setState({
      currentFile: file,
      showFullImage: true,
      value: 2,
    })
  }

  async backToFilesList() {
    if (this.state.updateMediaList) {
      await this.fetchAllMedias()
      this.searchMedias({})
    }

    this.setState({
      currentFile: null,
      showFullImage: false,
      value: 0,
      updateMediaList: false,
    })
  }

  async deleteFile(file, i) {
    if (!window.confirm('Do you really want to delete this file?')) {
      return
    }

    const { api } = this.props

    this.medias = this.medias.filter(function (item, k) {
      return k !== i
    })

    try {
      const { request } = api.deleteMedia(file._id)
      await request
    } catch (err) {
      console.error('Deletion of file failed with:', err)

      return
    }

    this.setState({
      upstreamFiles: this.medias,
    })
  }

  useThisMedia(file, k) {
    const { isList } = this.props

    if (isList) return

    const { upstreamFiles } = this.state

    if (this.props.value !== file._id) {
      file.selected = true

      this.setState({
        upstreamFiles: upstreamFiles,
        open: false,
        selectedFile: file,
      })

      cachedFileData[file._id] = file

      this.props.onChange.apply(this, [
        {
          target: {
            name: this.props.name,
            value: [file._id],
          },
        },
      ])
    }
  }

  handleChange = (event, value) => {
    this.setState({ value: value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  handleMultiSwitchChange = event => {
    const { onChange, name } = this.props

    const { value } = event.target
    this.setState({ multilang: value })

    onChange &&
      onChange({
        target: {
          name: `${name}__multilang`,
          value,
        },
      })
  }

  handleAutoplayChange = async event => {
    const { api } = this.props
    const { currentFile } = this.state
    const newFile = { ...currentFile }
    if (newFile.autoplay) {
      newFile.autoplay = !newFile.autoplay
    } else {
      newFile.autoplay = event.target.value
    }
    try {
      const { request } = api.updateMedia(currentFile._id, { set: { autoplay: newFile.autoplay } })
      await request
    } catch (e) {
      console.error('Error while changing autoPlay', e)
    }

    this.setState({
      currentFile: newFile,
      updateMediaList: true,
    })
  }

  _handleDeleteImageFromItem = () => {
    const { onChange } = this.props
    this.setState({
      selectedFile: null,
    })

    onChange &&
      onChange({
        target: {
          name: this.props.name,
          value: [],
        },
      })
  }

  _handleClickOnVideo = e => {
    const { isPlaying } = this.state

    this.setState({
      isPlaying: !isPlaying,
    })
  }

  shouldHasModal(children) {
    const { classes, isList } = this.props

    if (isList) {
      return children
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <div className={classes.paper}>
          <div className={classes.close} onClick={this.handleClose}>
            <Icon>close</Icon>
          </div>
          {children}
        </div>
      </Modal>
    )
  }

  getFilteredTypes(types, typeMedia) {
    return types.filter(t => t.startsWith(typeMedia))
  }

  render() {
    const {
      classes,
      theme,
      value: propsValue,
      isRichText,
      useThisMedia,
      typeMedia,
      label,
      helperText,
      disabled,
      columnSize,
      isList,
      sectionType,
    } = this.props

    let { selectedFile } = this.state

    let value = selectedFile !== undefined ? selectedFile : propsValue

    const {
      files,
      upstreamFiles,
      showFullImage,
      currentFile,
      types,
      tags,
      visible,
      isPlaying,
      multilang,
    } = this.state

    const showUploadContainer = !(sectionType === 'planogram')
    const mediaContent = (
      <React.Fragment>
        {!showFullImage && (
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="MEDIA" />
            {showUploadContainer && <Tab label="UPLOAD" />}
          </Tabs>
        )}

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          {types && (
            <ListMediaContainer
              classes={classes}
              searchMedias={this.searchMedias.bind(this)}
              contentType={checkContentType}
              upstreamFiles={upstreamFiles}
              deleteFile={this.deleteFile.bind(this)}
              useThisMedia={useThisMedia || this.useThisMedia.bind(this)}
              viewFullImage={this.viewFullImage.bind(this)}
              types={types}
              tags={tags}
              isList={isList}
              preSelectedTypes={this.getFilteredTypes(types, typeMedia)}
            />
          )}

          {showUploadContainer ? (
            <UploadMediaContainer
              classes={classes}
              contentType={checkContentType}
              imageMaxSize={imageMaxSize}
              handleOnDrop={this.handleOnDrop.bind(this)}
              uploadAllFiles={this.uploadAllFiles.bind(this)}
              removePreviewFile={this.removePreviewFile.bind(this)}
              uploadFile={this.uploadFile.bind(this)}
              previewFiles={files}
              tagsOnUpload={this.tagsOnUpload}
              handleAddChipOnUpload={this._handleAddChipOnUpload}
              handleDeleteChipOnUpload={this._handleDeleteChipOnUpload}
              visible={visible}
              onHandleCreate={this._handleCreateOption}
              parsedTags={this.parsedTags}
            />
          ) : (
            <React.Fragment />
          )}

          {showFullImage ? (
            <PreviewMedia
              currentFile={currentFile}
              {...this.props}
              onClickHandle={this.backToFilesList.bind(this)}
              onHandleAddChip={this._handleAddChip.bind(this)}
              useThisMedia={useThisMedia || this.useThisMedia.bind(this)}
              parsedTags={this.parsedTags}
              onHandleCreate={this._handleCreateOption}
              onAutoplayChange={this.handleAutoplayChange}
              isList={isList}
            />
          ) : (
            <React.Fragment />
          )}
        </SwipeableViews>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <FormLabel style={{ fontSize: '0.825rem' }} disabled={disabled}>
          {label}
        </FormLabel>
        {!isRichText && (
          <ImageSelection
            classes={classes}
            value={value}
            handleOpen={this.handleOpen}
            disabled={disabled}
            columnSize={columnSize}
            handleDeleteImageFromItem={this._handleDeleteImageFromItem}
            clickedOnVideo={this._handleClickOnVideo}
            isPlaying={isPlaying}
          />
        )}
        {this.shouldHasModal(mediaContent)}

        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
        {!isList && (
          <CustomSwitchField
            name="multilanguage"
            label="Localization"
            helperText="Use different media for different languages"
            helperTextClass={classes.multilang}
            disabled={disabled}
            value={multilang}
            onChange={this.handleMultiSwitchChange}
          />
        )}
      </React.Fragment>
    )
  }
}

CustomMedia.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(withApi(CustomMedia))
