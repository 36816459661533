import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import LanguageSelector from '~components/molecules/LanguageSelector/LanguageSelector'
import HeaderItem from './HeaderItem'
import HeaderUserItem from './HeaderUserItem'

const components = {
  HeaderItem,
  HeaderUserItem,
}

const wrapInListItem = (Component, wrapIt) =>
  wrapIt ? <ListItem>{Component}</ListItem> : Component

class CommonHeaderItems extends React.Component {
  render() {
    const { items, member, wrapAsListItem, itemClass } = this.props
    return (
      <React.Fragment>
        {wrapInListItem(<LanguageSelector className={itemClass} />, wrapAsListItem)}

        {items.map(({ component, props, data }, index) => {
          const Component = components[component]
          return wrapInListItem(
            <Component key={'item_' + index} {...props} member={member} className={itemClass} />,
            wrapAsListItem,
          )
        })}
      </React.Fragment>
    )
  }
}

export default CommonHeaderItems
