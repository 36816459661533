import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import PropertyRow from './PropertyRow'
import { changeToType } from './functions'
import TypeChip from './TypeChip'

const columns = [
  { width: '5%' },
  { width: '19%', label: 'id', notChangeable: true },
  { width: '19%', label: 'type' },
  { width: '19%', label: 'label' },
  { width: '19%', label: 'defaultValue' },
  { width: '19%', label: 'changes' },
]

const EntityPanel = ({
  classes,
  data: { type, name, settings, colName, scheme },
  change, // 'added' | 'removed' | Object
}) => {
  const changeType = changeToType(change)
  const isRemoved = changeType === 'removed'
  return (
    <ExpansionPanel disabled={isRemoved}>
      <ExpansionPanelSummary
        expandIcon={isRemoved ? '' : <Icon disabled>expand_more</Icon>}
        classes={{ content: classes.panelSummary, disabled: classes.panelDisabled }}
      >
        {change && <TypeChip type={changeType} className={classes.typeChip} />}
        {type && <TypeChip type={type} className={classes.typeChip} />}
        <Typography variant="h5" align="left" color={'primary'}>
          {name || (settings && settings.name) || ''}
        </Typography>
        <Typography className={classes.entityColName} variant="h6" align="right">
          {colName}
        </Typography>
      </ExpansionPanelSummary>
      {!isRemoved && (
        <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
          {/*<Typography classes={{ root: classes.description }}>This is a description</Typography>*/}
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({ width, label }, i) => (
                  <TableCell key={label || i.toString()} width={width}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: classes.tableBody }}>
              {Object.keys(scheme).map(key => (
                <PropertyRow
                  key={key}
                  data={scheme[key]}
                  id={key}
                  columns={columns}
                  change={change && change.scheme && change.scheme[key]}
                />
              ))}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  )
}

const styles = ({ spacing: { unit } }) => ({
  entityColName: {
    marginLeft: 'auto',
    opacity: 0.5,
  },
  typeChip: {
    marginRight: unit,
  },
  panelSummary: {
    alignItems: 'center',
  },
  panelDetails: {
    display: 'block',
  },
  description: {
    marginBottom: unit * 2,
  },
  tableBody: {
    '&>*:last-child>*': {
      borderBottom: 'none',
    },
  },
  panelDisabled: {
    opacity: '1 !important',
  },
})

export default withStyles(styles)(EntityPanel)
